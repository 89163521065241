import { Injectable } from '@angular/core';
import { DataService } from '../../providers/data/data.service';
import { StateService } from '../../providers/state/state.service';
import { NotificationService } from '../../providers/notification/notification.service';
import {
  AddToCartMutation,
  AddToCartMutationVariables,
  AdjustItemQuantityMutation,
  AdjustItemQuantityMutationVariables,
  GetActiveOrderQuery,
  RemoveItemFromCartMutation,
  RemoveItemFromCartMutationVariables,
  TransitionToAddingItemsMutation,
  Order,
  Product,
  ProductVariant,
} from '../../../common/generated-types';
import { ADD_TO_CART, ADJUST_ITEM_QUANTITY, REMOVE_ITEM_FROM_CART } from '../../../common/graphql/documents.graphql';
import { GET_ACTIVE_ORDER } from '../../providers/active/active.service.graphql';
import { TRANSITION_TO_ADDING_ITEMS } from '../../../checkout/components/checkout-single-page/checkout-single-page.graphql';
import { Observable, EMPTY, of, throwError } from 'rxjs';
import { switchMap, catchError, take, map } from 'rxjs/operators';
import { CombinedAnalyticsService } from '../analytics/combined-analytics.service';
import { NGXLogger } from 'ngx-logger';
import { AddToCartResult, AdjustItemQuantityResult, RemoveItemResult } from '../../../common/enums';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor(
    private dataService: DataService,
    private stateService: StateService,
    private notificationService: NotificationService,
    private analyticsService: CombinedAnalyticsService,
    private logger: NGXLogger,
  ) {}

  /**
   * Checks the current active order. If not in AddingItems state, tries to transition to AddingItems.
   */
  private ensureAddingItemsState(): Observable<null> {
    return this.dataService.query<GetActiveOrderQuery>(GET_ACTIVE_ORDER, {}, 'network-only').pipe(
      take(1),
      switchMap(({ activeOrder }) => {
        if (!activeOrder) {
          // No active order, nothing to do
          return of(null);
        }
        if (activeOrder.state === 'AddingItems') {
          return of(null);
        } else if (activeOrder.state === 'ArrangingPayment') {
          // Try to transition back to AddingItems
          return this.dataService.mutate<TransitionToAddingItemsMutation>(TRANSITION_TO_ADDING_ITEMS)
            .pipe(
              take(1),
              switchMap(({ transitionOrderToState }) => {
                switch (transitionOrderToState?.__typename) {
                  case 'Order':
                    return of(null);
                  case 'OrderStateTransitionError':
                    this.notificationService.error(transitionOrderToState.message).subscribe();
                    return EMPTY;
                  default:
                    this.notificationService.error('Unexpected error occurred').subscribe();
                    return EMPTY;
                }
              }),
              catchError(error => {
                this.notificationService.error(`Error transitioning order state: ${error.message}`).subscribe();
                return EMPTY;
              }),
            );
        } else {
          // Order not in a state we can modify
          this.notificationService.error('Cannot modify the cart at this time').subscribe();
          return EMPTY;
        }
      }),
    );
  }

  /**
   * Add an item to the cart and open the drawer.
   */
  addToCartAndOpenDrawer(productVariantId: string, quantity: number = 1): Observable<AddToCartResult> {
    return this.ensureAddingItemsState().pipe(
      switchMap(() => {
        return this.dataService
          .mutate<AddToCartMutation, AddToCartMutationVariables>(ADD_TO_CART, {
            variantId: productVariantId,
            qty: quantity,
          })
          .pipe(
            take(1),
            map(({ addItemToOrder }) => {
              if (!addItemToOrder) {
                this.notificationService.error('An unexpected error occurred').subscribe();
                return AddToCartResult.ERROR;
              }
              switch (addItemToOrder.__typename) {
                case 'Order':
                  this.stateService.setState('activeOrderId', addItemToOrder.id);
                  this.stateService.setState('cartDrawerOpen', true);
                  this.processAddToCartAnalytics(addItemToOrder as Order, productVariantId, quantity);
                  return AddToCartResult.SUCCESS;
                case 'OrderModificationError':
                case 'OrderLimitError':
                  this.notificationService.error(addItemToOrder.message).subscribe();
                  return AddToCartResult.ERROR;
                case 'NegativeQuantityError':
                case 'InsufficientStockError':
                  this.notificationService.error(addItemToOrder.message).subscribe();
                  return AddToCartResult.OUT_OF_STOCK;
                default:
                  this.notificationService.error('An unexpected error occurred').subscribe();
                  return AddToCartResult.ERROR;
              }
            }),
            catchError(error => {
              this.notificationService.error(`Error adding item to cart: ${error.message}`).subscribe();
              return throwError(AddToCartResult.ERROR);
            }),
          );
      })
    );
  }

  /**
   * Adjust item quantity in the cart. If the state is not AddingItems, try to fix that first.
   */
  adjustItemQuantity(id: string, qty: number): Observable<AdjustItemQuantityResult> {
    return this.ensureAddingItemsState().pipe(
      switchMap(() =>
        this.dataService.mutate<AdjustItemQuantityMutation, AdjustItemQuantityMutationVariables>(ADJUST_ITEM_QUANTITY, {
          id,
          qty,
        }).pipe(
          take(1),
          map(({ adjustOrderLine }) => {
            if (!adjustOrderLine) {
              this.notificationService.error('An unexpected error occurred').subscribe();
              return AdjustItemQuantityResult.ERROR;
            }
            switch (adjustOrderLine.__typename) {
              case 'Order':
                return AdjustItemQuantityResult.SUCCESS;
              case 'OrderLimitError':
              case 'OrderModificationError':
                this.notificationService.error(adjustOrderLine.message).subscribe();
                return AdjustItemQuantityResult.ERROR;
              case 'NegativeQuantityError':
              case 'InsufficientStockError':
                  this.notificationService.error(adjustOrderLine.message).subscribe();
                  return AdjustItemQuantityResult.OUT_OF_STOCK;
              default:
                this.notificationService.error('An unexpected error occurred').subscribe();
                return AdjustItemQuantityResult.ERROR;
            }
          }),
          catchError(error => {
            this.notificationService.error(`Error adjusting item quantity: ${error.message}`).subscribe();
            return throwError(AdjustItemQuantityResult.ERROR);
          }),
        )
      )
    );
  }

  /**
   * Remove an item from the cart. If the state is not AddingItems, try to fix that first.
   */
  removeItem(id: string): Observable<RemoveItemResult> {
    return this.ensureAddingItemsState().pipe(
      switchMap(() =>
        this.dataService.mutate<RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables>(REMOVE_ITEM_FROM_CART, {
          id,
        }).pipe(
          take(1),
          map(({ removeOrderLine }) => {
            if (!removeOrderLine) {
              this.notificationService.error('An unexpected error occurred').subscribe();
              return RemoveItemResult.ERROR;
            }
            switch (removeOrderLine.__typename) {
              case 'Order':
                return RemoveItemResult.SUCCESS;
              case 'OrderModificationError':
                this.notificationService.error(removeOrderLine.message).subscribe();
                return RemoveItemResult.ERROR;
              default:
                this.notificationService.error('An unexpected error occurred').subscribe();
                return RemoveItemResult.ERROR;
            }
          }),
          catchError(error => {
            this.notificationService.error(`Error removing item from cart: ${error.message}`).subscribe();
            return throwError(RemoveItemResult.ERROR);
          }),
        )
      )
    );
  }

  private processAddToCartAnalytics(
    order: Order,
    productVariantId: string,
    quantity: number,
  ) {
    const addedLine = order.lines.find(line => line.productVariant.id === productVariantId);
    if (addedLine) {
      const product = addedLine.productVariant.product;
      const variant = addedLine.productVariant;
      this.addToCartEvent(product, variant, quantity);
    } else {
      this.logger.warn('Added line not found in order lines for analytics tracking');
    }
  }

  private addToCartEvent(product: Product, variant: ProductVariant, quantity: number) {
    if (product && variant) {
      try {
        this.analyticsService.addToCart(product, variant, quantity);
        this.logger.debug('addToCartEvent');
      } catch (err) {
        this.logger.error('Error in addToCartEvent', err);
      }
    }
  }
}
