import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core';
import { Asset, GetProductAllDetailQuery, SearchProductsQuery, SearchVariantsQuery } from '../../../common/generated-types';
import { Subscription } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { CartService } from '../../../core/providers/cart/cart.service';
import { StateService } from '../../../core/providers/state/state.service';
import { AddToCartResult, CollectionItemType } from '../../../common/enums';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'vsf-product-card-v3-horizontal',
    templateUrl: './product-card-v3-horizontal.component.html',
    styleUrls: ['./product-card-v3-horizontal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardV3HorizontalComponent implements OnInit, AfterViewInit {
    @Input() item: SearchProductsQuery['search']['items'][number] | SearchVariantsQuery['search']['items'][number];
    @Input() displayType: CollectionItemType = CollectionItemType.Product;
    @Input() displaySubtitle: boolean = false;
    @Input() size: 'small' | 'large' = 'large';
    @Input() type: 'left-asset' | 'right-asset' = 'left-asset';
    @Input() displayViewers = false;
    @Input() displayLikes = false;
    @Input() displayRanking = false;
    @Input() displaySold = false;
    @Input() displayPrice = true;
    @Input() displayBuyButton = true;

    msrp: number | null = null;
    discountPercentage: number | null = null;
    inFlight = false;
    inStock = true;
    productBaseUrl = '';
    itemName: string;
    itemPrice: number | null = null;
    featuredAsset: Asset | null;

    private subscription = new Subscription();

    constructor(
        private cartService: CartService,
        private stateService: StateService,
        private changeDetector: ChangeDetectorRef,
        private logger: NGXLogger,
        @Inject(PLATFORM_ID) private platformId: object,
    ) {}

    ngOnInit() {
        if (this.item) {
            this.inStock = this.item.inStock;
            if(this.displayType === CollectionItemType.Product) {
                const product = this.item as SearchProductsQuery['search']['items'][number];
                this.itemName = product.productName;
                this.featuredAsset = product.productAsset as Asset;
            } else if(this.displayType === CollectionItemType.ProductVariant) {
                const variant = this.item as SearchVariantsQuery['search']['items'][number];
                this.itemName = variant.productVariantName;
                this.featuredAsset = variant.productVariantAsset as Asset;
            }
            this.updatePricingInfo();
            this.changeDetector.markForCheck();
        }
        this.stateService.select(state => state.storePath).subscribe(storePath => {
            this.productBaseUrl = `${storePath}/dp`;
        });
    }

    ngAfterViewInit() {
        // No need for card size calculation in this layout
    }

    updatePricingInfo() {
        if(this.displayType === CollectionItemType.Product) {
            const product = this.item as SearchProductsQuery['search']['items'][number];
            if(product.price?.__typename === 'PriceRange' && product.price.min !== null && product.price.max !== null && product.price.min === product.price.max) {
                this.itemPrice = product.price.min;
            } else if(product.price?.__typename === 'SinglePrice' && product.price.value !== null) {
                this.itemPrice = product.price.value;
            }
            if(product.productVariantMSRP) {
                this.msrp = product.productVariantMSRP;
            }
        } else if(this.displayType === CollectionItemType.ProductVariant) {
            const variant = this.item as SearchVariantsQuery['search']['items'][number];
            if(variant.price?.__typename === 'PriceRange' && variant.price.min !== null && variant.price.max !== null && variant.price.min === variant.price.max) {
                this.itemPrice = variant.price.min;
            } else if(variant.price?.__typename === 'SinglePrice' && variant.price.value !== null) {
                this.itemPrice = variant.price.value;
            }
            if(variant.productVariantMSRP) {
                this.msrp = variant.productVariantMSRP;
            }
        }
        if (this.itemPrice && this.msrp && this.msrp > this.itemPrice) {
            this.discountPercentage = ((this.msrp - this.itemPrice) / this.msrp) * 100;
        }
    }

    onButtonClick(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.addToCartAndOpenDrawer();
    }

    addToCartAndOpenDrawer() {
        if (!this.item) {
            return;
        }
        this.inFlight = true;
        this.changeDetector.markForCheck();

        this.subscription.add(
            this.cartService.addToCartAndOpenDrawer(this.item.productVariantId).subscribe({
                next: (result: AddToCartResult) => {
                    this.inFlight = false;
                    if (result === AddToCartResult.OUT_OF_STOCK) {
                        this.inStock = false;
                    }
                    this.changeDetector.markForCheck();
                },
                error: err => {
                    this.inFlight = false;
                    this.logger.error('Error in addToCartAndOpenDrawer', err);
                    this.changeDetector.markForCheck();
                },
            }),
        );
    }
}