<div *ngIf="storeInfo && (readyToDisplay$ | async)">
    <ng-container><!-- *ngIf="isMobile !== null">-->
        <div *ngIf="layoutOrder.length > 0">
            <div *ngFor="let layout of layoutOrder" [ngStyle]="layout.styles">
                <!-- Single Component -->
                <ng-container *ngIf="layout.layoutComponentType === 'hero-section'">
                    <div *ngIf="heroSection && heroSection?.medias?.length > 0" class="hero-banner" style="min-height: 180px;">
                        <vsf-hero-section
                            [heroSection]="heroSection"
                            [isMobile]="isMobile">
                        </vsf-hero-section>
                    </div>
                </ng-container>
                <ng-container *ngIf="layout.layoutComponentType === 'customer-review-section'">
                    <div *ngIf="featuredProductReviewList" class="flex flex-row justify-center">
                        <div>
                            <vsf-top-reviews
                                [reviews]="featuredProductReviewList"
                                [isMobile]="isMobile">
                            </vsf-top-reviews>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="layout.layoutComponentType === 'email-subscription-section'">
                    <div *ngIf="emailSubscriptionSection">
                        <vsf-email-subscription-form
                            [emailSubscriptionData]="emailSubscriptionSection">
                        </vsf-email-subscription-form>
                    </div>
                </ng-container>
                <!-- List Component -->
                <ng-container *ngIf="layout.layoutComponentType === 'unique-selling-point'">
                    <div *ngIf="marketingBanners?.length > layout.seqNumberOfList">
                        <div>
                            <vsf-marketing-banner
                                [marketingBanner]="marketingBanners[layout.seqNumberOfList]"
                                [isMobile]="isMobile">
                            </vsf-marketing-banner>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="layout.layoutComponentType === 'featured-product-collection'">
                    <div *ngIf="featuredCollectionList?.length > layout.seqNumberOfList" >
                        <div *ngIf="!isMobile">
                            <div>
                                <vsf-product-carousel-v2
                                    [title]="featuredCollectionList[layout.seqNumberOfList]?.title"
                                    [subtitile]="featuredCollectionList[layout.seqNumberOfList]?.subtitle"
                                    [displayType] = "featuredCollectionList[layout.seqNumberOfList]?.displayType"
                                    [productCardType]="featuredCollectionList[layout.seqNumberOfList]?.productCardType"
                                    [displayFields]="featuredCollectionList[layout.seqNumberOfList]?.displayFields"
                                    [collectionSlug]="featuredCollectionList[layout.seqNumberOfList]?.collection.slug"
                                    [collectionName]="featuredCollectionList[layout.seqNumberOfList]?.collection.name"
                                    [collectionItemOrder]="featuredCollectionList[layout.seqNumberOfList]?.collection.customFields?.itemOrderData"
                                    [channelId]="storeInfo.sellerChannelId"
                                    [itemsPerSlide]="featuredCollectionList[layout.seqNumberOfList]?.cardsPerRow"
                                    [displayAlternativeImage]="true"
                                    [imgSize]="'md'">
                                </vsf-product-carousel-v2>
                            </div>
                        </div>
                        <div *ngIf="isMobile">
                            <div class="product-collection-title">
                                <p>{{ featuredCollectionList[layout.seqNumberOfList].title }}</p>
                                <div class="title-underline"></div>
                            </div>
                            <ng-container *ngIf="featuredCollectionList[layout.seqNumberOfList]?.productCardType === 'regular'
                                                || featuredCollectionList[layout.seqNumberOfList]?.productCardType === 'top-asset-small';
                                                else newSwiper ">
                                <vsf-product-swiper
                                    [displayType] = "featuredCollectionList[layout.seqNumberOfList]?.displayType"
                                    [productCardType]="featuredCollectionList[layout.seqNumberOfList]?.productCardType"
                                    [displayFields]="featuredCollectionList[layout.seqNumberOfList]?.displayFields"
                                    [collectionSlug]="featuredCollectionList[layout.seqNumberOfList]?.collection?.slug"
                                    [collectionItemOrder]="featuredCollectionList[layout.seqNumberOfList]?.collection?.customFields?.itemOrderData"
                                    [channelId]="storeInfo.sellerChannelId"
                                    [layoutMode]="'vertical'"
                                    [itemsPerRow]=2
                                    [itemsPerSlide]=8
                                    [displaySubtitle]="false"
                                    [displayPagination]="false"
                                    [imgSize]="'sm-v'">
                                </vsf-product-swiper>
                            </ng-container>
                            <ng-template #newSwiper>
                                <vsf-product-swiper-v2
                                    [displayType] = "featuredCollectionList[layout.seqNumberOfList]?.displayType"
                                    [productCardType]="featuredCollectionList[layout.seqNumberOfList]?.productCardType"
                                    [displayFields]="featuredCollectionList[layout.seqNumberOfList]?.displayFields"
                                    [collectionSlug]="featuredCollectionList[layout.seqNumberOfList]?.collection?.slug"
                                    [collectionItemOrder]="featuredCollectionList[layout.seqNumberOfList]?.collection?.customFields?.itemOrderData"
                                    [channelId]="storeInfo.sellerChannelId"
                                    [layoutMode]="'vertical'"
                                    [itemsPerSlide]=4
                                    [displaySubtitle]="false"
                                    [displayPagination]="false"
                                    [imgSize]="'sm-v'">
                                </vsf-product-swiper-v2>
                            </ng-template>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="layout.layoutComponentType === 'collection-list-for-shopby'">
                    <div *ngIf="collectionListForShopByGroupList?.length > layout.seqNumberOfList">
                        <div>
                            <vsf-collection-group-carousel
                                [collectionGroup]="collectionListForShopByGroupList[layout.seqNumberOfList]">
                            </vsf-collection-group-carousel>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="layout.layoutComponentType === 'collection-for-series'">
                    <div *ngIf="collectionForSeriesHeroSectionList?.length > layout.seqNumberOfList"
                        class="hero-banner" style="min-height: 180px;">
                        <ng-container *ngIf="collectionForSeriesHeroSectionList[layout.seqNumberOfList]?.medias?.length > 0">
                            <vsf-hero-section class="hero-banner" style="min-height: 180px;"
                                [heroSection]="collectionForSeriesHeroSectionList[layout.seqNumberOfList]"
                                [isMobile]="isMobile">
                            </vsf-hero-section>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>