<div class="hero-slider flex flex-col relative">
  <!-- Fallback image template for SSR -->
  <ng-template #fallbackImage>
    <div class="h-full flex justify-center items-center" style="background: #eee; position:relative;">
      <ng-container *ngIf="medias?.[0] as firstMedia">
        <ng-container *ngIf="firstMedia.type === 'IMAGE'">
          <img [src]="firstMedia | assetPreview:'full'"
               style="pointer-events: none;"
               alt="Hero Placeholder Image"
               class="object-cover w-full h-full"
               [ngClass]="(isMobile && !(heroSection.mobileMedias?.length > 0)) ? 'aspect-[16/9]' : ''"
          />
          <div class="absolute inset-0 bg-gray-500" [style.opacity]="shadowOpacity"></div>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>

  <div class="h-full">
    <div *ngIf="medias?.length > 0" class="h-full">
      <!-- Only show Swiper in browser; fall back to a static image during SSR -->
      <ng-container *ngIf="isBrowser; else fallbackImage">
        <swiper-container
          appSwiper #swiper
          pagination.type="progressbar" pagination-clickable="true"
          centered-slides="true" autoplay-delay="5000" autoplay-disable-on-interaction="false" height="auto"
          rewind="true" lazy="true" autoplay="true" class="flex-grow object-cover custom-pagination h-full" [style.z-index]="1">
          <swiper-slide *ngFor="let media of medias">
            <div class="flex justify-center items-center w-full">
              <div *ngIf="media.type === 'IMAGE'" class="w-full h-full relative">
                <img [src]="media | assetPreview: 'full'"
                     style="pointer-events: none;"
                     class="object-cover w-full h-full"
                     [ngClass]="(isMobile && !(heroSection.mobileMedias?.length > 0)) ? 'aspect-[16/9]' : ''"
                     [width]="swiperSlideWidth"
                     loading="eager"
                     decoding="async"
                     alt="Hero Image"/>
                <div class="absolute inset-0 bg-gray-500" [style.opacity]="shadowOpacity"></div>
              </div>

              <div *ngIf="media.type === 'VIDEO' && media.mimeType === 'video/mp4'" class="w-auto relative">
                <div class="w-auto max-h-none md:max-h-[800px] mx-auto relative">
                  <video style="z-index:1; pointer-events: auto;" [muted]="true" class="object-contain w-full h-full" autoplay playsinline controls>
                    <source [src]="media.source" type="video/mp4" alt="Hero Video">
                  </video>
                  <div class="absolute inset-0 bg-gray-500" [style.opacity]="shadowOpacity" style="pointer-events: none;"></div>
                </div>
              </div>

              <div *ngIf="media.type === 'VIDEO' && media.mimeType === 'video/vimeo' && swiperSlideWidth > 0" class="w-auto relative">
                <div class="w-auto max-h-none md:max-h-[800px] mx-auto relative">
                  <vsf-vimeo-player
                      [source]="media.customFields?.videoUrl || media.source"
                      [width]="swiperSlideWidth"
                      [height]="swiperSlideHeight">
                  </vsf-vimeo-player>
                  <div class="absolute inset-0 bg-gray-500" [style.opacity]="shadowOpacity" style="pointer-events: none;"></div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper-container>
      </ng-container>
    </div>

    <!-- Text & Button Layouts -->
    <div
      *ngIf="layoutType === 'left-text-full-media'"
      style="z-index: 5; pointer-events: none;"
      class="absolute inset-0 flex flex-col items-start justify-between px-2 md:px-8 top-1/2 transform -translate-y-1/2 left-0"
    >
      <div class="hero-text-left">
        <div [ngStyle]="{'color': textColor, 'font-size': isMobile?titleFontSizeMobile:titleFontSize}" class="hero-title whitespace-nowrap" [innerHTML]="title | safeHtml"></div>
        <div [ngStyle]="{'color': textColor}" class="hero-subtitle mt-2" [innerHTML]="subtitle | safeHtml"></div>
        <div *ngIf="button?.enabled && (isMobile ? buttonPositionMobile : buttonPosition) === 'below-text'" class="button-container">
          <button (click)="buttonClick($event); $event.stopPropagation()"
              class="shop-button mt-12 md:mt-20 justify-center rounded-lg focus:outline-none"
              style="pointer-events: auto;"
              [ngStyle]="{'background-color': button.backgroundColor, 'color': button.textColor}"
              [ngClass]="{ 'opacity-80': inFlight }"
              [disabled]="inFlight">
              <div class="flex-container justify-center">
                <p>{{button.text}}</p>
                <fa-icon [icon]="['fas', 'caret-right']" class="ml-2 color-black icon-size"></fa-icon>
              </div>
          </button>
        </div>
      </div>
    </div>

    <div
      *ngIf="layoutType === 'middle-text-full-media'"
      style="z-index: 5; pointer-events: none;"
      class="absolute inset-0 flex flex-col items-center justify-center px-2 md:px-8"
    >
      <div class="hero-text-middle text-center">
        <div [ngStyle]="{'color': textColor, 'font-size': isMobile ? titleFontSizeMobile : titleFontSize}" class="hero-title" [innerHTML]="title | safeHtml"></div>
        <div [ngStyle]="{'color': textColor}" class="hero-subtitle mt-2 mx-auto" [innerHTML]="subtitle | safeHtml"></div>
        <div *ngIf="button?.enabled && (isMobile ? buttonPositionMobile : buttonPosition) === 'below-text'" class="button-container mt-4 md:mt-20">
          <button (click)="buttonClick($event); $event.stopPropagation()"
              class="shop-button justify-center rounded-lg focus:outline-none"
              style="pointer-events: auto;"
              [ngStyle]="{'background-color': button.backgroundColor, 'color': button.textColor}"
              [ngClass]="{ 'opacity-80': inFlight }"
              [disabled]="inFlight">
              <div class="flex-container justify-center">
                <p>{{button.text}}</p>
                <fa-icon [icon]="['fas', 'caret-right']" class="ml-2 color-black icon-size"></fa-icon>
              </div>
          </button>
        </div>
      </div>
    </div>

    <div
      *ngIf="layoutType === 'right-text-full-media'"
      style="z-index: 5; pointer-events: none;"
      class="absolute inset-0 flex flex-col items-end justify-between px-2 md:px-8 top-1/2 transform -translate-y-1/2 right-0"
    >
      <div class="hero-text-right">
        <div [ngStyle]="{'color': textColor, 'font-size': isMobile?titleFontSizeMobile:titleFontSize}" class="hero-title whitespace-nowrap" [innerHTML]="title | safeHtml"></div>
        <div [ngStyle]="{'color': textColor}" class="hero-subtitle mt-2" [innerHTML]="subtitle | safeHtml"></div>
        <div *ngIf="button?.enabled && (isMobile ? buttonPositionMobile : buttonPosition) === 'below-text'" class="button-container">
          <button (click)="buttonClick($event); $event.stopPropagation()"
              class="shop-button mt-4 md:mt-20 justify-center rounded-lg focus:outline-none"
              style="pointer-events: auto;"
              [ngStyle]="{'background-color': button.backgroundColor, 'color': button.textColor}"
              [ngClass]="{ 'opacity-80': inFlight }"
              [disabled]="inFlight">
              <div class="flex-container justify-center">
                <p>{{button.text}}</p>
                <fa-icon [icon]="['fas', 'caret-right']" class="ml-2 color-black icon-size"></fa-icon>
              </div>
          </button>
        </div>
      </div>
    </div>

    <ng-container *ngIf="button?.enabled && (isMobile ? buttonPositionMobile : buttonPosition) === 'custom'">
      <div class="button-container" [ngStyle]="getButtonStyles()">
        <button
          (click)="buttonClick($event); $event.stopPropagation()"
          class="shop-button justify-center rounded-lg focus:outline-none"
          [ngStyle]="{ 'background-color': button?.backgroundColor, 'color': button?.textColor }"
          [ngClass]="{ 'opacity-80': inFlight }"
          [disabled]="inFlight"
        >
          <div class="flex-container justify-center">
            <p>{{ button.text }}</p>
            <fa-icon [icon]="['fas', 'caret-right']" class="ml-2 icon-size"></fa-icon>
          </div>
        </button>
      </div>
    </ng-container>
  </div>
</div>
