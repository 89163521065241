<div class="product-carousel flex flex-col">
  <div *ngIf="headerTitle" [style.z-index]="4" class="flex mt-2 mb-2 mr-2 justify-between text-gray-500 w-100">
    <p class="fa-vertical-bar-medium text-center text-2xl text-[#2E3742]">{{headerTitle}}</p>
    <div>
      <div *ngIf="displayPagination">
        <button class="fa-chevron-left-medium mr-2" (click)="swipeToPrev()">< </button>
        <span>{{ selectedIndex }}</span> / <span>{{ totalSlides }}</span>
        <button class="fa-chevron-right-medium ml-2" (click)="swipeToNext()">> </button>
      </div>
    </div>
  </div>

  <div class="w-100 ml-1 mr-1 min-h-[300px]">
    <ng-container *ngIf="isBrowser; else fallbackList">
      <swiper-container appSwiper #swiper
        [slidesPerView]="itemsPerRow"
        [slidesPerGroup]="itemsPerRow"
        [slidesPerColumn]="gridRows"
        [direction]="swipeDirectoin"
        [grid]="{'rows': gridRows, 'fill': 'row'}"
        space-between="10"
        rewind="true"
        class="flex object-cover custom-pagination"
      >
        <swiper-slide *ngFor="let item of displayedItems; let i = index; trackBy: trackByProductId"
            class="product-grid"
            [ngClass]="{'selected-slide': selectedIndex === i}">
          <div class="flex w-full h-full">
            <ng-container *ngIf="productCardType === 'regular'">
              <vsf-product-card
                [displayType]="displayType"
                [item]="item"
                [displayTitle]="true"
                [displayBuyButton]="displayBuyButton"
                [displayPrice]="displayPrice"
                [displaySubtitle]="displaySubtitle"
                [layoutMode]="layoutMode"
                [imgSize]="imgSize"
                [isMobile]="true"
                class="card"
                [style.height.px]="layoutMode === 'horizontal' ? 180 : undefined">
              </vsf-product-card>
            </ng-container>
            <ng-container *ngIf="productCardType === 'top-asset-small'">
              <vsf-product-card-v3-vertical
                [displayType]="displayType"
                [item]="item"
                [displayTitle]="true"
                [displayBuyButton]="displayBuyButton"
                [displayPrice]="displayPrice"
                [displaySubtitle]="displaySubtitle"
                [displayViewers]="displayViewers"
                [displayLikes]="displayLikes"
                [displayRanking]="displayRanking"
                [displaySold]="displaySold"
                [layoutMode]="layoutMode"
                [imgSize]="imgSize"
                [isMobile]="true"
                [size]="'small'"
                class="card">
              </vsf-product-card-v3-vertical>
            </ng-container>
          </div>
        </swiper-slide>
      </swiper-container>
    </ng-container>

    <!-- Fallback template for SSR -->
    <ng-template #fallbackList>
      <div class="grid grid-cols-2 gap-4 justify-center items-center mx-auto">
        <div
          *ngFor="let item of displayedItems.slice(0,4); trackBy: trackByProductId"
          class="flex-shrink-0"
        >
          <ng-container *ngIf="productCardType === 'regular'">
            <vsf-product-card
              [displayType]="displayType"
              [item]="item"
              [displayTitle]="true"
              [displayBuyButton]="displayBuyButton"
              [displayPrice]="displayPrice"
              [displaySubtitle]="displaySubtitle"
              [layoutMode]="layoutMode"
              [imgSize]="imgSize"
              [isMobile]="true"
              class="card"
              [style.height.px]="layoutMode === 'horizontal' ? 180 : undefined">
            </vsf-product-card>
          </ng-container>
          <ng-container *ngIf="productCardType === 'top-asset-small'">
            <vsf-product-card-v3-vertical
              [displayType]="displayType"
              [item]="item"
              [displayTitle]="true"
              [displayBuyButton]="displayBuyButton"
              [displayPrice]="displayPrice"
              [displaySubtitle]="displaySubtitle"
              [displayViewers]="displayViewers"
              [displayLikes]="displayLikes"
              [displayRanking]="displayRanking"
              [displaySold]="displaySold"
              [layoutMode]="layoutMode"
              [imgSize]="imgSize"
              [isMobile]="true"
              [size]="'small'"
              class="card">
            </vsf-product-card-v3-vertical>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </div>
</div>
