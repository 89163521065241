<div class="max-w-6xl mx-auto flex items-center justify-between px-4 py-2">
  <div class="flex items-center">
      <img [src]="selectedVariant?.featuredAsset | assetPreview:'small'" class="h-24 mr-4" alt="Product Image">
      <div>
          <div class="text-lg font-bold">{{ product?.name }}</div>
          <div class="text-sm text-gray-500">SKU: {{ selectedVariant?.sku }}</div>
          <div *ngIf="selectedVariant?.options.length > 0" class="flex flex-col text-lg text-gray-650 mr-4">
                <ng-container *ngFor="let option of optionsWithGroupNames; let i = index">
                    <strong>{{ option.groupName }}: {{ option.optionName }}</strong>
                </ng-container>
          </div>
      </div>
  </div>
  <div class="flex">
      <div class="flex items-center mb-2">
          <ng-container *ngIf="selectedVariantMSRP && discountPercentage">
              <div class="flex flex-row items-start">
                  <p class="mrsp-price text-gray-300 line-through mx-1">
                      {{ (selectedVariantMSRP | separatedFormatPrice)?.price }}
                  </p>
                  <span class="save-label whitespace-nowrap mx-2">
                      -{{ discountPercentage | number:'1.0-0' }}%
                  </span>
              </div>
          </ng-container>
          <p class="font-[#2E3742] mx-4">
              <span class="currency-tag">{{ (selectedVariant?.price | separatedFormatPrice)?.currency }}</span>
              <span class="product-price">{{ (selectedVariant?.price | separatedFormatPrice)?.price }}</span>
          </p>
      </div>
      <div class="flex items-center">
        <div>
            <vsf-product-shop-now-banner
                [product]="product"
                [selectedVariant]="selectedVariant"
                [isMobile]="isMobile"
                [inStock]="inStock"
                [buttonBackgroundColor]="buttonBackgroundColor"
            >
            </vsf-product-shop-now-banner>
        </div>
      </div>
  </div>
</div>