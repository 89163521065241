<footer
  [ngStyle]="{'background-color': isMobile ? '#F2F4F7' : storeColor?.footerBackgroundColor, 'color': isMobile ? 'black' : storeColor?.footerFontColor}"
>
  <div class="mx-auto max-w-6xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
    <!-- Use custom grid template columns -->
    <div class="xl:grid xl:grid-cols-[200px_1fr_200px] xl:gap-8">
      <!-- First Column: Store Info & Logo -->
      <div class="space-y-8">
        <!-- Logo -->
        <a [routerLink]="[baseUrl]">
          <img class="w-20 h-auto hover:scale-110 transition duration-300 ease-in-out"
            *ngIf="storeLogo"
            [src]="storeLogo | assetPreview:'small'"
            alt="logo"
          />
        </a>
        <!-- Contact Us -->
        <p>
          <a
            href="mailto:{{ contactEmail }}"
            class="text-sm"
            [ngClass]="isMobile ? '' : 'hover:text-gray-300'"
          >
            Contact Us
          </a>
        </p>
        <!-- Social Media Links -->
        <div *ngIf="snsInfo" class="flex flex-col gap-y-2">
          <div class="flex flex-row space-x-2">
            <a *ngIf="snsInfo?.instagram"
              [href]="snsInfo?.instagram"
              target="_blank"
              class="text-base cursor-pointer"
              [ngClass]="isMobile ? '' : 'hover:text-gray-300'"
            >
              <span class="sr-only">Instagram</span>
              <fa-icon [icon]="['fab', 'instagram']" class="text-2xl"></fa-icon>
            </a>
            <a *ngIf="snsInfo?.facebook"
              [href]="snsInfo?.facebook"
              target="_blank"
              class="text-base cursor-pointer"
              [ngClass]="isMobile ? '' : 'hover:text-gray-300'"
            >
              <span class="sr-only">Facebook</span>
              <fa-icon [icon]="['fab', 'facebook']" class="text-2xl"></fa-icon>
            </a>
            <a *ngIf="snsInfo?.xTwitter"
              [href]="snsInfo?.xTwitter"
              target="_blank"
              class="text-base cursor-pointer"
              [ngClass]="isMobile ? '' : 'hover:text-gray-300'"
            >
              <span class="sr-only">X</span>
              <fa-icon [icon]="['fab', 'x-twitter']" class="text-2xl"></fa-icon>
            </a>
            <a *ngIf="snsInfo?.youtube"
              [href]="snsInfo?.youtube"
              target="_blank"
              class="text-base cursor-pointer"
              [ngClass]="isMobile ? '' : 'hover:text-gray-300'"
            >
              <span class="sr-only">Youtube</span>
              <fa-icon [icon]="['fab', 'youtube']" class="text-2xl"></fa-icon>
            </a>
          </div>
        </div>
      </div>

      <!-- Second Column: Navigation Columns -->
      <div
        class="mt-16 grid gap-8 xl:mt-0"
        [ngClass]="{'grid-cols-2': isMobile}"
        [ngStyle]="{'grid-template-columns': !isMobile ? 'repeat(' + navigationColumns.length + ', minmax(0,1fr))' : null }"
      >
        <ng-container *ngFor="let navigationColumn of navigationColumns">
          <div [class]="isMobile ? '' : 'md:grid md:grid-cols-1 md:gap-8'" class="space-y-4">
            <div *ngIf="navigationColumn?.items?.length">
              <h3 class="text-sm font-semibold"
                >
                {{ navigationColumn.name }}
              </h3>
              <ul class="mt-6 space-y-4">
                <li *ngFor="let item of navigationColumn.items">
                  <ng-container [ngSwitch]="item.type">
                    <div *ngSwitchCase="'page'">
                      <a
                        [href]="getItemHref(item)"
                        class="text-sm cursor-pointer"
                        [ngClass]="isMobile ? '' : 'hover:text-gray-300'"
                      >
                        {{ item.label }}
                      </a>
                    </div>
                    <div *ngSwitchCase="'website'">
                      <a
                        [href]="getItemHref(item)"
                        target="_blank"
                        class="text-sm cursor-pointer"
                        [ngClass]="isMobile ? '' : 'hover:text-gray-300'"
                      >
                        {{ item.label }}
                      </a>
                    </div>
                    <div *ngSwitchCase="'policy'">
                      <a
                        class="text-sm cursor-pointer"
                        [href]="getItemHref(item)"
                        [ngClass]="isMobile ? '' : 'hover:text-gray-300'"
                      >
                        {{ item.label }}
                      </a>
                    </div>
                    <div *ngSwitchCase="'order-status'">
                      <a
                        [routerLink]="[orderStatusHref]"
                        class="text-sm cursor-pointer"
                        [ngClass]="isMobile ? '' : 'hover:text-gray-300'"
                      >
                        {{ item.label }}
                      </a>
                    </div>
                    <div *ngSwitchCase="'contact-us'">
                      <a
                        href="mailto:{{ item.url }}"
                        class="text-sm cursor-pointer"
                        [ngClass]="isMobile ? '' : 'hover:text-gray-300'"
                      >
                        {{ item.label }}
                      </a>
                    </div>
                  </ng-container>
                </li>
              </ul>
            </div>
          </div>
        </ng-container>
      </div>

      <!-- Third Column: Disclaimer -->
      <div class="mt-10 md:mt-0">
        <ng-container *ngIf="disclaimer">
          <h3 class="text-sm font-semibold"
            >
            Disclaimer
          </h3>
          <div class="mt-6 text-sm"

            [innerHtml]="disclaimer | safeHtml">
          </div>
        </ng-container>
      </div>
    </div>

    <!-- Bottom Area -->
    <div
      class="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24"
      [ngClass]="isMobile ? 'border-gray-200' : ''"
    >
      <div *ngIf="copyrightNotice" class="text-sm text-center"

        [innerHtml]="copyrightNotice | safeHtml">
      </div>
      <div class="pt-4 flex justify-center text-sm items-center"
          [ngClass]="isMobile ? 'border-gray-200 ' : ''"
      >Powered by &nbsp;<a [href]="'https://www.dealomega.com'" [ngClass]="isMobile ? '' : 'hover:text-gray-300'"> Deal Omega LLC</a>
      </div>
    </div>
  </div>
</footer>
