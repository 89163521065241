<button class="search-button relative" (click)="onSearchButtonClick($event, '')">
  <svg class="search-icon size-5 text-gray-600 hover:scale-110 transition duration-300 ease-in-out hover:font-bold"
    fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
    <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
  </svg>
</button>

<div *ngIf="showOverlay" class="search-overlay" #searchOverlay>
  <div class="search-container relative flex flex-row justify-between">
      <input type="text" [formControl]="searchTerm" (keydown.enter)="doSearch(searchTerm.value)"
             class="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
             placeholder="Search..." aria-label="Search input">
      <button class="absolute inset-y-0 right-0 pr-3 flex items-center" (click)="doSearch(searchTerm.value)">
        <svg class="search-icon size-5 text-gray-400"
          fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
          <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
        </svg>
      </button>
  </div>
  <button class="close-button" (click)="closeOverlay()">
      <fa-icon [icon]="['fas', 'times']" class="size-5 text-gray-400"></fa-icon>
  </button>
</div>