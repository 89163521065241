<ng-container *ngIf="showReturnForm !== undefined; else loading">
    <vsf-layout *ngIf="showReturnForm; else regularPageLayout">
        <router-outlet></router-outlet>
    </vsf-layout>
</ng-container>
<ng-template #regularPageLayout>
    <vsf-layout *ngIf="(readyToDisplay$ | async) && (uiState.isMobile !== null)">
        <vsf-layout-header style="position: relative; min-height: 60px;" id="page-header">
            <!-- top promotion message -->
            <div *ngIf="uiState.isForStore; else nonStore">
                <div *ngIf="!uiState.isMobile; else storeMobileHeader">
                    <div *ngIf="pageMessages.headerMessage">
                        <div class="header-message text-center text-2xl py-1 px-2 xl:px-0"
                            [ngStyle]="{'background-color': storeColor?.headerBackgroundColor, 'color': storeColor?.headerFontColor}">
                            <div class="max-w-6xl mx-2 md:mx-auto flex items-center justify-center">
                                <p> {{pageMessages.headerMessage}} </p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="uiState.displayHeaderMenu && storeInfo.identifier"
                        class="bg-white max-w-6xl mx-auto px-2 py-2 items-center justify-between"
                        style="min-height: 60px;">
                        <div class="flex flex-row">
                            <vsf-store-logo class="flex-shrink-0"
                                [normalLogo]="storeLogoConfig.normalLogo"
                                [nameLogo]="storeLogoConfig.nameLogo"
                                [smallLogo]="storeLogoConfig.smallLogo"
                                [name]="storeInfo.name"
                                [path]="[baseUrl]"
                                [logoHeight]="'50px'"
                                [displayName]="storeLogoConfig.displayName"
                                [isMobile]="uiState.isMobile"
                            >
                            </vsf-store-logo>
                            <div class="nav-menus flex items-center space-x-4 ml-auto">
                                <nav *ngIf="storeNavMenu.length > 0" class="py-2 flex flex-row">
                                  <div *ngFor="let item of storeNavMenu" class="flex items-center">
                                    <button (click)="navigateToPage(item)" class="nav-banner-button">{{ item.label }}</button>
                                  </div>
                                </nav>
                            </div>
                            <div class="grid grid-cols-3 col-span2 ml-2 justify-end items-center gap-x-2  flex-shrink-0">
                                <vsf-product-search-bar class="z-20"></vsf-product-search-bar>
                                <vsf-cart-toggle class="text-white z-10" (toggle)="openCartDrawer()"></vsf-cart-toggle>
                                <vsf-account-button *ngIf="!hideAccount" class="text-black text-lg z-10"
                                ></vsf-account-button>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #storeMobileHeader>
                    <ng-container *ngIf="uiState.displayHeaderMenu">
                        <div class="flex flex-row justify-center pt-4 pb-2 bg-white" style="min-height: 60px;">
                            <div class="grid grid-cols-2 justify-center items-center gap-x-6">
                                <button id="navButton" class="nav-button ml-3" (click)="toggleMobileMenu()">
                                    <svg class="size-5 text-grey-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                    </svg>
                                </button>
                                <vsf-product-search-bar class="z-20"></vsf-product-search-bar>
                            </div>
                            <div class="flex flex-grow felx-grow items-center justify-center">
                                <vsf-store-logo
                                    [normalLogo]="storeLogoConfig.normalLogo"
                                    [nameLogo]="storeLogoConfig.nameLogo"
                                    [smallLogo]="storeLogoConfig.smallLogo"
                                    [name]="storeInfo.name"
                                    [path]="[baseUrl]"
                                    [logoHeight]="'50px'"
                                    [displayName]="storeLogoConfig.displayName"
                                    [isMobile]="uiState.isMobile"
                                >
                                </vsf-store-logo>
                            </div>
                            <div class="grid grid-cols-2 justify-center items-center gap-x-6">
                                <vsf-cart-toggle class="text-white z-10" (toggle)="openCartDrawer()"></vsf-cart-toggle>
                                <vsf-account-button *ngIf="!hideAccount" class="text-black text-lg z-10"
                                ></vsf-account-button>
                            </div>
                        </div>
                        <hr>
                        <div *ngIf="uiState.isNavExpanded && uiState.isMobile" class="bg-white nav-container">
                            <div class="flex justify-center items-center">
                                <nav *ngIf="storeNavMenu.length > 0" class="mx-auto py-2 flex flex-row justify-between">
                                    <div *ngFor="let item of storeNavMenu" class="flex items-center">
                                        <button (click)="navigateToPage(item)" class="nav-banner-button">{{item.label}}</button>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </ng-container>
                </ng-template>
            </div>
            <ng-template #nonStore>
                <div>
                    <div *ngIf="pageMessages?.headerMessage">
                        <div class="header-message bg-black text-white text-center text-2xl py-1 px-2 xl:px-0">
                            <div class="max-w-6xl mx-2 md:mx-auto flex items-center justify-center">
                                <p class="block"> {{pageMessages?.headerMessage}} </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-white max-w-6xl mx-auto px-2 py-2 flex items-center justify-between">
                    <div style="width: 85%;">
                        <div class="flex">
                            <div   class="flex flex-row">
                                <div  class="flex flex-row items-center w-auto" [ngStyle]="{'min-width': uiState.isMobile ? '60px' : '250px'}">
                                    <vsf-store-logo
                                        [normalLogo]="storeLogoConfig.normalLogo"
                                        [nameLogo]="storeLogoConfig.nameLogo"
                                        [smallLogo]="storeLogoConfig.smallLogo"
                                        [name]="storeInfo.name"
                                        [path]="['/']"
                                        [logoHeight]="'30px'"
                                        [displayName]="true"
                                        [isMobile]="uiState.isMobile"
                                    >
                                    </vsf-store-logo>
                                    <a *ngIf="!uiState.isMobile" [routerLink]="['/']">
                                        <div class="justify-start items-center">
                                            <div *ngIf="!storeLogoConfig.nameLogo && storeInfo.name">
                                                <p class="header-title mr-5 ml-2 text-left">{{ storeInfo.name }}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div *ngIf="uiState.isMobile && uiState.isForHomepage">
                                    <button id="navButton" class="nav-button ml-3" (click)="clickOnNavButton()">
                                        <svg class="size-5 text-grey-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center space-x-4 ml-4">
                        <div class="control-icons grid grid-cols-3 col-span2 ml-2 justify-end items-center gap-x-2">
                            <vsf-product-search-bar class="z-20"></vsf-product-search-bar>
                            <vsf-cart-toggle class="text-white z-10" (toggle)="openCartDrawer()"></vsf-cart-toggle>
                            <vsf-account-button *ngIf="!hideAccount" class="text-black text-lg z-10">
                            </vsf-account-button>
                        </div>
                    </div>
                </div>
                <ng-template [ngIf]="uiState.isForHomepage">
                    <div *ngIf="channelInfo$ | async as channel">
                        <hr>
                        <div *ngIf="uiState.isNavExpanded && uiState.isMobile" class="bg-white nav-container">
                            <div class="max-w-6xl mx-auto py-2 flex justify-between">
                                <button (click)="scrollToSection('new')" class="nav-banner-button">{{channel.customFields?.NewArrivalsCollectionLabel}}</button>
                                <button (click)="scrollToSection('for-you')" class="nav-banner-button">{{channel.customFields?.DefaultCollectionLabel}}</button>
                                <button (click)="scrollToSection('sales-offers')" class="nav-banner-button">{{channel.customFields?.HotSalesCollectionLabel}}</button>
                            </div>
                        </div>
                        <div *ngIf="!uiState.isMobile" class="nav-banner bg-white">
                            <div class="max-w-6xl mx-auto py-2 flex items-left">
                                <button (click)="scrollToSection('new')" class="nav-banner-button">{{channel.customFields?.NewArrivalsCollectionLabel}}</button>
                                <button (click)="scrollToSection('for-you')" class="nav-banner-button">{{channel.customFields?.DefaultCollectionLabel}}</button>
                                <button (click)="scrollToSection('sales-offers')" class="nav-banner-button">{{channel.customFields?.HotSalesCollectionLabel}}</button>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-template>
            <hr>
        </vsf-layout-header>

        <!-- Cart Drawer -->
        <div *ngIf="uiState.isForStore; else nonStoreCart">
            <vsf-cart-drawer (close)="closeCartDrawer()" [storeIdentifier]="storeInfo?.identifier" [visible]="cartDrawerVisible$ | async"></vsf-cart-drawer>
        </div>
        <ng-template #nonStoreCart>
            <vsf-cart-drawer (close)="closeCartDrawer()" [visible]="cartDrawerVisible$ | async"></vsf-cart-drawer>
        </ng-template>

        <!-- Mobile menu overlay -->
        <div *ngIf="uiState.isMobile && uiState.isForStore && orderStatusHref">
            <vsf-mobile-menu-overlay
                [isOpen]="uiState.isNavExpanded"
                [navMenu]="storeNavMenu"
                [orderStatusHref]="orderStatusHref"
                (onClose)="closeMobileMenu()"
                (onNavigate)="navigateToPage($event)"
                (onContactUs)="handleAction('Contact Us')"
            ></vsf-mobile-menu-overlay>
        </div>

        <!-- Page Content -->
        <div class="contents" class="max-w-6xl mx-auto"  [ngStyle]="{'min-height': uiState.isMobile ? '800px' : '1200px'}">
            <router-outlet></router-outlet>
        </div>

        <!-- Page Footer -->
        <vsf-layout-footer *ngIf="uiState.displayFooterMenu && navigationColumns?.length > 0" style="min-height: 425px;">
            <vsf-page-footer
                [navigationColumns]="navigationColumns"
                [storeLogo]="storeLogoConfig.normalLogo"
                [policyContentList]="policyContentList"
                [isMobile]="uiState.isMobile"
                [snsInfo]="storeInfo.snsInfo"
                [baseUrl]="baseUrl"
                [storeEmail]="storeInfo.email"
                [orderStatusHref]="orderStatusHref"
                [storeColor]="storeColor">
            </vsf-page-footer>
        </vsf-layout-footer>
    </vsf-layout>
</ng-template>
<ng-container *ngIf="fullscreenPreviewOpen$ | async">
    <vsf-vimeo-fullscreen-preview
        [videoId]="vimeoVideoId"
        (close)="closeFullscreenPreview()">
    </vsf-vimeo-fullscreen-preview>
</ng-container>

<!-- Loading Placeholder -->
<ng-template #loading>
    <p>{{showReturnForm}}</p>
    <p>Loading...</p>
</ng-template>