<form [formGroup]="addressForm">
    <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
        <!-- First Name -->
        <div>
            <label
                for="firstName"
                class="block text-sm font-medium text-gray-700"
            >
                First name
            </label>
            <div class="mt-1">
                <input
                type="text"
                id="firstName"
                name="firstName"
                formControlName="firstName"
                class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                [readonly]="readonlyFullName"
                />
            </div>
        </div>

        <!-- Last Name -->
        <div>
            <label
                for="lastName"
                class="block text-sm font-medium text-gray-700"
            >
                Last name
            </label>
            <div class="mt-1">
                <input
                type="text"
                id="lastName"
                name="lastName"
                formControlName="lastName"
                class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                [readonly]="readonlyFullName"
                />
            </div>
        </div>

        <div class="sm:col-span-2">
            <label
                htmlFor="streetLine1"
                class="block text-sm font-medium text-gray-700"
            >
                Address
            </label>
            <div class="mt-1">
                <input
                    type="text"
                    name="streetLine1"
                    id="streetLine1"
                    formControlName="streetLine1"
                    autoComplete="street-address"
                    class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500
            sm:text-sm"
                />
            </div>
        </div>

        <div class="sm:col-span-2">
            <label
                htmlFor="streetLine2"
                class="block text-sm font-medium text-gray-700"
            >
                Apartment, suite, etc. (optional)
            </label>
            <div class="mt-1">
                <input
                    type="text"
                    name="streetLine2"
                    id="streetLine2"
                    formControlName="streetLine2"
                    class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500
            sm:text-sm"
                />
            </div>
        </div>

        <div>
            <label
                htmlFor="city"
                class="block text-sm font-medium text-gray-700"
            >
                City
            </label>
            <div class="mt-1">
                <input
                    type="text"
                    name="city"
                    id="city"
                    autoComplete="address-level2"
                    formControlName="city"
                    class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500
            sm:text-sm"
                />
            </div>
        </div>

        <div>
            <label
                htmlFor="countryCode"
                class="block text-sm font-medium text-gray-700"
            >
                Country
            </label>
            <div class="mt-1">
                <select
                    class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    formControlName="countryCode" id="countryCode">
                    <option *ngFor="let country of availableCountries" [ngValue]="country.code">
                        {{ country.name }}
                    </option>
                </select>
            </div>
        </div>

        <div>
            <label
                htmlFor="province"
                class="block text-sm font-medium text-gray-700"
            >
                State
            </label>
            <div class="mt-1">
                <select
                    *ngIf="availableStates.length > 0"
                    id="province"
                    formControlName="province"
                    class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                >
                    <option value="">Select State</option>
                    <option *ngFor="let state of availableStates" [ngValue]="state.code">
                        {{ state.name }}
                    </option>
                </select>
                <input
                    *ngIf="availableStates.length === 0"
                    placeholder="Please select Country first!"
                    type="text"
                    name="province"
                    id="province"
                    formControlName="province"
                    autocomplete="address-level1"
                    class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                />
            </div>
        </div>

        <div>
            <label
                htmlFor="postalCode"
                class="block text-sm font-medium text-gray-700"
            >
                Zip Code
            </label>
            <div class="mt-1">
                <input
                    type="text"
                    name="postalCode"
                    id="postalCode"
                    formControlName="postalCode"
                    autoComplete="postal-code"
                    class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500
            sm:text-sm"
                />
            </div>
        </div>

        <div class="sm:col-span-2">
            <label
                htmlFor="phoneNumber"
                class="block text-sm font-medium text-gray-700"
            >
                Phone Number (optional)
            </label>
            <div class="mt-1">
                <input
                    type="text"
                    name="phoneNumber"
                    id="phoneNumber"
                    formControlName="phoneNumber"
                    autoComplete="tel"
                    class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500
            sm:text-sm"
                />
            </div>
        </div>
    </div>
</form>
