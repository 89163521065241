import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DataService } from './data/data.service';
import { GetProductAllDetailQuery, GetProductAllDetailQueryVariables } from '../../common/generated-types';
import { GET_PRODUCT_ALL_DETAIL } from '../../common/graphql/documents.graphql';
import { NGXLogger } from 'ngx-logger';

@Injectable({ providedIn: 'root' })
export class ProductResolver implements Resolve<any> {
  constructor(
    private dataService: DataService,
    private logger: NGXLogger,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const slug = route.paramMap.get('slug') || '';
    return this.dataService
    .query<GetProductAllDetailQuery, GetProductAllDetailQueryVariables>(GET_PRODUCT_ALL_DETAIL, {
        slug,
    }).pipe(
      map(result => {
        if (result?.product) {
          return result?.product;
        } else {
          // If product doesn't exist, navigate to not-found or return null
          this.logger.error(`ProductResolver: Product not found for slug '${slug}'`);
          this.router.navigateByUrl('/not-found', { skipLocationChange: true });
          return null;
        }
      }),
      catchError((err) => {
        // In case of errors, also go to not-found
        this.logger.error(`ProductResolver: Error ${err} fetching product for slug '${slug}'`);
        this.router.navigateByUrl('/not-found', { skipLocationChange: true });
        return of(null);
      })
    );
  }
}