import { ChangeDetectionStrategy, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { Collection, GetStoreInfoQuery } from '../../common/generated-types';
import { NGXLogger } from 'ngx-logger';
import { safeJSONParse } from '../../common/utils/safe-json-parser';
import { CollectionGroupData, ColorConfig } from '../../common/interfaces';
import { notNullOrUndefined } from '../../common/utils/not-null-or-undefined';
import { StateService } from '../../core/providers/state/state.service';
import { CanonicalService } from '../../core/providers/canonical/canonical.service';

@Component({
  selector: 'vsf-store-shopping-page',
  templateUrl: './store-shopping-page.component.html',
  styleUrls: ['./store-shopping-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoreShoppingPageComponent implements OnInit {
  storeInfo: GetStoreInfoQuery['storeSite'];
  sellerChannelId: string;
  baseUrl = '';
  shopHost: string;
  isShoppingRoute: boolean;
  isSearchRoute: boolean;

  colorConfig: ColorConfig | null;

  collectionForSeriesList: Collection[];
  collectionListForShopByGroupList: CollectionGroupData[];

  isMobile: boolean | null = null;
  readyToDisplay$ = new BehaviorSubject<boolean>(false);
  pathSegment: string = '';
  lastSegment: string;

  constructor(private route: ActivatedRoute,
              private stateService: StateService,
              private canonicalService: CanonicalService,
              private logger: NGXLogger) { }

  async ngOnInit() {
    const routeDetect$ = this.route.url.pipe(
      map((urlSegments) => {
        this.lastSegment = urlSegments[urlSegments.length - 1].path;
        this.pathSegment = this.lastSegment;
        const isShoppingRoute = this.lastSegment === 'shopping';
        const isSearchRoute = this.lastSegment === 'search';
        return {
          isShoppingRoute,
          isSearchRoute
        };
      })
    );
    this.logger.debug('Store Shopping Page Component Initialized', this.route.params);
    const storeInfo$ = this.stateService?.select((state:any) => state.storeInfo).pipe(take(1), filter(notNullOrUndefined));
    const isMobile$ = this.stateService.select(state => state.isMobile).pipe(take(1), filter(notNullOrUndefined));
    const storePath$ = this.stateService.select(state => state.storePath).pipe(take(1), filter(notNullOrUndefined));
    const shopHost$ = this.stateService.select(state => state.shopHost).pipe(take(1), filter(notNullOrUndefined));
    await combineLatest([routeDetect$, storeInfo$, isMobile$, storePath$, shopHost$]).subscribe(([{isShoppingRoute,isSearchRoute}, storeInfo, isMobile, storePath, shopHost]) => {
      this.storeInfo = storeInfo;
      this.isMobile = isMobile;
      this.isShoppingRoute = isShoppingRoute;
      this.isSearchRoute = isSearchRoute;
      this.baseUrl = storePath;
      this.shopHost = shopHost;
    });
    this.canonicalService.setCanonicalURL(`${this.shopHost}${this.baseUrl}/${this.lastSegment}`);
    this.sellerChannelId = this.storeInfo?.sellerChannelId || '';
    this.colorConfig = safeJSONParse<ColorConfig>(this.storeInfo?.colorConfig, this.logger);
    this.collectionForSeriesList = this.storeInfo?.collectionForSeriesListData as Collection[];
    this.collectionListForShopByGroupList = this.storeInfo?.collectionGroupDataList as CollectionGroupData[];
    this.readyToDisplay$.next(true);
  }

  ngOnDestroy() {
    this.canonicalService.removeCanonicalURL();
  }
}