<div class="mb-4 block">
    <div *ngFor="let facet of facets; trackBy:trackById" class="text-sm"
         [class.enabled]="activeFacetValueIds.length">
        <div class="py-3 font-medium text-gray-900 uppercase">{{ facet.name | titlecase }}</div>
        <ul class="space-y-4">
            <li *ngFor="let value of facet.values; trackBy:trackById" [class.active]="isActive(value.id)" class="hover:font-bold">
                <label class=""><input type="checkbox" [checked]="isActive(value.id)"
                                       (change)="toggleFacetValueIdInRoute(facet.id, value.id)"
                                       class="h-4 w-4 border-gray-300 rounded text-primary-600 focus:ring-primary-500 cursor-pointer">
                    <span class="ml-3 text-sm text-gray-600 cursor-pointer">{{ value.name }}</span>
                </label>
            </li>
        </ul>
    </div>
</div>
