<dl class="border-t mt-6 border-gray-200 py-6 space-y-6">
    <ng-container *ngIf="cart.discounts && cart.discounts.length > 0">
        <div
          class="flex justify-between"
          *ngFor="let discount of cart.discounts"
        >
          <dt class="flex items-center text-sm">
            Discount
            <!-- You can optionally display discount.code or any property here -->
            <span
              *ngIf="discount.description"
              class="ml-2 rounded-full bg-gray-200 px-2 py-0.5 text-xs tracking-wide text-gray-600"
            >
              {{ discount.description }}
            </span>
          </dt>
          <dd class="text-sm font-medium text-gray-900">
            {{ discount.amount | formatPrice }}
          </dd>
        </div>
    </ng-container>
    <div class="flex items-center justify-between">
        <dt class="text-sm">Subtotal</dt>
        <dd class="text-sm font-medium text-gray-900">{{ cart.subTotal | formatPrice }}</dd>
    </div>
    <hr>
    <div class="flex items-center justify-between">
        <dt class="text-sm">
            Shipping
        </dt>
        <dd class="text-sm font-medium text-gray-900">
            {{ cart.shippingWithTax | formatPrice }}
        </dd>
    </div>
    <hr>
    <div class="flex items-center justify-between">
        <dt class="text-sm">Estimated tax</dt>
        <dd class="text-sm font-medium text-gray-900">{{ (cart.subTotalWithTax-cart.subTotal)  | formatPrice }}</dd>
    </div>
    <div class="flex items-center justify-between border-t border-gray-200 pt-6">
        <dt class="text-base font-medium">Total</dt>
        <dd class="text-base font-medium text-gray-900">
            {{ cart.totalWithTax | formatPrice }}
        </dd>
    </div>
</dl>
