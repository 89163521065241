import { Subscription } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component, EventEmitter, HostListener,
    Input, OnDestroy, OnInit, Output, ViewChild,
    PLATFORM_ID, Inject,
    SimpleChanges,
    OnChanges,
    ElementRef,
    AfterViewInit,
} from '@angular/core';
import { Asset, GetProductAllDetailQuery, ProductOption, ProductOptionGroup, ProductVariant } from '../../../common/generated-types';
import { StateService } from '../../../core/providers/state/state.service';
import { ActiveService } from '../../../core/providers/active/active.service';
import { safeJSONParse } from '../../../common/utils/safe-json-parser';
import { ColorConfig, ProductFeatureData } from '../../../common/interfaces';

type Variant = NonNullable<GetProductAllDetailQuery['product']>['variants'][number];
type Collection = NonNullable<GetProductAllDetailQuery['product']>['collections'][number];

@Component({
    selector: 'vsf-product-info',
    templateUrl: './product-info.component.html',
    styleUrls: ['./product-info.component.scss'],
    animations: [
        trigger('expandCollapse', [
            state('collapsed', style({ height: '0', overflow: 'hidden' })),
            state('expanded', style({ height: '*' })),
            transition('collapsed <=> expanded', animate('300ms ease-in-out'))
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductInfoComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input() selectedVariant: Variant;
    @Input() colorConfig: ColorConfig;
    @Input() isMobile: boolean;
    @Input() product: GetProductAllDetailQuery['product'];

    @Output() variantChanged = new EventEmitter<{ variant: Variant }>();
    @Output() shopNowBannerVisibilityChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    private shopNowBannerElement: HTMLElement;
    private lastVisibility: boolean;

    selectedAsset: { id: string; preview: string; };
    qtyInCart: { [id: string]: number; } = {};
    qty = 1;
    breadcrumbs: Collection['breadcrumbs'] | null = null;
    inFlight = false;
    inStock = true;
    private sub: Subscription;
    selectedVariantMSRP: number | null = null;
    discountPercentage: number | null = null;

    sizeOptions: ProductOption[] = [];
    colorOptions: ProductOption[] = [];
    sizeOptionGroupId: string | undefined;
    colorOptionGroupId: string | undefined;
    selectedSize: string | null = null;
    selectedColor: string | null = null;
    selectedColorName: string | null = null;
    variantImage: Asset | null = null;

    productFeatures: ProductFeatureData[] = [];
    foldedSections: { [key: string]: boolean } = {};
    colorOptionsName: string;
    sizeOptionsName: string;

    static readonly DROPDOWN_LIST_PRODUCT_OPTION_CODE = 'size';
    static readonly THUMB_SELECTION_PRODUCT_OPTION_CODE = 'color';

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private stateService: StateService,
        private activeService: ActiveService,
        private changeDetector: ChangeDetectorRef,
        private logger: NGXLogger) {
    }

    ngOnInit() {
        this.initializeComponentState();
        this.sub = this.activeService.activeOrder$.subscribe(order => {
            this.qtyInCart = {};
            for (const line of order?.lines ?? []) {
                this.qtyInCart[line.productVariant.id] = line.quantity;
            }
        });
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.product || changes.selectedVariant) {
            this.initializeComponentState();
        }
    }

    ngAfterViewInit() {
        if(isPlatformBrowser(this.platformId)) {
            this.shopNowBannerElement = document.querySelector('#shopNowBanner') as HTMLElement;
            this.checkShopNowBannerVisibility();
        }
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        this.checkShopNowBannerVisibility();
    }

    private checkShopNowBannerVisibility() {
        if (this.shopNowBannerElement) {
            const rect = this.shopNowBannerElement.getBoundingClientRect();
            const isScreenBelowBanner = rect.bottom <= 0;
            // Emit the event only if the visibility state has changed
            if (isScreenBelowBanner !== this.lastVisibility) {
                this.shopNowBannerVisibilityChange.emit(!isScreenBelowBanner);
                this.lastVisibility = isScreenBelowBanner;
            }
        }
    }

    private initializeComponentState() {
        if (!this.product) {
            return;
        }
        // Reset or reinitialize component state
        this.productFeatures = [];
        this.foldedSections = {};
        this.qtyInCart = {};
        this.sizeOptions = [];
        this.colorOptions = [];
        this.selectedSize = null;
        this.selectedColor = null;
        this.selectedColorName = null;
        this.selectedVariantMSRP = null;
        this.discountPercentage = null;
        this.inStock = true;
        // Initialize product features
        if (this.product.customFields?.ProductFeatures) {
            this.product.customFields.ProductFeatures.forEach((feature: string) => {
                const productFeature = safeJSONParse<ProductFeatureData>(feature, this.logger);
                if (productFeature) {
                    this.productFeatures.push(productFeature);
                    this.foldedSections[productFeature.title] = true;
                }
            });
        }
        // Initialize options
        this.initializeOptions();
        // If selectedVariant is provided, set selectedSize and selectedColor based on it
        if (this.selectedVariant) {
            const variantOptions = this.selectedVariant.options;
            for (const option of variantOptions) {
                if (option.groupId === this.sizeOptionGroupId) {
                    this.selectedSize = option.code;
                } else if (option.groupId === this.colorOptionGroupId) {
                    this.selectedColor = option.code;
                    this.selectedColorName = option.name;
                }
            }
        }
        // Now that selectedSize is set, reset the color options based on selectedSize
        this.resetColorOptions();
        // If selectedColor is still not set, set default selectedColor
        if (!this.selectedColor) {
            this.updateSelectedColor();
        }
        // Update selectedVariant based on selectedSize and selectedColor if not provided
        if (!this.selectedVariant) {
            this.updateSelectedVariant();
        }
        // Set the selected asset
        if (this.selectedVariant?.featuredAsset) {
            this.selectedAsset = {
                id: this.selectedVariant.featuredAsset.id,
                preview: this.selectedVariant.featuredAsset.preview,
            };
        }
        this.updateMSRP();
        // Check stock status
        this.inStock = this.selectedVariant ? this.selectedVariant.stockLevel !== 'OUT_OF_STOCK' : true;
        this.changeDetector.markForCheck();
    }

    viewCartFromNotification(closeFn: () => void) {
        this.stateService.setState('cartDrawerOpen', true);
        closeFn();
    }

    updateMSRP() {
        if (this.selectedVariant && this.selectedVariant.customFields?.MSRP && this.selectedVariant.customFields.MSRP !== 0) {
            this.selectedVariantMSRP = this.selectedVariant.customFields.MSRP;
            const price = this.selectedVariant.price;
            const msrp = this.selectedVariantMSRP;
            if (msrp && msrp > price) {
                this.discountPercentage = ((msrp - price) / msrp) * 100;
            } else {
                this.discountPercentage = null;
            }
        } else {
            this.selectedVariantMSRP = null;
            this.discountPercentage = null;
        }
    }

    onVariantChange(variant: Variant) {
        this.selectedVariant = variant;
        this.inStock = (variant.stockLevel !== 'OUT_OF_STOCK');
        this.updateMSRP();
        this.changeDetector.markForCheck();
        this.variantChanged.emit({ variant: this.selectedVariant });
    }

    initializeOptions() {
        if (this.product?.optionGroups) {
            this.resetSizeOptions();
            this.resetColorOptions();
        }
    }

    onSizeChange(size: string) {
        this.selectedSize = size;
        this.resetColorOptions();
        this.updateSelectedVariant();
    }

    onColorChange(color: ProductOption) {
        this.selectedColor = color.code;
        this.selectedColorName = color.name;
        this.updateSelectedVariant();
    }

    private updateSelectedVariant() {
        if (!this.product?.variants || this.product.variants.length === 0) {
            return;
        }
        const filters = [
            { code: this.selectedSize || '', groupId: this.sizeOptionGroupId, isEnabled: this.sizeOptions.length > 0 },
            { code: this.selectedColor || '', groupId: this.colorOptionGroupId, isEnabled: this.colorOptions.length > 0 },
        ].filter(filter => filter.isEnabled);
        const matchingVariants = this.filterVariantsByOptions(filters);
        const variant = matchingVariants[0] || this.product.variants[0];
        // Only update selectedVariant if it's different
        if (!this.selectedVariant || this.selectedVariant.id !== variant.id) {
            this.onVariantChange(variant);
        }
    }

    private resetSizeOptions() {
        const sizeGroup = this.getGroupByCode(ProductInfoComponent.DROPDOWN_LIST_PRODUCT_OPTION_CODE) as ProductOptionGroup;
        if (!sizeGroup) {
            this.clearSizeOptions();
            return;
        }
        this.sizeOptionGroupId = sizeGroup.id;
        this.sizeOptionsName = sizeGroup.name;
        this.sizeOptions = this.filterAndSortOptions(sizeGroup.options, this.sizeOptionGroupId);
        // Preserve selectedSize if valid
        if (!this.selectedSize || !this.sizeOptions.find(option => option.code === this.selectedSize)) {
            this.selectedSize = this.sizeOptions.length > 0 ? this.sizeOptions[0].code : null;
        }
    }

    private resetColorOptions() {
        const colorGroup = this.getGroupByCode(ProductInfoComponent.THUMB_SELECTION_PRODUCT_OPTION_CODE) as ProductOptionGroup;
        if (!colorGroup) {
            this.clearColorOptions();
            return;
        }
        this.colorOptionsName = colorGroup.name;
        this.colorOptionGroupId = colorGroup.id;
        const availableColors = this.getAvailableColorsWithSelectedSize(colorGroup);
        this.colorOptions = this.filterAndSortOptions(colorGroup.options, this.colorOptionGroupId)
            .filter(option => availableColors.has(option.code));
        // Preserve selectedColor if valid
        if (!this.selectedColor || !this.colorOptions.find(option => option.code === this.selectedColor)) {
            this.updateSelectedColor();
        }
    }

    private filterVariantsByOptions(filters: { code: string; groupId: string | undefined }[]): Variant[] {
        return this.product?.variants.filter(variant =>
            filters.every(filter =>
                variant.options.some(opt => opt.code === filter.code && opt.groupId === filter.groupId)
            )
        ) || [];
    }

    private isOptionAvailable(code: string, groupId: string): boolean {
        return this.filterVariantsByOptions([{ code, groupId }]).length > 0;
    }

    private filterAndSortOptions(options: ProductOption[], groupId: string): ProductOption[] {
        return options
            .filter((option: ProductOption) => this.isOptionAvailable(option.code, groupId))
            .sort((a: ProductOption, b: ProductOption) => (a.customFields?.Priority || 9999) - (b.customFields?.Priority || 9999));
    }

    private getAvailableVariantsWithSelectedSize(): Variant[] {
        return this.selectedSize
            ? this.filterVariantsByOptions([{ code: this.selectedSize, groupId: this.sizeOptionGroupId }])
            : this.product?.variants || [];
    }

    private getAvailableColorsWithSelectedSize(colorGroup: ProductOptionGroup): Set<string> {
        const availableColors = new Set<string>();
        const availableVariants = this.getAvailableVariantsWithSelectedSize();
        availableVariants.forEach((variant: Variant) => {
            variant.options.forEach((opt) => {
                if (opt.groupId === colorGroup?.id) {
                    availableColors.add(opt.code);
                }
            });
        });
        return availableColors;
    }

    private updateSelectedColor() {
        if (this.colorOptions.length === 0) {
            this.selectedColor = null;
            this.selectedColorName = null;
        } else if (!this.selectedColor || !this.colorOptions.find(option => option.code === this.selectedColor)) {
            this.selectedColor = this.colorOptions[0].code;
            this.selectedColorName = this.colorOptions[0].name;
        }
    }

    private clearSizeOptions() {
        this.sizeOptions = [];
        this.selectedSize = null;
    }

    private clearColorOptions() {
        this.colorOptions = [];
        this.selectedColor = null;
        this.selectedColorName = null;
    }

    getGroupByCode(code: string) {
        return this.product?.optionGroups.find(group => group.code.toLowerCase() === code) || undefined;
    }

    getVariantImage(colorCode: string): Asset | undefined {
        const filters = [
            { code: this.selectedSize || '', groupId: this.sizeOptionGroupId },
            { code: colorCode || '', groupId: this.colorOptionGroupId },
        ].filter(filter => filter.code);
        const variants = this.filterVariantsByOptions(filters);
        if(variants.length > 0) {
            return variants[0]?.featuredAsset as Asset || undefined;
        } else {
            return undefined;
        }
    }

    isFolded(sectionId: string): boolean {
        return this.foldedSections[sectionId];
    }

    toggleFold(sectionId: string) {
        this.foldedSections[sectionId] = !this.foldedSections[sectionId];
    }

    getSku(): string {
        return this.selectedVariant?.sku || '';
    }

    scrollToReviews() {
        if (isPlatformBrowser(this.platformId)) {
            const reviews = document.getElementById('product-reviews');
            if (reviews) {
                reviews.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
}
