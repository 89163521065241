import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { StateService } from '../../../core/providers/state/state.service';


@Component({
    selector: 'vsf-simple-search-bar',
    template: `
        <div class="flex items-center border border-gray-300 rounded-md shadow-sm p-2">
            <input type="text" [formControl]="searchTerm" (keydown.enter)="doSearch(searchTerm.value)"
                   class="flex-grow p-2 text-sm border-none focus:ring-0 focus:outline-none"
                   placeholder="Search..." aria-label="Search input">
            <button class="p-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    (click)="doSearch(searchTerm.value)">
                <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197M16.803 15.803A7.5 7.5 0 1112 4.5a7.5 7.5 0 014.803 11.303z" />
                </svg>
            </button>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleSearchBarComponent implements OnInit, OnDestroy {
    searchTerm = new UntypedFormControl('');
    baseUrl = '';
    private destroy$ = new Subject<void>();
    private subscription: Subscription;

    constructor(
        private router: Router,
        private stateService: StateService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.stateService.select(state => state.storePath).pipe(
            takeUntil(this.destroy$)
        ).subscribe(storePath => {
            this.baseUrl = storePath;
        });
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.destroy$.next();
        this.destroy$.complete();
    }

    doSearch(term: string) {
        if (term) {
            this.router.navigate([this.baseUrl, 'search'], {
                queryParams: { search: term },
                relativeTo: this.route,
                queryParamsHandling: 'merge',
            });
        }
    }
}
