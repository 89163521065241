import { gql } from 'apollo-angular';
import e from 'express';


export const ASSET_FRAGMENT = gql`
    fragment Asset on Asset {
        id
        width
        height
        type
        preview
        source
        mimeType
        focalPoint {
            x
            y
        }
        customFields {
            videoUrl
        }
    }
`;

export const BUTTON_FRAGMENT = gql`
    fragment Button on ButtonData {
        enabled
        text
        textColor
        backgroundColor
        actionType
        selectedVariantId
        navigateType
        targetProduct {
            id
            name
            slug
        }
        targetCollection {
            id
            name
            slug
        }
        targetPage
        targetPageSectionId
    }
`;

export const KEY_POINT_FRAGMENT = gql`
    fragment KeyPoint on KeyPointData {
        icon {
            id
            preview
            focalPoint {
                x
                y
            }
        }
        title
        value
    }
    ${ASSET_FRAGMENT}
`;

export const QA_FRAGMENT = gql`
    fragment QA on QaData {
        question
        answer
    }
`;

export const HERO_SECTION_FRAGMENT = gql`
    fragment HeroSection on HeroSectionData {
        layoutType
        title
        subtitle
        textColor
        titleFontSize
        shadowTransparency
        buttonPosition
        buttonLocationX
        buttonLocationY
        buttonPositionMobile
        buttonLocationXMobile
        buttonLocationYMobile
        button {
            ...Button
        }
        medias {
            ...Asset
        }
        mobileMedias {
            ...Asset
        }
    }
    ${BUTTON_FRAGMENT}
    ${ASSET_FRAGMENT}
`;

export const EXTERNAL_HTML_CONTENT_FRAGMENT = gql`
    fragment ExternalHtmlContent on ExternalHtmlContentData {
        title
        subtitle
        htmlContent
        medias {
            ...Asset
        }
        mobileMedias {
            ...Asset
        }
    }
    ${ASSET_FRAGMENT}
`;

export const MARKETING_BANNER_FRAGMENT = gql`
    fragment MarketingBanner on MarketingBannerData {
        type
        title
        text
        leftRightPartRatio
        shadowTransparency
        backgroundColor
        textColor
        textAlignment
        textWidth
        textIndentation
        buttonPosition
        buttonLocationX
        buttonLocationY
        buttonPositionMobile
        buttonLocationXMobile
        buttonLocationYMobile
        button {
            ...Button
        }
        medias {
            ...Asset
        }
        mobileMedias {
            ...Asset
        }
        mobileMediaPosition
        backgroundMedia {
            ...Asset
        }
        backgroundMobileMedia {
            ...Asset
        }
        keyPointsLayoutType
        keyPoints {
            ...KeyPoint
        }
        heroSection {
            ...HeroSection
        }
        externalHtmlContent {
            ...ExternalHtmlContent
        }
    }
    ${BUTTON_FRAGMENT}
    ${ASSET_FRAGMENT}
    ${KEY_POINT_FRAGMENT}
    ${HERO_SECTION_FRAGMENT}
    ${EXTERNAL_HTML_CONTENT_FRAGMENT}
`;

export const PRODUCT_REVIEW_FRAGMENT = gql`
    fragment ProductReview on ProductReview {
        id
        updatedAt
        summary
        body
        rating
        authorName
    }
`

export const LOGO_SECTION_FRAGMENT = gql`
    fragment LogoSection on LogoSectionData {
        pageTitle
        displayHeaderMenu
        displayFooterMenu
        normalLogo {
            id
            width
            height
            preview
            focalPoint {
                x
                y
            }
        }
        smallLogo {
            id
            width
            height
            preview
            focalPoint {
                x
                y
            }
        }
        nameLogo {
            id
            width
            height
            preview
            focalPoint {
                x
                y
            }
        }
        browserIcon {
            id
            source
        }
        displayName
    }
    ${ASSET_FRAGMENT}
`;


export const PRODUCT_CUSTOM_FIELDS_FRAGMENT = gql`
    fragment ProductCustomFields on ProductCustomFields {
        OGDescription
        OGImage {
            id
            preview
        }
        ProductSubtitle
        ProductFeatures
        ProductDisclaimer
        reviewRating
        reviewCount
        featuredReview {
            ...ProductReview
        }
        navigationMenu
        layoutOrder
    }
    ${ASSET_FRAGMENT}
    ${PRODUCT_REVIEW_FRAGMENT}
`;

export const VARIANT_CUSTOM_FIELDS_FRAGMENT = gql`
    fragment ProductVariantCustomFields on ProductVariantCustomFields {
        MSRP
    }
`;

export const CART_FRAGMENT = gql`
    fragment Cart on Order {
        id
        code
        state
        active
        createdAt
        updatedAt
        couponCodes
        orderPlacedAt
        customer {
            id
            firstName
            emailAddress
            customFields {
                nickname
            }
        }
        shippingAddress {
            fullName
            postalCode
        }
        lines {
            id
            featuredAsset {
                id
                preview
                focalPoint {
                    x
                    y
                }
            }
            unitPrice
            unitPriceWithTax
            quantity
            linePriceWithTax
            discountedLinePriceWithTax
            productVariant {
                id
                name
                sku
                price
                featuredAsset {
                    id
                    preview
                    focalPoint {
                        x
                        y
                    }
                }
                options {
                    name
                }
                product {
                    id
                    name
                    slug
                    channels {
                        id
                        code
                        seller {
                            id
                            name
                            customFields {
                                returnPolicy
                            }
                        }
                    }
                }
                customFields {
                    MSRP
                }
            }
            discounts {
                amount
                amountWithTax
                description
                adjustmentSource
                type
            }
        }
        totalQuantity
        subTotal
        subTotalWithTax
        total
        totalWithTax
        shipping
        shippingWithTax
        shippingLines {
            priceWithTax
            shippingMethod {
                id
                code
                name
                description
            }
        }
        discounts {
            amount
            amountWithTax
            description
            adjustmentSource
            type
        }
    }
`;

export const COUNTRY_FRAGMENT = gql`
    fragment Country on Country {
        id
        code
        name
        enabled
    }
`;

export const ORDER_ADDRESS_FRAGMENT = gql`
    fragment OrderAddress on OrderAddress {
        fullName
        company
        streetLine1
        streetLine2
        city
        province
        postalCode
        country
        phoneNumber
    }
`;

export const COLLECTION_FRAGMENT = gql`
    fragment Collection on Collection {
        id
        name
        slug
        description
        customFields {
            itemOrderData
        }
        parent {
            id
            name
            slug
        }
        featuredAsset {
            ...Asset
        }
        storeSite {
            id
            name
            identifier
            sellerChannelId
            colorConfig
        }
        heroSection {
            ...HeroSection
        }
    }
    ${ASSET_FRAGMENT}
    ${HERO_SECTION_FRAGMENT}
`;

export const COLLECTION_HERO_FRAGMENT = gql`
    fragment CollectionHero on Collection {
        id
        name
        slug
        heroSection {
            ...HeroSection
        }
    }
    ${HERO_SECTION_FRAGMENT}
`;

export const FACET_VALUE_FRAGMENT = gql`
    fragment FacetValue on FacetValue {
        id
        name
        code
        customFields {
            priority
        }
        facet {
            id
            name
            code
        }
    }
    ${ASSET_FRAGMENT}
`;

export const FACET_FRAGMENT = gql`
    fragment Facet on Facet {
        id
        name
        code
        values {
            ...FacetValue
        }
    }
    ${FACET_VALUE_FRAGMENT}
`;

export const ABOUT_PAGE_FRAGMENT = gql`
    fragment AboutPage on AboutPageData {
        posterAsset {
            id
            preview
            focalPoint {
                x
                y
            }
        }
        title
        text
        citation
        featuredAsset {
            id
            preview
            focalPoint {
                x
                y
            }
        }
    }
`;

export const FAQ_PAGE_FRAGMENT = gql`
    fragment FaqPage on FaqPageData {
        posterAsset {
            id
            preview
            focalPoint {
                x
                y
            }
        }
        title
        qaList {
            ...QA
        }
    }
    ${QA_FRAGMENT}
`;

export const CONTACT_PAGE_FRAGMENT = gql`
    fragment ContactPage on ContactPageData {
        posterAsset {
            id
            preview
            focalPoint {
                x
                y
            }
        }
        title
        contactFormType
        featuredAsset {
            id
            preview
            focalPoint {
                x
                y
            }
        }
    }
`;

export const EMAIL_SUBSCRIPTION_FRAGMENT = gql`
    fragment EmailSubscriptionData on EmailSubscriptionData {
        type
        backgroundColor
        title
        subtitle
        backgroundImage {
            id
            preview
            focalPoint {
                x
                y
            }
        }
        shadowTransparency
        omnisendFormId
    }
    ${ASSET_FRAGMENT}
`;

export const COLLECTION_GROUP_FRAGMENT = gql`
    fragment CollectionGroup on CollectionGroupData {
        groupName
        title
        subtitle
        description
        backgroundAsset {
            ...Asset
        }
        featuredAssets {
            ...Asset
        }
        collections {
            id
            name
            slug
            description
            featuredAsset {
                id
                preview
                focalPoint {
                    x
                    y
                }
            }

        }
    }
    ${ASSET_FRAGMENT}
`;

export const FEATURED_COLLECTION_FRAGMENT = gql`
    fragment FeaturedCollection on FeaturedCollectionData {
        title
        subtitle
        collection {
            id
            name
            slug
            customFields {
                itemOrderData
            }
        }
        displayType
        productCardType
        cardsPerRow
        displayFields
    }
`;

export const STORESITE_MAIN_FRAGMENT = gql`
    fragment StoreSiteMain on StoreSite {
        id
        name
        identifier
        seller {
            customFields {
                email
            }
        }
        sellerChannelId
        sellerChannel {
            id
            code
            token
            currencyCode
            defaultLanguageCode
        }
        headerMessage
        colorConfig
        logoSectionData {
            ...LogoSection
        }
        snsInfoSection

        navigationMenu
        aboutPageData {
            ...AboutPage
        }
        faqPageData {
            ...FaqPage
        }
        contactPageData {
            ...ContactPage
        }
        policyContentList
        footerNavigationColumns

        layoutOrder
        heroSectionData {
            ...HeroSection
        }
        featuredProductReviewListData {
            ...ProductReview
        }
        emailSubscriptionData {
            ...EmailSubscriptionData
        }

        marketingBanners {
            ...MarketingBanner
        }
        featuredCollectionDataList {
            ...FeaturedCollection
        }
        collectionGroupDataList {
            ...CollectionGroup
        }
        collectionForSeriesListData {
            id
            name
            slug
            featuredAsset {
                id
                preview
                focalPoint {
                    x
                    y
                }
            }
            heroSection {
                ...HeroSection
            }
        }
    }
    ${LOGO_SECTION_FRAGMENT}
    ${ABOUT_PAGE_FRAGMENT}
    ${FAQ_PAGE_FRAGMENT}
    ${CONTACT_PAGE_FRAGMENT}
    ${HERO_SECTION_FRAGMENT}
    ${PRODUCT_REVIEW_FRAGMENT}
    ${EMAIL_SUBSCRIPTION_FRAGMENT}
    ${FEATURED_COLLECTION_FRAGMENT}
    ${MARKETING_BANNER_FRAGMENT}
    ${COLLECTION_GROUP_FRAGMENT}
`;

export const FULFILLMENT_FRAGMENT = gql`
    fragment Fulfillment on Fulfillment {
        id
        state
        createdAt
        updatedAt
        method
        lines {
            orderLineId
            quantity
        }
        trackingCode
    }
`;

export const ADDRESS_FRAGMENT = gql`
    fragment Address on Address {
        id
        fullName
        company
        streetLine1
        streetLine2
        city
        province
        postalCode
        country {
            id
            code
            name
        }
        phoneNumber
        defaultShippingAddress
        defaultBillingAddress
    }
`;

export const ERROR_RESULT_FRAGMENT = gql`
    fragment ErrorResult on ErrorResult {
        errorCode
        message
    }
`;

export const PAYMENT_INTENT_FRAGMENT = gql`
    fragment PaymentIntentResponse on PaymentIntentResponse {
        paymentIntentId
        clientSecret
        showRecaptcha
        stripeAccount
    }
`;
