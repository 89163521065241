<div class="product-carousel flex flex-col">
  <div *ngIf="headerTitle" [style.z-index]="4" class="flex mt-2 mb-2 mr-2 justify-between text-gray-500 w-100">
    <p class="fa-vertical-bar-medium text-center text-2xl text-[#2E3742]">{{headerTitle}}</p>
    <div>
      <div *ngIf="displayPagination">
        <button class="fa-chevron-left-medium mr-2" (click)="swipeToPrev()">< </button>
        <span>{{ selectedIndex }}</span> / <span>{{ totalSlides }}</span>
        <button class="fa-chevron-right-medium ml-2" (click)="swipeToNext()">> </button>
      </div>
    </div>
  </div>

  <div class="w-100 ml-1 mr-1 min-h-[300px] overflow-hidden">
    <ng-container *ngIf="isBrowser; else fallbackList">
      <swiper-container
        #swiper
        [slidesPerView]="1"
        space-between="10"
        rewind="true"
        class="flex object-cover custom-pagination"
      >
        <swiper-slide *ngFor="let group of chunkedItems; let i = index">
          <div class="product-grid flex flex-col">
            <div *ngFor="let item of group; trackBy: trackByProductId">
              <ng-container *ngIf="productCardType === 'left-asset-small' || productCardType === 'left-asset-large' || productCardType === 'right-asset-small' || productCardType === 'right-asset-large'">
                <vsf-product-card-v3-horizontal
                  [displayType]="displayType"
                  [item]="item"
                  [displaySubtitle]="displaySubtitle"
                  [displayPrice]="displayPrice"
                  [displayBuyButton]="displayBuyButton"
                  [displayAlternativeImage]="displayAlternativeImage"
                  [displayViewers]="displayViewers"
                  [displayLikes]="displayLikes"
                  [displayRanking]="displayRanking"
                  [displaySold]="displaySold"
                  [displayPrice]="displayPrice"
                  [imgType]="'rectangle'"
                  [imgSize]="imgSize"
                  [type]="(productCardType === 'right-asset-small' || productCardType === 'right-asset-large') ? 'right-asset' : 'left-asset'"
                  [size]="(productCardType === 'left-asset-large' || productCardType === 'right-asset-large') ? 'large' : 'small'"
                  class="card product-card">
                </vsf-product-card-v3-horizontal>
              </ng-container>
              <ng-container *ngIf="productCardType === 'top-asset-large' || productCardType === 'full-asset'">
                <vsf-product-card-v3-vertical
                  [displayType]="displayType"
                  [item]="item"
                  [displaySubtitle]="displaySubtitle"
                  [displayPrice]="displayPrice"
                  [displayBuyButton]="displayBuyButton"
                  [displayAlternativeImage]="displayAlternativeImage"
                  [displayViewers]="displayViewers"
                  [displayLikes]="displayLikes"
                  [displayRanking]="displayRanking"
                  [displaySold]="displaySold"
                  [displayPrice]="displayPrice"
                  [imgSize]="imgSize"
                  [size]="(productCardType === 'top-asset-large') ? 'large' : 'full'"
                  class="card product-card">
                </vsf-product-card-v3-vertical>
              </ng-container>
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
    </ng-container>

    <!-- Fallback template for SSR -->
    <ng-template #fallbackList>
      <div class="grid grid-cols-1 gap-4 justify-center items-center mx-auto">
        <div
          *ngFor="let item of displayedItems.slice(0,4); trackBy: trackByProductId"
          class="flex-shrink-0"
        >
        <ng-container *ngIf="productCardType === 'left-asset-small' || productCardType === 'left-asset-large' || productCardType === 'right-asset-small' || productCardType === 'right-asset-large'">
          <vsf-product-card-v3-horizontal
            [displayType]="displayType"
            [item]="item"
            [displaySubtitle]="displaySubtitle"
            [displayPrice]="displayPrice"
            [displayBuyButton]="displayBuyButton"
            [displayAlternativeImage]="displayAlternativeImage"
            [displayViewers]="displayViewers"
            [displayLikes]="displayLikes"
            [displayRanking]="displayRanking"
            [displaySold]="displaySold"
            [displayPrice]="displayPrice"
            [imgType]="'rectangle'"
            [imgSize]="imgSize"
            [type]="(productCardType === 'right-asset-small' || productCardType === 'right-asset-large') ? 'right-asset' : 'left-asset'"
            [size]="(productCardType === 'left-asset-large' || productCardType === 'right-asset-large') ? 'large' : 'small'"
            class="card product-card">
          </vsf-product-card-v3-horizontal>
        </ng-container>
        <ng-container *ngIf="productCardType === 'top-asset-small' || productCardType === 'top-asset-large' || productCardType === 'full-asset'">
          <vsf-product-card-v3-vertical
            [displayType]="displayType"
            [item]="item"
            [displaySubtitle]="displaySubtitle"
            [displayPrice]="displayPrice"
            [displayBuyButton]="displayBuyButton"
            [displayAlternativeImage]="displayAlternativeImage"
            [displayViewers]="displayViewers"
            [displayLikes]="displayLikes"
            [displayRanking]="displayRanking"
            [displaySold]="displaySold"
            [displayPrice]="displayPrice"
            [imgSize]="imgSize"
            [size]="(productCardType === 'top-asset-small') ? 'small' :
                    ((productCardType === 'top-asset-large') ? 'large' : 'full')"
            class="card product-card">
          </vsf-product-card-v3-vertical>
        </ng-container>
        </div>
      </div>
    </ng-template>
  </div>
</div>
