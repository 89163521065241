import { Route , RouterModule} from '@angular/router';
import { NgModule } from '@angular/core';
import { OrderStatusFormComponent } from './core/components/order-status-form/order-status-form.component';
import { ReturnRequestRmaFormComponent } from './shared/components/return-request-rma-form/return-request-rma-form.component';
import { CartPageComponent } from './core/components/cart-page/cart-page.component';
import { StoreShoppingPageComponent } from './store/store-shopping-page/store-shopping-page.component';
import { AboutPageComponent } from './core/components/about-page/about-page.component';
import { FaqPageComponent } from './core/components/faq-page/faq-page.component';
import { ContactPageComponent } from './core/components/contact-page/contact-page.component';
import { StorePolicyPageComponent } from './store/store-policy-page/store-policy-page.component';
import { SearchEntryComponent } from './entry/components/search-entry/search-entry.component';
import { RootEntryComponent } from './entry/components/root-entry/root-entry.component';
import { ProductShowcaseComponent } from './product/components/product-showcase/product-showcase.component';
import { CollectionMainPageComponent } from './collection/collection-main-page/collection-main-page.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { ProductResolver } from './core/providers/product-resolver';
import { CollectionResolver } from './core/providers/collection-resolver';
import { PolicyResolver } from './core/providers/policy-resolver';
import { NGXLogger } from 'ngx-logger';

export const routes: Route[] = [
    {
        path: '',
        component: RootEntryComponent,
        pathMatch: 'full',
    },
    {
        path: 'return-form',
        component: ReturnRequestRmaFormComponent,
    },
    {
        path: 'gp/:slug',
        component: CollectionMainPageComponent,
        resolve: { collection: CollectionResolver },
    },
    {
        path: 'search',
        component: SearchEntryComponent,
    },
    {
        path: 'dp/:slug',
        component: ProductShowcaseComponent,
        resolve: { product: ProductResolver },
    },
    {
        path: 'product/:slug',
        component: ProductShowcaseComponent,
    },
    {
        path: 'account',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
    },
    {
        path: 'checkout',
        loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule),
    },
    {
        path: 'shopping',
        component: StoreShoppingPageComponent,
    },
    {
        path: 'about',
        component: AboutPageComponent,
    },
    {
        path: 'faq',
        component: FaqPageComponent,
    },
    {
        path: 'contact',
        component: ContactPageComponent,
    },
    {
        path: 'store-policy/:policy',
        component: StorePolicyPageComponent,
        resolve: { policyData: PolicyResolver }
    },
    {
        path: 'cart',
        component: CartPageComponent,
    },
    {
        path: 'orderstatus',
        component: OrderStatusFormComponent,
    },
    {
        path: 'policy',
        loadChildren: () => import('./policy/policy.module').then(m => m.PolicyModule),
    },
    {
        path: 'not-found',
        component: NotFoundComponent,
    },
    {
        path: '**',
        component: NotFoundComponent,
    }
];


@NgModule({
    imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' ,  enableTracing: false})],
    exports: [RouterModule]
  })
  export class AppRoutingModule {
    constructor(private logger: NGXLogger) {
        this.logger.debug('AppRoutingModule loaded');
    }
}
