<div *ngIf="product as productDetail" class="max-w-6xl mx-auto">
    <div class="mb:pb-24">
        <vsf-section-nav-menu
            [isMobile]="isMobile"
            [showTopNav]="showTopNav"
            [isHeaderFloating]="isHeaderFloating"
            [withHeaderMessage]="withHeaderMessage"
            [navigationMenu]="navigationMenu"
            (navItemClicked)="scrollToSection($event)"
        ></vsf-section-nav-menu>
        <ng-container *ngIf="layoutOrder.length > 0;else normalLayout">
            <div *ngFor="let layout of layoutOrder" [ngStyle]="layout.styles" [id]="layout.sectionName">
                <ng-container *ngIf="layout.layoutComponentType === 'product-info'">
                    <div *ngIf="selectedVariant" class="pb-[60px]">
                        <vsf-product-info
                            [product]="productDetail"
                            [selectedVariant]="selectedVariant"
                            [colorConfig]="colorConfig"
                            [isMobile]="isMobile"
                            (variantChanged)="onVariantChange($event)"
                            (shopNowBannerVisibilityChange)="onShopNowBannerVisibilityChange($event)">
                        </vsf-product-info>
                    </div>
                </ng-container>
                <ng-container *ngIf="layout.layoutComponentType === 'hero-section'">
                    <div *ngIf="heroSection && heroSection?.medias?.length > 0" class="hero-banner" style="min-height: 180px;">
                        <vsf-hero-section
                            [heroSection]="heroSection"
                            [isMobile]="isMobile">
                        </vsf-hero-section>
                    </div>
                </ng-container>
                <ng-container *ngIf="layout.layoutComponentType === 'customer-review-section'">
                    <div *ngIf="productDetail.customFields?.featuredReview">
                        <div>
                            <vsf-porduct-review-section
                                [productId]="productDetail.id"
                                [reviewRating]="productDetail.customFields?.reviewRating"
                                [reviewCount]="productDetail.customFields?.reviewCount"
                                [featuredReview]="productDetail.customFields?.featuredReview"
                                [isMobile]="isMobile"
                        ></vsf-porduct-review-section>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="layout.layoutComponentType === 'unique-selling-point'">
                    <div *ngIf="marketingBanners?.length > layout.seqNumberOfList">
                        <div>
                            <vsf-marketing-banner
                                [marketingBanner]="marketingBanners[layout.seqNumberOfList]"
                                [isMobile]="isMobile">
                            </vsf-marketing-banner>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="layout.layoutComponentType === 'featured-product-collection'">
                    <div *ngIf="featuredCollectionList?.length > layout.seqNumberOfList" >
                        <div *ngIf="!isMobile">
                            <div>
                                <vsf-product-carousel-v2
                                    [title]="featuredCollectionList[layout.seqNumberOfList]?.title"
                                    [subtitile]="featuredCollectionList[layout.seqNumberOfList]?.subtitle"
                                    [displayType] = "featuredCollectionList[layout.seqNumberOfList]?.displayType"
                                    [productCardType]="featuredCollectionList[layout.seqNumberOfList]?.productCardType"
                                    [displayFields]="featuredCollectionList[layout.seqNumberOfList]?.displayFields"
                                    [collectionSlug]="featuredCollectionList[layout.seqNumberOfList]?.collection.slug"
                                    [collectionName]="featuredCollectionList[layout.seqNumberOfList]?.collection.name"
                                    [collectionItemOrder]="featuredCollectionList[layout.seqNumberOfList]?.collection.customFields?.itemOrderData"
                                    [channelId]="storeSite?.sellerChannelId"
                                    [itemsPerSlide]="featuredCollectionList[layout.seqNumberOfList]?.cardsPerRow"
                                    [displayAlternativeImage]="true"
                                    [imgSize]="'md'">
                                </vsf-product-carousel-v2>
                            </div>
                        </div>
                        <div *ngIf="isMobile">
                            <div class="mt-8 text-center">
                                <p class="text-[20px] font-semibold mb-2">
                                    {{ featuredCollectionList[layout.seqNumberOfList]?.title }}
                                </p>
                                <div class="w-10 h-[3px] bg-black my-2 mx-auto"></div>
                            </div>
                            <ng-container *ngIf="featuredCollectionList[layout.seqNumberOfList]?.productCardType === 'regular'
                                                || featuredCollectionList[layout.seqNumberOfList]?.productCardType === 'top-asset-small';
                                                else newSwiper ">
                                <vsf-product-swiper
                                    [displayType] = "featuredCollectionList[layout.seqNumberOfList]?.displayType"
                                    [productCardType]="featuredCollectionList[layout.seqNumberOfList]?.productCardType"
                                    [displayFields]="featuredCollectionList[layout.seqNumberOfList]?.displayFields"
                                    [collectionSlug]="featuredCollectionList[layout.seqNumberOfList]?.collection?.slug"
                                    [collectionItemOrder]="featuredCollectionList[layout.seqNumberOfList]?.collection?.customFields?.itemOrderData"
                                    [channelId]="storeSite?.sellerChannelId"
                                    [layoutMode]="'vertical'"
                                    [itemsPerRow]=2
                                    [itemsPerSlide]=8
                                    [displaySubtitle]="false"
                                    [displayPagination]="false"
                                    [imgSize]="'sm-v'">
                                </vsf-product-swiper>
                            </ng-container>
                            <ng-template #newSwiper>
                                <vsf-product-swiper-v2
                                    [displayType] = "featuredCollectionList[layout.seqNumberOfList]?.displayType"
                                    [productCardType]="featuredCollectionList[layout.seqNumberOfList]?.productCardType"
                                    [displayFields]="featuredCollectionList[layout.seqNumberOfList]?.displayFields"
                                    [collectionSlug]="featuredCollectionList[layout.seqNumberOfList]?.collection?.slug"
                                    [collectionItemOrder]="featuredCollectionList[layout.seqNumberOfList]?.collection?.customFields?.itemOrderData"
                                    [channelId]="storeInfo.sellerChannelId"
                                    [layoutMode]="'vertical'"
                                    [itemsPerSlide]=4
                                    [displaySubtitle]="false"
                                    [displayPagination]="false"
                                    [imgSize]="'sm-v'">
                                </vsf-product-swiper-v2>
                            </ng-template>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="layout.layoutComponentType === 'collection-list-for-shopby'">
                    <div *ngIf="collectionListForShopByGroupList?.length > layout.seqNumberOfList">
                        <div *ngIf="isMobile"  class="mb-4">
                            <vsf-collection-group-swiper
                                [collectionGroup]="collectionList"
                                [displayPagination]=true>
                            </vsf-collection-group-swiper>
                        </div>
                        <div *ngIf="(isMobile===false)" class="facet-group mb-12">
                            <vsf-collection-group-carousel
                                [collectionGroup]="collectionList">
                            </vsf-collection-group-carousel>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-template #normalLayout>
            <div *ngIf="heroSection && heroSection?.medias?.length > 0; else deprecatedHeroSection">
                <vsf-hero-section
                    [heroSection]="heroSection"
                    [isMobile]="isMobile">
                </vsf-hero-section>
            </div>
            <ng-template #deprecatedHeroSection>
                <div>
                    <vsf-product-hero-section
                        [product]="productDetail">
                    </vsf-product-hero-section>
                </div>
            </ng-template>
            <div *ngIf="productDetail.productKeySellingPoints && productDetail.productKeySellingPoints.length > 0">
                <vsf-key-selling-points-banner
                    [keySellingPoints]="productDetail.productKeySellingPoints"
                    [layout]="'horizontal'"
                    >
                </vsf-key-selling-points-banner>
            </div>
            <div *ngIf="selectedVariant" class="pb-[60px]">
                <vsf-product-info
                    [product]="productDetail"
                    [selectedVariant]="selectedVariant"
                    [colorConfig]="colorConfig"
                    [isMobile]="isMobile"
                    (variantChanged)="onVariantChange($event)"
                    (shopNowBannerVisibilityChange)="onShopNowBannerVisibilityChange($event)">
                </vsf-product-info>
            </div>
            <hr>
            <div id="product-reviews" *ngIf="productDetail.customFields?.featuredReview">
                <vsf-porduct-review-section
                    [productId]="productDetail.id"
                    [reviewRating]="productDetail.customFields?.reviewRating"
                    [reviewCount]="productDetail.customFields?.reviewCount"
                    [featuredReview]="productDetail.customFields?.featuredReview"
                    [isMobile]="isMobile"
                ></vsf-porduct-review-section>
            </div>
            <div>
                <div *ngFor="let markingBanner of productDetail.marketingBanners" class="mt-4 mx-4">
                    <vsf-marketing-banner  style="min-height: 400px;"
                        [marketingBanner]="markingBanner"
                        [isMobile]="isMobile">
                    </vsf-marketing-banner>
                </div>
            </div>
            <div *ngIf="crossSellingFeaturedCollection">
                <div class="corss-selling-product-collection-title mt-8">
                    <p>You May Also Like</p>
                    <div class="corss-selling-title-underline"></div>
                </div>
                <div *ngIf="(isMobile===false)">
                    <vsf-product-carousel-v2
                        [title]="crossSellingFeaturedCollection.title"
                        [subtitile]="crossSellingFeaturedCollection.subtitle"
                        [displayType] = "crossSellingFeaturedCollection.displayType"
                        [productCardType]="crossSellingFeaturedCollection.productCardType"
                        [displayFields]="crossSellingFeaturedCollection.displayFields"
                        [collectionItemOrder]="crossSellingFeaturedCollection?.collection?.customFields?.itemOrderData"
                        [collectionSlug]="crossSellingFeaturedCollection?.collection?.slug"
                        [collectionName]="crossSellingFeaturedCollection?.collection?.name"
                        [channelId]="storeSite?.sellerChannelId"
                        [itemsPerSlide]="crossSellingFeaturedCollection.cardsPerRow"
                        [displayCarouselTitle]="false"
                        [displayAlternativeImage]="true"
                        [imgSize]="'md'">
                    </vsf-product-carousel-v2>
                </div>
                <div *ngIf="isMobile" class="mt-4 mb-8">
                    <ng-container *ngIf="crossSellingFeaturedCollection.productCardType === 'regular'
                                        || crossSellingFeaturedCollection.productCardType === 'top-asset-small';
                                        else newSwiper ">
                        <vsf-product-swiper
                            [displayType] = "crossSellingFeaturedCollection.displayType"
                            [productCardType]="crossSellingFeaturedCollection.productCardType"
                            [displayFields]="crossSellingFeaturedCollection.displayFields"
                            [collectionSlug]="crossSellingFeaturedCollection.collection?.slug"
                            [collectionItemOrder]="crossSellingFeaturedCollection.collection?.customFields?.itemOrderData"
                            [channelId]="storeSite?.sellerChannelId"
                            [layoutMode]="'vertical'"
                            [itemsPerRow]=2
                            [itemsPerSlide]=8
                            [displaySubtitle]="false"
                            [displayPagination]="false"
                            [imgSize]="'sm-v'">
                        </vsf-product-swiper>
                    </ng-container>
                    <ng-template #newSwiper>
                        <vsf-product-swiper-v2
                            [displayType] = "crossSellingFeaturedCollection.displayType"
                            [productCardType]="crossSellingFeaturedCollection.productCardType"
                            [displayFields]="crossSellingFeaturedCollection.displayFields"
                            [collectionSlug]="crossSellingFeaturedCollection.collection?.slug"
                            [collectionItemOrder]="crossSellingFeaturedCollection.collection?.customFields?.itemOrderData"
                            [channelId]="storeSite?.sellerChannelId"
                            [layoutMode]="'vertical'"
                            [itemsPerSlide]=4
                            [displaySubtitle]="false"
                            [displayPagination]="false"
                            [imgSize]="'sm-v'">
                        </vsf-product-swiper-v2>
                    </ng-template>
                </div>
            </div>
            <div *ngIf="collectionListForShopByGroupList?.length > 0">
                <div *ngFor="let collectionList of collectionListForShopByGroupList">
                    <div *ngIf="isMobile"  class="mb-4">
                        <vsf-collection-group-swiper
                            [collectionGroup]="collectionList"
                            [displayPagination]=true>
                    </vsf-collection-group-swiper>
                    </div>
                    <div *ngIf="(isMobile===false)" class="facet-group mb-12">
                        <vsf-collection-group-carousel
                            [collectionGroup]="collectionList"
                            >
                        </vsf-collection-group-carousel>
                    </div>
                </div>
            </div>
        </ng-template>
        <div *ngIf="productDetail.customFields.ProductDisclaimer && isMobile">
            <div class="border rounded shadow p-4">
                <span class="disclaimer-html" [innerHtml]="productDetail.customFields.ProductDisclaimer | safeHtml"></span>
            </div>
        </div>
        <hr>
    </div>
    <div *ngIf="isMobile" [style.z-index]="9" class="fixed bottom-0 left-0 right-0 bg-white shadow-lg">
        <vsf-product-shop-now-banner
            [product]="productDetail"
            [selectedVariant]="selectedVariant"
            [isMobile]="isMobile"
            [inStock]="inStock"
            [buttonBackgroundColor]="colorConfig?.secondaryColor"
            >
        </vsf-product-shop-now-banner>
    </div>
    <div *ngIf=" (isMobile === false) && showFixedBanner"
        [ngStyle]="{'top.px': shopNowBannerTopOffset }"
        class="fixed-banner fixed left-0 right-0 bg-white shadow-lg">
        <vsf-desktop-shop-now-banner
            [product]="productDetail"
            [selectedVariant]="selectedVariant"
            [isMobile]="isMobile"
            [inStock]="inStock"
            [buttonBackgroundColor]="colorConfig?.secondaryColor"
            >
        </vsf-desktop-shop-now-banner>
    </div>
</div>
