import { ChangeDetectorRef, Component, HostListener, Input, OnChanges, OnInit, SimpleChanges, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { GetProductAllDetailQuery } from '../../../common/generated-types';
import { CartService } from '../../../core/providers/cart/cart.service';
import { AddToCartResult } from '../../../common/enums';

type Variant = NonNullable<GetProductAllDetailQuery['product']>['variants'][number];

@Component({
    selector: 'vsf-desktop-shop-now-banner',
    templateUrl: './desktop-shop-now-banner.component.html',
    styleUrls: ['./desktop-shop-now-banner.component.scss'],
})
export class DesktopShopNowBannerComponent implements OnInit, OnChanges {
    @Input() product: GetProductAllDetailQuery['product'];
    @Input() selectedVariant: Variant;
    @Input() inStock = true;
    @Input() isMobile: boolean = false;
    @Input() buttonBackgroundColor: null | string;
    buttonBgColor: string;
    buttonFtColor: string;
    inFlight = false;
    selectedVariantMSRP: number | null = null;
    discountPercentage: number | null = null;
    optionsWithGroupNames: { groupName: string, optionName: string }[] = [];
    private subscription = new Subscription();

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private changeDetector: ChangeDetectorRef,
        private cartService: CartService,
        private logger: NGXLogger) { }

    ngOnInit() {
        this.buttonBgColor = this.buttonBackgroundColor || '#29319C';
        this.buttonFtColor = this.buttonBackgroundColor ? '#000000' : '#FFFFFF';
        this.updateMSRP();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedVariant || changes.product) {
            this.processOptions();
        }
        if (changes['selectedVariant']) {
          this.updateMSRP();
        }
    }

    private processOptions() {
        if (this.selectedVariant && this.product) {
          const optionGroups = this.product.optionGroups;
          this.optionsWithGroupNames = this.selectedVariant.options.map(option => {
            const group = optionGroups.find(group => group.id === option.groupId);
            return {
              groupName: group ? group.name : 'Unknown',
              optionName: option.name,
            };
          });
        } else {
          this.optionsWithGroupNames = [];
        }
    }

    updateMSRP() {
        if (this.selectedVariant && this.selectedVariant.customFields?.MSRP && this.selectedVariant.customFields.MSRP !== 0) {
            this.selectedVariantMSRP = this.selectedVariant.customFields.MSRP;
            const price = this.selectedVariant.price;
            const msrp = this.selectedVariantMSRP;
            if (msrp && msrp > price) {
                this.discountPercentage = ((msrp - price) / msrp) * 100;
            } else {
                this.discountPercentage = null;
            }
        } else {
            this.selectedVariantMSRP = null;
            this.discountPercentage = null;
        }
    }

    addToCartAndOpenDrawer() {
        this.inFlight = true;
        this.changeDetector.markForCheck();
        this.subscription.add(
          this.cartService.addToCartAndOpenDrawer(this.selectedVariant?.id).subscribe({
            next: (result: AddToCartResult) => {
                this.inFlight = false;
                if (result === AddToCartResult.OUT_OF_STOCK) {
                  this.inStock = false;
                }
                this.changeDetector.markForCheck();
            },
            error: err => {
              this.inFlight = false;
              this.logger.error('Error in addToCartAndOpenDrawer', err);
              this.changeDetector.markForCheck();
            },
          }),
        );
    }
}
