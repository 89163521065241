import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { notNullOrUndefined } from '../../common/utils/not-null-or-undefined';
import { GetCollectionQuery, GetCollectionQueryVariables, StoreSite } from '../../common/generated-types';
import { DataService } from '../../core/providers/data/data.service';
import { NGXLogger } from 'ngx-logger';
import { GET_COLLECTION } from '../../common/graphql/documents.graphql';
import { safeJSONParse } from '../../common/utils/safe-json-parser';
import { CollectionGroupData, ColorConfig } from '../../common/interfaces';
import { StateService } from '../../core/providers/state/state.service';
import { CanonicalService } from '../../core/providers/canonical/canonical.service';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';

@Component({
  selector: 'vsf-collection-main-page',
  templateUrl: './collection-main-page.component.html',
  styleUrls: ['./collection-main-page.component.scss'],
})
export class CollectionMainPageComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  storeInfo: StoreSite;
  identifier: string;
  baseUrl = '';
  sellerChannelId: string;
  heroSection: any | null;
  colorConfig: ColorConfig | null;
  isMobile: boolean | null = null;
  shopHost: string;
  collection: GetCollectionQuery['collection'];

  collectionListForShopByGroupList: CollectionGroupData[];

  constructor(@Inject(PLATFORM_ID) private platformId: object,
              private route: ActivatedRoute,
              private canonicalService: CanonicalService,
              private router: Router,
              private viewportScroller: ViewportScroller,
              private changeDetectorRef: ChangeDetectorRef,
              private stateService: StateService,
              private logger: NGXLogger) {}

  async ngOnInit() {
    const isMobile$ = this.stateService.select(state => state.isMobile);
    const storeInfo$ = this.stateService.select(state => state.storeInfo);
    const baseUrl$ = this.stateService.select(state => state.storePath);
    const shopHost$ = this.stateService.select(state => state.shopHost);
    await combineLatest([isMobile$, storeInfo$, baseUrl$, shopHost$])
    .pipe(take(1)).subscribe(([isMobile, storeInfo, baseUrl, shopHost]) => {
        this.isMobile = isMobile;
        this.storeInfo = storeInfo;
        this.baseUrl = baseUrl;
        this.shopHost = shopHost;
        this.sellerChannelId = this.storeInfo?.sellerChannelId || '';
        this.colorConfig = safeJSONParse<ColorConfig>(this.storeInfo?.colorConfig, this.logger);
        this.collectionListForShopByGroupList = this.storeInfo?.collectionGroupDataList?.filter(notNullOrUndefined) as CollectionGroupData[];
    });

    const collectionSlug$ = this.route.paramMap.pipe(
      map(paramMap => paramMap.get('slug')),
      takeUntil(this.destroy$),
      filter(notNullOrUndefined),
    );
    collectionSlug$.subscribe(() => {
      this.heroSection = null;
      this.collection = this.route.snapshot.data['collection'];
      this.heroSection = this.collection?.heroSection;
      const canonicalURL = `${this.baseUrl}/gp/${this.collection?.slug}`;
      this.canonicalService.setCanonicalURL(`${this.shopHost}${canonicalURL}`);
      if (isPlatformBrowser(this.platformId)) {
        this.viewportScroller.scrollToPosition([0, 0]);
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.canonicalService.removeCanonicalURL();
    this.destroy$.next();
    this.destroy$.complete();
  }
}