import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnChanges, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CartService } from '../../providers/cart/cart.service';
import { Asset } from '../../../common/generated-types';
import { ButtonData, HeroSectionData, KeySellingPointData, MarketingBannerData, ExternalHtmlContentData } from '../../../common/interfaces';
import { SwiperContainer } from 'swiper/element';
import Swiper from 'swiper';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { StateService } from '../../providers/state/state.service';
import { take } from 'rxjs/operators';
import { ButtonActionType, ButtonNavigationType } from '../../../common/enums';
import { isStoreRelatedPage, normalizeUrlForRouting } from '../../../common/utils/url-handlers';

@Component({
    selector: 'vsf-marketing-banner',
    templateUrl: './marketing-banner.component.html',
    styleUrls: ['./marketing-banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketingBannerComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() marketingBanner: MarketingBannerData;
    @Input() isMobile: boolean | null;

    @ViewChild('leftMediaDesktopSwiper') leftMediaDesktopSwiper: ElementRef<SwiperContainer>;
    @ViewChild('leftMediaMobileSwiper') leftMediaMobileSwiper: ElementRef<SwiperContainer>;
    @ViewChild('rightMediaDesktopSwiper') rightMediaDesktopSwiper: ElementRef<SwiperContainer>;
    @ViewChild('rightMediaMobileSwiper') rightMediaMobileSwiper: ElementRef<SwiperContainer>;
    @ViewChild('belowMediaMultipleSwiper') belowMediaMultipleSwiper: ElementRef<SwiperContainer>;
    @ViewChild('belowMediaSingleSwiper') belowMediaSingleSwiper: ElementRef<SwiperContainer>;
    @ViewChild('mediaOnlySwiper') mediaOnlySwiper: ElementRef<SwiperContainer>;
    @ViewChild('titledMediaSwiper') titledMediaSwiper: ElementRef<SwiperContainer>;
    @ViewChild('rightSlideSwiper') rightSlideSwiper: ElementRef<SwiperContainer>;
    @ViewChild('leftSlideSwiper') leftSlideSwiper: ElementRef<SwiperContainer>;
    @ViewChild('mobileSlideSwiper') mobileSlideSwiper: ElementRef<SwiperContainer>;
    private swiperInstance: Swiper;

    constructor(@Inject(PLATFORM_ID) private platformId: object,
                private cartService: CartService,
                private stateService: StateService,
                private changeDetector: ChangeDetectorRef,
                private router: Router,
                private logger: NGXLogger,
                private el: ElementRef) {
                    this.isBrowser = isPlatformBrowser(this.platformId);
                }

    type: string;
    medias: Asset[] = [];
    mobileMedias: Asset[] = [];
    mobileMediaPosition: string;
    bannerMedias: Asset[] = [];
    title: string;
    text: string;
    textColor: string;
    textAlignment: string;
    textWidth: string;
    textIndentation: string;
    leftRightPartRatio: number;
    shadowTransparency: number;
    backgroundMedia: Asset | null;
    backgroundMobileMedia: Asset | null;
    backgroundColor: string;
    button: ButtonData | null;
    buttonPosition: string;
    buttonLocationX: number;
    buttonLocationY: number;
    buttonPositionMobile: string;
    buttonLocationXMobile: number;
    buttonLocationYMobile: number;
    keyPointsLayoutType: string;
    keyPoints: KeySellingPointData[] = [];
    heroSection: HeroSectionData;
    externalHtmlContent: ExternalHtmlContentData;
    slidesPerView: number;
    inFlight: boolean = false;
    baseUrl = '';
    mediaContainerWidth: number;
    textContainerWidth: number;
    private subscription = new Subscription();
    backgroundMediaHeight = 400;
    backgroundMediaWidth = 0;
    backgroundMobileMediaHeight = 0;
    backgroundMobileMediaWidth = 400;
    mediasHeight = 400;
    mediasWidth = 540;
    isBrowser: boolean;

    ngOnInit(): void {
        this.stateService.select(state => state.storePath)
        .pipe(take(1)).subscribe(baseUrl => {
            this.baseUrl = baseUrl;
        });
        const marketingBanner = this.marketingBanner;
        if(marketingBanner) {
            this.type = marketingBanner.type;
            this.medias = marketingBanner.medias;
            this.mobileMedias = marketingBanner.mobileMedias;
            this.mobileMediaPosition = marketingBanner.mobileMediaPosition;
            this.title = marketingBanner.title;
            this.text = marketingBanner.text;
            this.textColor = marketingBanner.textColor;
            this.textAlignment = marketingBanner.textAlignment;
            this.textWidth = marketingBanner.textWidth*100 + '%';
            this.textIndentation = marketingBanner.textIndentation + 'px';
            this.leftRightPartRatio = marketingBanner.leftRightPartRatio || 0.5;
            this.shadowTransparency = marketingBanner.shadowTransparency || 1.0;
            this.backgroundMedia = marketingBanner.backgroundMedia;
            this.backgroundMobileMedia = marketingBanner.backgroundMobileMedia;
            this.backgroundColor = marketingBanner.backgroundColor;
            this.button = marketingBanner.button;
            this.buttonPosition = marketingBanner.buttonPosition;
            this.buttonLocationX = marketingBanner.buttonLocationX;
            this.buttonLocationY = marketingBanner.buttonLocationY;
            this.buttonPositionMobile = marketingBanner.buttonPositionMobile;
            this.buttonLocationXMobile = marketingBanner.buttonLocationXMobile;
            this.buttonLocationYMobile = marketingBanner.buttonLocationYMobile;
            this.keyPointsLayoutType = marketingBanner.keyPointsLayoutType;
            this.keyPoints = marketingBanner.keyPoints as KeySellingPointData[];
            this.heroSection = marketingBanner.heroSection;
            this.externalHtmlContent = marketingBanner.externalHtmlContent;
            this.bannerMedias = (this.isMobile && this.mobileMedias?.length > 0) ? this.mobileMedias : this.medias;
            this.slidesPerView = Math.min(this.medias.length, 3);
        }
    }

    ngOnChanges() {
        if(this.swiperInstance) {
            this.swiperInstance.update();
            this.changeDetector.detectChanges();
        }
    }

    ngAfterViewInit() {
        if(!this.isBrowser) {
            return;
        }
        if(this.type === 'left-media-right-text') {
            if(this.isMobile) {
                this.swiperInstance = new Swiper(this.leftMediaMobileSwiper.nativeElement);
            } else if(this.isMobile === false) {
                this.swiperInstance = new Swiper(this.leftMediaDesktopSwiper.nativeElement);
            }
        } else if(this.type === 'left-text-right-media') {
            if(this.isMobile) {
                this.swiperInstance = new Swiper(this.rightMediaMobileSwiper.nativeElement);
            } else if(this.isMobile === false) {
                this.swiperInstance = new Swiper(this.rightMediaDesktopSwiper.nativeElement);
            }
        }  else if(this.type === 'below-media-multiple') {
            if(this.isMobile) {
                this.swiperInstance = new Swiper(this.belowMediaMultipleSwiper.nativeElement);
            } else if(this.isMobile === false) {
                this.swiperInstance = new Swiper(this.belowMediaSingleSwiper.nativeElement);
            }
        } else if(this.type === 'media-only') {
            this.swiperInstance = new Swiper(this.mediaOnlySwiper.nativeElement);
        } else if(this.type === 'titled-media') {
            this.swiperInstance = new Swiper(this.titledMediaSwiper.nativeElement);
        } else if(this.type === 'left-media-slide-with-background' || this.type === 'right-media-slide-with-background') {
            if(this.isMobile) {
                this.swiperInstance = new Swiper(this.mobileSlideSwiper.nativeElement);
            } else if(this.isMobile === false) {
                this.swiperInstance = this.type === 'left-media-slide-with-background' ?
                    new Swiper(this.leftSlideSwiper.nativeElement) :
                    new Swiper(this.rightSlideSwiper.nativeElement);
            }
        }
        this.changeDetector.detectChanges();
        setTimeout(() => {
            this.calculateSwiperSize();
        });
    }

    calculateWidths() {
        const totalRatio = 1.0;
        let textWidthPercentage = 50;
        let mediaWidthPercentage = 50;
        if (this.type === 'left-media-right-text' || this.type ==='left-media-slide-with-background') {
            mediaWidthPercentage = this.leftRightPartRatio * 100;
            textWidthPercentage = (totalRatio - this.leftRightPartRatio) * 100;
        } else if (this.type === 'left-text-right-media' || this.type ==='right-media-slide-with-background') {
            textWidthPercentage = this.leftRightPartRatio * 100;
            mediaWidthPercentage = (totalRatio - this.leftRightPartRatio) * 100;
        }
        return { text: `${textWidthPercentage}%`, media: `${mediaWidthPercentage}%` };
    }

    getMediaContainerClass(position: string): string {
        switch (position) {
            case 'top':
                return 'align-top';
            case 'bottom':
                return 'align-bottom';
            case 'center':
            default:
                return 'align-center';
        }
    }

    getButtonStyles() {
        const position = this.isMobile ? this.buttonPositionMobile : this.buttonPosition;
        const locationX = (this.isMobile ? this.buttonLocationXMobile : this.buttonLocationX) || 0.5;
        const locationY = (this.isMobile ? this.buttonLocationYMobile : this.buttonLocationY) || 0.5;
        if (position === 'custom') {
          return {
            position: 'absolute',
            left: `${locationX * 100}%`,
            top: `${locationY * 100}%`,
            transform: 'translate(-50%, -50%)',
            pointerEvents: 'auto',
            zIndex: 10,
          };
        } else {
          return {};
        }
    }

    buttonClick() {
        this.logger.debug('Button clicked', this.button);
        if(this.button?.enabled && this.button?.actionType) {
            if(this.button.actionType === ButtonActionType.NavigateTo) {
                const targetPageSectionId = this.button.targetPageSectionId ? this.button.targetPageSectionId : undefined;
                if(this.button.navigateType === ButtonNavigationType.NavigateToProduct) {
                    this.router.navigate([`${this.baseUrl}/dp`, this.button.targetProduct?.slug], {queryParams: { section: targetPageSectionId }});
                } else if(this.button.navigateType === ButtonNavigationType.NavigateToCollection) {
                    this.router.navigate([`${this.baseUrl}/gp`, this.button.targetCollection?.slug], {queryParams: { section: targetPageSectionId }});
                } else if(this.button.navigateType === ButtonNavigationType.NavigateToPage) {
                    const targetPage = this.button.targetPage;
                    if (isStoreRelatedPage(targetPage)) {
                        // For store-related pages, perform a full page reload
                        window.location.href = `/${targetPage.replace(/^\/+/, '')}`;
                    } else {
                        const pathSegments = normalizeUrlForRouting(this.baseUrl, targetPage);
                        this.router.navigate(pathSegments, {queryParams: { section: targetPageSectionId }});
                    }
                } else if(this.button.navigateType === ButtonNavigationType.NavigateToWebsite) {
                    window && window.open(this.button.targetPage, '_blank');
                }
            } else if(this.button.actionType === ButtonActionType.AddVariantToCart) {
                if(this.button.selectedVariantId) {
                    this.addToCartAndOpenDrawer(this.button.selectedVariantId);
                }
            }
        }
    }

    addToCartAndOpenDrawer(productVariantId: string) {
        this.inFlight = true;
        this.changeDetector.markForCheck();

        this.subscription.add(
          this.cartService.addToCartAndOpenDrawer(productVariantId).subscribe({
            next: () => {
              this.inFlight = false;
              this.changeDetector.markForCheck();
            },
            error: err => {
              this.inFlight = false;
              this.logger.error('Error in addToCartAndOpenDrawer', err);
              this.changeDetector.markForCheck();
            },
          }),
        );
    }

    private calculateSwiperSize() {
        if(isPlatformServer(this.platformId)) {
            return;
        }
        const mediaContainer = this.el.nativeElement.querySelector('.media-container');
        if (!mediaContainer) {
            this.logger.warn('Media container not found');
            return { width: 540, height: 400 }; // Default fallback sizes
        }
        const rect = mediaContainer.getBoundingClientRect();
        if(rect) {
            if (this.isMobile) {
                this.backgroundMobileMediaHeight = rect.height;
                this.backgroundMobileMediaWidth = rect.width;
            } else {
                this.mediasWidth = rect.width;
                this.mediasHeight = rect.height;
                this.backgroundMediaWidth = rect.width;
                this.backgroundMediaHeight = rect.height;
            }
        }
        this.changeDetector.detectChanges();
    }
}
