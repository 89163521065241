import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { StateService } from '../../providers/state/state.service';

@Component({
    selector: 'vsf-product-search-bar',
    templateUrl: './product-search-bar.component.html',
    styleUrls: ['./product-search-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSearchBarComponent implements OnInit, OnDestroy {
    @Input() autoSearch = false;
    @ViewChild('searchOverlay') overlayRef: ElementRef;

    private destroy$ = new Subject<void>();
    showOverlay = false;
    isMobile = false;
    baseUrl = '';
    searchTerm = new UntypedFormControl('');
    private subscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private stateService: StateService,
        private changedetector: ChangeDetectorRef,
        private router: Router
    ) {}

    ngOnInit() {
        this.stateService.select(state => state.storePath).pipe(
            takeUntil(this.destroy$)
        ).subscribe(storePath => {
            this.baseUrl = storePath;
        });
        this.stateService.select(state => state.isMobile).pipe(
            take(1)
        ).subscribe(isMobile => {
            this.isMobile = typeof isMobile === 'boolean' ? isMobile : false;
            this.changedetector.markForCheck();
        });
        if (this.autoSearch) {
            this.subscription = this.searchTerm.valueChanges.pipe(
                debounceTime(250),
            ).subscribe(term => this.doSearch(term));
        }
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.destroy$.next();
        this.destroy$.complete();
    }

    onSearchButtonClick(event: MouseEvent, term: string) {
        event.stopPropagation();
        this.doSearch(term);
    }

    doSearch(term: string) {
        if (term || this.showOverlay) {
            this.router.navigate([this.baseUrl, 'search'], {
                queryParams: { search: term },
                relativeTo: this.route,
                queryParamsHandling: 'merge',
            });
        }
        this.showOverlay = !this.showOverlay; // Toggle overlay visibility
        this.searchTerm.setValue('', { emitEvent: false });
        this.changedetector.markForCheck();
    }

    closeOverlay() {
        this.showOverlay = false;
        this.changedetector.markForCheck();
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: Event) {
        if (this.showOverlay && this.overlayRef && this.overlayRef.nativeElement) {
            const clickedInside = this.overlayRef.nativeElement.contains(event.target);
            if (!clickedInside) {
                this.closeOverlay();
            }
        }
    }
}
