import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';

import {
    Collection,
    GetProductAllDetailQuery,
    GetProductAllDetailQueryVariables,
    StoreSite,
    HeroSectionData
} from '../../../common/generated-types';
import { notNullOrUndefined } from '../../../common/utils/not-null-or-undefined';
import { DataService } from '../../../core/providers/data/data.service';
import { StateService } from '../../../core/providers/state/state.service';

import { GET_PRODUCT_ALL_DETAIL } from '../../../common/graphql/documents.graphql';
import { ActiveService } from '../../../core/providers/active/active.service';
import { NGXLogger } from 'ngx-logger';
import { CombinedAnalyticsService } from '../../../core/providers/analytics/combined-analytics.service';
import { safeJSONParse } from '../../../common/utils/safe-json-parser';
import { CollectionGroupData, ColorConfig, FeaturedCollectionData, LayoutOptionData, NavMenuOption } from '../../../common/interfaces';
import { stripHtml } from '../../../common/utils/strip-html';
import { replaceHostname } from '../../../common/utils/url-handlers';
import { CanonicalService } from '../../../core/providers/canonical/canonical.service';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { DESKTOP_HEADER_HEIGHT, DESKTOP_TOP_MESSAGE_HEIGHT, DESKTOP_SECTION_NAV_HEIGHT, WAIT_FOR_NAVIGATION_INTERVAL, WAIT_FOR_NAVIGATION_TIMEOUT } from '../../../common/constants';

type Variant = NonNullable<GetProductAllDetailQuery['product']>['variants'][number];

declare let document: Document & typeof globalThis;

@Component({
    selector: 'vsf-product-showcase',
    templateUrl: './product-showcase.component.html',
    styleUrls: ['./product-showcase.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductShowcaseComponent implements OnInit, OnDestroy {

    product: GetProductAllDetailQuery['product'];
    qtyInCart: { [id: string]: number; } = {};
    selectedVariant: Variant;
    qty = 1;
    breadcrumbs: Collection['breadcrumbs'] | null = null;
    inFlight = false;
    inStock = true;
    @ViewChild('addedToCartTemplate', {static: true})
    private sub: Subscription;
    private orderSub: Subscription;
    private stateSub: Subscription;
    selectedVariantMSRP: number | null = null;
    discountPercentage: number | null = null;
    isMobile: boolean | null = null;
    showFixedBanner: boolean = false;
    isHeaderFloating: boolean = false;
    showTopNav: boolean = true;
    shopNowBannerTopOffset: number = 0;
    baseUrl = '';
    shopHost: string;
    currentCanonicalURL: string | null = null;
    heroSection: HeroSectionData | null = null;
    colorConfig: ColorConfig | null = null;
    storeSite: StoreSite | null = null;
    withHeaderMessage: boolean = true;
    layoutOrder: LayoutOptionData[] = [];
    navigationMenu: NavMenuOption[] = [];
    marketingBanners: any[] = [];
    featuredCollectionList: FeaturedCollectionData[] = [];
    collectionListForShopByGroupList: CollectionGroupData[] = [];
    desktopHeaderHeight = DESKTOP_HEADER_HEIGHT;
    desktopTopMessageHeight = DESKTOP_TOP_MESSAGE_HEIGHT;
    desktopSectionNavHeight = DESKTOP_SECTION_NAV_HEIGHT;
    waitForNavigationInterval = WAIT_FOR_NAVIGATION_INTERVAL;
    waitForNavigationTimeout = WAIT_FOR_NAVIGATION_TIMEOUT;

    constructor(@Inject(PLATFORM_ID) private platformId: object,
                private stateService: StateService,
                private activeService: ActiveService,
                private canonicalService: CanonicalService,
                private route: ActivatedRoute,
                private router: Router,
                private titleService: Title,
                private metaService: Meta,
                private changeDetector: ChangeDetectorRef,
                private viewportScroller: ViewportScroller,
                private analyticsService: CombinedAnalyticsService,
                private logger: NGXLogger) {
    }
    async ngOnInit() {
        const isMobile$ = this.stateService.select(state => state.isMobile);
        const storeInfo$ = this.stateService.select(state => state.storeInfo);
        const storePath$ = this.stateService.select(state => state.storePath);
        const shopHost$ = this.stateService.select(state => state.shopHost);
        const hasTopMessage$ = this.stateService.select(state => state.hasTopMessage);
        await combineLatest([isMobile$, storeInfo$, storePath$, shopHost$, hasTopMessage$])
        .pipe(take(1)).subscribe(([isMobile, storeInfo, storePath, shopHost, hasTopMessage]) => {
            this.isMobile = isMobile;
            this.storeSite = storeInfo;
            this.baseUrl = storePath;
            this.shopHost = shopHost;
            if(this.withHeaderMessage !== hasTopMessage) {
                this.withHeaderMessage = hasTopMessage;
                this.updateShopNowBannerTopOffset()
            }
        });
        this.stateSub = this.stateService.select(state => ({
            isHeaderFloating: state.isHeaderFloating,
          })).subscribe(({ isHeaderFloating }) => {
            if(isHeaderFloating !== this.isHeaderFloating) {
                this.isHeaderFloating = isHeaderFloating;
                this.updateShopNowBannerTopOffset()
            }
        });

        const productSlug$ = this.route.paramMap.pipe(
            map(paramMap => paramMap.get('slug')),
            filter(notNullOrUndefined),
            distinctUntilChanged()
        );
        const variantId$ = this.route.queryParamMap.pipe(
            map(params => params.get('variantid')),
        );

        this.sub = combineLatest([productSlug$, variantId$]).subscribe(([ _, variantId]) => {
            this.product = this.route.snapshot.data['product'];
            if (variantId) {
                const variant = this.product?.variants.find(v => v.id === variantId);
                if (variant) {
                    this.selectedVariant = variant;
                } else {
                    this.selectedVariant = this.product?.variants[0] as any;
                }
            } else {
                this.selectedVariant = this.product?.variants[0] as any;
            }
            const canonicalURL = `${this.baseUrl}/dp/${this.product?.slug}?variantid=${this.selectedVariant.id}`;
            this.canonicalService.setCanonicalURL(`${this.shopHost}${canonicalURL}`);
            this.updateProductMetadata(this.product, this.selectedVariant.id);
            this.currentCanonicalURL = canonicalURL;
            try {
                this.selectItemEvent(this.product);
            } catch (e) {
                this.logger.error('Error in select ItemEvent', e);
            }

            this.colorConfig = safeJSONParse<ColorConfig>(this.storeSite?.colorConfig, this.logger);

            this.layoutOrder = (this.product?.customFields?.layoutOrder || [])
            .map(jsonString => {
                const layoutOption =  safeJSONParse<LayoutOptionData>(jsonString, this.logger);
                if (layoutOption) {
                  // Compute desktop width percentage
                  layoutOption.widthPercentage = layoutOption.width && layoutOption.width > 0
                    ? `${layoutOption.width * 100}%`
                    : '100%';
                  // Compute mobile width percentage
                  layoutOption.mobileWidthPercentage = layoutOption.mobileWidth && layoutOption.mobileWidth > 0
                    ? `${layoutOption.mobileWidth * 100}%`
                    : '100%';
                  // Ensure margin properties are numbers (fallback to 0 if undefined)
                  layoutOption.topMargin = layoutOption.topMargin || 0;
                  layoutOption.bottomMargin = layoutOption.bottomMargin || 0;
                  layoutOption.mobileTopMargin = layoutOption.mobileTopMargin || 0;
                  layoutOption.mobileBottomMargin = layoutOption.mobileBottomMargin || 0;
                }
                return layoutOption;
            })
            .filter(notNullOrUndefined);
            this.navigationMenu = (this.product?.customFields?.navigationMenu || [])
            .map(jsonString => safeJSONParse<NavMenuOption>(jsonString, this.logger))
            .filter(notNullOrUndefined);

            this.marketingBanners = (this.product?.marketingBanners || [])
            this.heroSection = this.product?.heroSection as HeroSectionData || null;
            this.collectionListForShopByGroupList = this.product?.collectionGroupDataList as CollectionGroupData[] || [];
            this.featuredCollectionList = this.product?.featuredCollectionDataList as any[] || null;

            if(!variantId) {
                this.selectedVariant = this.product?.variants[0] as any;
            }
            this.inStock = (this.selectedVariant.stockLevel !== 'OUT_OF_STOCK');
            this.updateMSRP();
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: { variantid: this.selectedVariant.id },
                queryParamsHandling: 'merge',
            });
            if (isPlatformBrowser(this.platformId)) {
                this.viewportScroller.scrollToPosition([0, 0]);
            }
            this.changeDetector.detectChanges();
        });

        this.orderSub = this.activeService.activeOrder$.subscribe(order => {
            this.qtyInCart = {};
            for (const line of order?.lines ?? []) {
                this.qtyInCart[line.productVariant.id] = line.quantity;
            }
        });

        if(isPlatformBrowser(this.platformId)) {
            window.addEventListener('scroll', () => {
                const scrolled = window.scrollY || document.documentElement.scrollTop;
                let showTopNav = true;
                // If scrolled beyond a certain point, hide top nav => show floating nav
                if (scrolled > 150) {
                    showTopNav = false;
                }
                if(showTopNav !== this.showTopNav) {
                    this.showTopNav = showTopNav;
                    this.updateShopNowBannerTopOffset()
                }
            });
            this.route.queryParams.subscribe((params) => {
                const section = params['section'];
                if (section) {
                  this.scrollToSectionAfterLoad(section);
                }
            });
        }
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        if (this.orderSub) {
            this.orderSub.unsubscribe();
        }
        if (this.stateSub) {
            this.stateSub.unsubscribe();
        }
        this.removeMetadata();
        if (this.currentCanonicalURL) {
            this.canonicalService.removeCanonicalURL();
            this.currentCanonicalURL = null;
        }
    }

    scrollToSection(item: NavMenuOption): void {
        if(item.type === 'other-page') {
            this.router.navigate([this.baseUrl, item.url]);
        } else if(item.type === 'page-section') {
            const targetEl = document.getElementById(item.url);
            if (targetEl) {
                targetEl.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    scrollToSectionAfterLoad(sectionId: string): void {
        const interval = setInterval(() => {
          const targetEl = document.getElementById(sectionId);
          if (targetEl) {
            targetEl.scrollIntoView({ behavior: 'smooth' });
            clearInterval(interval);
          }
        }, this.waitForNavigationInterval);
        setTimeout(() => clearInterval(interval), this.waitForNavigationTimeout);
    }

    private updateProductMetadata(product: any, variantId: any): void {
        if (!product) return;
        const heroSection = product?.heroSection;
        const title = this.selectedVariant?.name ? this.selectedVariant?.name : product.name;
        // Set the page title
        this.titleService.setTitle(title);
        // Set meta description
        const description = product.customFields?.OGDescription ? product.customFields?.OGDescription :
          (product.customFields?.ProductSubtitle ? product.customFields?.ProductSubtitle :
            (stripHtml(product.description, this.platformId)));
        this.metaService.updateTag({ name: 'description', content: description });
        // Set Open Graph meta tags
        this.metaService.updateTag({ property: 'og:title', content: title });
        this.metaService.updateTag({ property: 'og:description', content: description });
        this.metaService.updateTag({ property: 'og:type', content: 'product' });
        this.metaService.updateTag({ property: 'og:url', content: `${this.shopHost}${this.baseUrl}/dp/${product.slug}?variantid=${variantId}` });
        // Set Twitter Card meta tags
        this.metaService.updateTag({ name: 'twitter:title', content: title });
        this.metaService.updateTag({ name: 'twitter:description', content: description });
        // Set image meta tags
        const fp = product.featuredAsset?.focalPoint ? `&fpx=${product.featuredAsset.focalPoint.x}&fpy=${product.featuredAsset.focalPoint.y}` : '';
        const imageUrl = product.customFields.OGImage ? (`${product.customFields.OGImage.preview}?preset='full'&format=webp&cache=true`) :
          ((heroSection?.medias && heroSection?.medias.length > 0 && heroSection?.medias[0].type === 'IMAGE') ?
            (`${heroSection?.medias[0].preview}?preset='full'&format=webp&cache=true`) :
            (product.featuredAsset ? (`${product.featuredAsset?.preview}?w=600&h=315${fp}&format=webp&cache=true`) : ''));
        if (imageUrl) {
          this.metaService.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
          this.metaService.updateTag({ property: 'og:image', content: replaceHostname(imageUrl, this.shopHost) });
          this.metaService.updateTag({ property: 'og:image:alt', content: title });
          this.metaService.updateTag({ name: 'twitter:image', content: replaceHostname(imageUrl, this.shopHost) });
          this.metaService.updateTag({ name: 'twitter:image:alt', content: title });
        } else {
          this.metaService.updateTag({ name: 'twitter:card', content: 'summary' });
        }
        this.logger.debug(`Updated metadata for product:${title} variantId:${variantId}`);
    }

    private removeMetadata() {
        this.titleService.setTitle(`${this.storeSite?.name? this.storeSite.name : ''}`);
        this.metaService.removeTag('name="description"');
        this.metaService.removeTag('property="og:title"');
        this.metaService.removeTag('property="og:description"');
        this.metaService.removeTag('property="og:type"');
        this.metaService.removeTag('property="og:url"');
        this.metaService.removeTag('property="og:image"');
        this.metaService.removeTag('name="twitter:card"');
        this.metaService.removeTag('name="twitter:title"');
        this.metaService.removeTag('name="twitter:description"');
        this.metaService.removeTag('name="twitter:image"');
    }

    selectItemEvent(product: any) {
        if(product) {
            this.analyticsService.viewItem(product);
        }
    }

    viewCartFromNotification(closeFn: () => void) {
        this.stateService.setState('cartDrawerOpen', true);
        closeFn();
    }

    updateMSRP() {
        if (this.selectedVariant && this.selectedVariant.customFields?.MSRP && this.selectedVariant.customFields.MSRP !== 0) {
            this.selectedVariantMSRP = this.selectedVariant.customFields.MSRP;
            const price = this.selectedVariant.price;
            const msrp = this.selectedVariantMSRP;
            if (msrp && msrp > price) {
                this.discountPercentage = ((msrp - price) / msrp) * 100;
            } else {
                this.discountPercentage = null;
            }
        } else {
            this.selectedVariantMSRP = null;
            this.discountPercentage = null;
        }
    }

    onVariantChange(event: { variant: Variant }) {
        this.logger.debug(`onVariantChange: ${event.variant.id}`);
        this.selectedVariant = event.variant;
        this.inStock = (this.selectedVariant.stockLevel !== 'OUT_OF_STOCK');
        this.updateMSRP();
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { variantid: this.selectedVariant.id, section: undefined },
            queryParamsHandling: 'merge',
        });
    }

    onShopNowBannerVisibilityChange(isVisible: boolean) {
        this.showFixedBanner = !isVisible && !this.isMobile;
    }

    updateShopNowBannerTopOffset () {
        let height = 0;
        if (this.isHeaderFloating) {
          height = this.desktopHeaderHeight;
          if (this.withHeaderMessage) {
            height += this.desktopTopMessageHeight;
          }
        }
        if(!this.showTopNav && this.navigationMenu.length > 0) {
            height += this.desktopSectionNavHeight;
        }
        this.shopNowBannerTopOffset = height;
        this.changeDetector.markForCheck();
    }
}
