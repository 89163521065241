import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { Asset } from '../../../common/generated-types';
import { ColorConfig } from '../../../common/interfaces';

interface NavigationItem {
  label: string;
  type: string;
  policyName?: string;
  url: string;
}

interface NavigationColumn {
    name: string;
    items: NavigationItem[];
}

interface PolicyContentData {
  type: string;
  title: string;
  path: string;
  content: string;
}

interface SnsInfo {
  facebook?: string;
  instagram?: string;
  xTwitter?: string;
  youtube?: string;
}

@Component({
  selector: 'vsf-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageFooterComponent implements OnInit, OnChanges {
  @Input() navigationColumns: NavigationColumn[];
  @Input() storeLogo: Asset;
  @Input() policyContentList: PolicyContentData[];
  @Input() isMobile: boolean | null;
  @Input() snsInfo: SnsInfo;
  @Input() baseUrl: string;
  @Input() storeEmail: string;
  @Input() orderStatusHref: string;
  @Input() storeColor: ColorConfig;

  disclaimer: string = '';
  copyrightNotice: string = '';
  contactEmail: string = '';

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.disclaimer = this.policyContentList?.find(policy => policy.type === 'disclaimer')?.content || '';
    this.copyrightNotice = this.policyContentList?.find(policy => policy.type === 'copyright-notice')?.content || '';
    this.contactEmail = this.storeEmail ? this.storeEmail : 'support@dealomega.com';
  }

  ngOnChanges(changes: SimpleChanges) {
    if('orderStatusHref' in changes && changes.orderStatusHref.currentValue !== this.orderStatusHref) {
      this.orderStatusHref = changes.orderStatusHref.currentValue;
      this.changeDetector.markForCheck();
    }
  }

  getItemHref(item: NavigationItem): string | null {
    switch (item.type) {
      case 'page':
      case 'website':
        return item.url;

      case 'policy':
        if (item.policyName && item.policyName !== 'other') {
          // Find matching policy in policyContentList
          const matchingPolicy = this.policyContentList?.find(
            policy => policy.type === item.policyName
          );
          if (matchingPolicy) {
            return '/store-policy/' + matchingPolicy.path;
          } else {
            return '/policy/' + item.url;
          }
        }
        if (item.policyName === 'other') {
          return '/store-policy/' + item.url;
        }
        return null;
      default:
        return null;
    }
  }
}