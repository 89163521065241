import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { CartService } from '../../../core/providers/cart/cart.service';
import { ButtonData, HeroSectionData } from '../../../common/interfaces';
import { NotificationService } from '../../../core/providers/notification/notification.service';
import { Asset } from '../../../common/generated-types';
import { StateService } from '../../../core/providers/state/state.service';
import { ButtonActionType, ButtonNavigationType } from '../../../common/enums';
import { isStoreRelatedPage, normalizeUrlForRouting } from '../../../common/utils/url-handlers';

const DELAY_TIME = 1000;

@Component({
    selector: 'vsf-hero-section',
    templateUrl: './hero-section.component.html',
    styleUrls: ['./hero-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeroSectionComponent implements OnInit, AfterViewInit {
    @Input() heroSection: HeroSectionData;
    @Input() isMobile: boolean;

    layoutType: 'left-text-full-media' | 'right-text-full-media' | 'middle-text-full-media' = 'left-text-full-media';
    title: string;
    subtitle: string;
    titleFontSize: string;
    titleFontSizeMobile: string;
    textColor: string;
    medias: Asset[];
    button: ButtonData | null;
    buttonPosition: string;
    buttonLocationX: number;
    buttonLocationY: number;
    buttonPositionMobile: string;
    buttonLocationXMobile: number;
    buttonLocationYMobile: number;
    shadowOpacity = 0;
    inFlight = false;
    defaultHeroFontSize = 40;
    mobileHeroFontSizeRatio = 0.4;
    baseUrl = '';
    notificationService: NotificationService;
    private subscription = new Subscription();
    swiperSlideWidth = 1152;
    swiperSlideHeight = 658;
    isBrowser: boolean;

    private static readonly BREAK_POINT_CONFIG = [
      { query: '(max-width: 300px)', ratio: 0.3 },
      { query: '(max-width: 400px)', ratio: 0.37 },
      { query: '(max-width: 500px)', ratio: 0.45 },
      { query: '(max-width: 600px)', ratio: 0.57 },
      { query: '(max-width: 700px)', ratio: 0.65 },
      { query: '(min-width: 701px)', ratio: 0.75 }
    ];

    constructor(@Inject(PLATFORM_ID) private platformId: object,
                private changeDetector: ChangeDetectorRef,
                private router: Router,
                private cartService: CartService,
                private stateService: StateService,
                private logger: NGXLogger,
                private elementRef: ElementRef,
                private cdr: ChangeDetectorRef,
                private breakpointObserver: BreakpointObserver) {
                  this.isBrowser = isPlatformBrowser(this.platformId);
                }

    ngOnInit() {
        this.stateService.select(state => state.storePath).subscribe(storePath => {
          this.baseUrl = storePath;
        });
        this.logger.info('Hero Section', JSON.stringify(this.heroSection));
        this.layoutType = this.heroSection?.layoutType;
        this.title = this.heroSection?.title;
        this.titleFontSize = `${this.heroSection?.titleFontSize}px`;
        if (isPlatformBrowser(this.platformId)) {
          const breakpointQueries = HeroSectionComponent.BREAK_POINT_CONFIG.map(config => config.query);
          this.breakpointObserver
            .observe(breakpointQueries)
            .subscribe(result => {
              const breakpoints = result.breakpoints;
              const matchedBreakpoint = HeroSectionComponent.BREAK_POINT_CONFIG.find(config => breakpoints[config.query]);
              if (matchedBreakpoint) {
                this.mobileHeroFontSizeRatio = matchedBreakpoint.ratio;
              } else {
                this.mobileHeroFontSizeRatio = 0.75;
              }
              this.titleFontSizeMobile = `${(this.heroSection?.titleFontSize || this.defaultHeroFontSize) * this.mobileHeroFontSizeRatio}px`;
              this.changeDetector.markForCheck();
            });
        }
        this.subtitle = this.heroSection?.subtitle;
        this.textColor = this.heroSection?.textColor;
        this.medias = this.isMobile ?
          (this.heroSection?.mobileMedias?.length > 0 ? this.heroSection?.mobileMedias : this.heroSection?.medias)
          : this.heroSection?.medias;
        this.shadowOpacity = (1.0-this.heroSection?.shadowTransparency) || this.shadowOpacity;
        this.button = this.heroSection?.button;
        this.buttonPosition = this.heroSection?.buttonPosition;
        this.buttonLocationX = this.heroSection?.buttonLocationX;
        this.buttonLocationY = this.heroSection?.buttonLocationY;
        this.buttonPositionMobile = this.heroSection?.buttonPositionMobile;
        this.buttonLocationXMobile = this.heroSection?.buttonLocationXMobile;
        this.buttonLocationYMobile = this.heroSection?.buttonLocationYMobile;
        this.changeDetector.markForCheck();
    }

    ngAfterViewInit() {
        if (this.isBrowser) {
          // Delay the execution of getSwiperSlideSize
          setTimeout(() => {
            this.getSwiperSlideSize();
            // Trigger change detection to update the view
            this.cdr.detectChanges();
          }, DELAY_TIME);
        }
      }

    @HostListener('window:resize', [])
    onResize() {
      this.getSwiperSlideSize();
    }

    buttonClick(event: Event) {
        this.logger.debug('Button clicked', this.button);
        if(this.button?.enabled && this.button?.actionType) {
            if(this.button.actionType === ButtonActionType.NavigateTo) {
                const targetPageSectionId = this.button.targetPageSectionId ? this.button.targetPageSectionId : undefined;
                if(this.button.navigateType === ButtonNavigationType.NavigateToProduct) {
                    this.router.navigate([`${this.baseUrl}/dp`, this.button.targetProduct?.slug], {queryParams: { section: targetPageSectionId }});
                } else if(this.button.navigateType === ButtonNavigationType.NavigateToCollection) {
                    this.router.navigate([`${this.baseUrl}/gp`, this.button.targetCollection?.slug], {queryParams: { section: targetPageSectionId }});
                } else if(this.button.navigateType === ButtonNavigationType.NavigateToPage) {
                    const targetPage = this.button.targetPage;
                    if (isStoreRelatedPage(targetPage)) {
                        // For store-related pages, perform a full page reload
                        window.location.href = `/${targetPage.replace(/^\/+/, '')}`;
                    } else {
                        const pathSegments = normalizeUrlForRouting(this.baseUrl, targetPage);
                        this.router.navigate(pathSegments, {queryParams: { section: targetPageSectionId }});
                    }
                } else if(this.button.navigateType === ButtonNavigationType.NavigateToWebsite) {
                    window && window.open(this.button.targetPage, '_blank');
                }
            } else if(this.button.actionType === ButtonActionType.AddVariantToCart) {
                if(this.button.selectedVariantId) {
                    this.addToCartAndOpenDrawer(this.button.selectedVariantId);
                }
            }
        }
    }

    addToCartAndOpenDrawer(variantId: string) {
        this.inFlight = true;
        this.changeDetector.markForCheck();

        this.subscription.add(
          this.cartService.addToCartAndOpenDrawer(variantId).subscribe({
            next: () => {
              this.inFlight = false;
              this.changeDetector.markForCheck();
            },
            error: err => {
              this.inFlight = false;
              this.logger.error('Error in addToCartAndOpenDrawer', err);
              this.changeDetector.markForCheck();
            },
          }),
        );
    }

    getButtonStyles(): { [key: string]: string } {
      const isCustomPosition = (this.isMobile ? this.buttonPositionMobile : this.buttonPosition) === 'custom';
      if (!isCustomPosition) {
        return {};
      }
      const locationX = (this.isMobile ? this.buttonLocationXMobile : this.buttonLocationX) || 0.5;
      const locationY = (this.isMobile ? this.buttonLocationYMobile : this.buttonLocationY) || 0.5;
      return {
        position: 'absolute',
        left: `${locationX * 100}%`,
        top: `${locationY * 100}%`,
        transform: 'translate(-50%, -50%)',
        'pointer-events': 'auto',
        'z-index': '5',
      };
    }

    getVimeoId(source: string): number {
        const match = source.match(/vimeo\.com\/(\d+)/);
        return match ? parseInt(match[1], 10) : 0;
    }

    // use native elements to calculate the swiper-slide width and height
    getSwiperSlideSize() {
        if(!this.isBrowser) {
            return;
        }
        const rect = this.elementRef.nativeElement.parentElement.getBoundingClientRect();
        this.swiperSlideWidth = rect.width;
        this.swiperSlideHeight = rect.height || this.swiperSlideHeight;
    }
}
