import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ExternalHtmlContentData } from '../../../common/interfaces';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'vsf-external-html-content',
    templateUrl: './external-html-content.component.html',
    styleUrls: ['./external-html-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalHtmlContentComponent implements OnInit {
    @Input() externalHtmlContent: ExternalHtmlContentData;
    @Input() isMobile: boolean = false;

    htmlContent: SafeHtml = '';
    assetUrls: string[] = [];
    title: string = '';

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private logger: NGXLogger,
        private sanitizer: DomSanitizer,
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.title = this.externalHtmlContent?.title;
        this.assetUrls = this.externalHtmlContent?.medias.map(media => `${media.preview}?preset=full`) || [];
        if(this.isMobile && this.externalHtmlContent?.mobileMedias?.length>0) {
            this.assetUrls = this.externalHtmlContent?.mobileMedias.map(media => `${media.preview}?preset=full`) || [];
        }
        if(isPlatformBrowser(this.platformId)) {
            this.htmlContent = this.sanitizeAndReplaceImages(this.externalHtmlContent?.htmlContent, this.assetUrls);
        }
        this.changeDetectorRef.markForCheck();
    }

    sanitizeAndReplaceImages(html: string, newImageUrls: string[]): SafeHtml {
        // Create a DOM parser to manipulate the HTML
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        // 1. Remove all <script> tags
        const scripts = doc.querySelectorAll('script');
        scripts.forEach(script => script.remove());
        //2. (Optional) Remove any inline event handlers on elements
        //   e.g. onClick, onMouseOver, etc.
        Array.from(doc.querySelectorAll<HTMLElement>('*')).forEach((el) => {
            // Remove all attributes starting with 'on'
            for (const attr of Array.from(el.attributes)) {
                if (attr.name.startsWith('on')) {
                    el.removeAttribute(attr.name);
                }
            }
        });

        // Select all <img> tags
        const images = doc.querySelectorAll('img');
        // Replace the `src` attribute with the new URLs
        images.forEach((img, index) => {
            if (newImageUrls[index]) {
                img.src = newImageUrls[index];
            }
        });

        // Serialize the modified DOM back to a string
        const updatedHtml = doc.body.innerHTML;

        // Sanitize the modified HTML before using it
        return this.sanitizer.bypassSecurityTrustHtml(updatedHtml);
    }
}
