<div *ngIf="cart">
    <label for="discount-code" class="block text-sm/6 font-medium text-gray-700 mb-2">Discount code</label>
    <div class="flex space-x-4">
        <input
            type="text"
            id="discount-code"
            name="discount-code"
            class="block w-full rounded-md bg-white px-3 py-1 text-base text-gray-900 placeholder:text-gray-400  sm:text-sm/6"
            placeholder="Promo Code"
            [(ngModel)]="promoCode"
        >
        <button
            (click)="applyCoupon()"
            type="submit"
            [disabled]="!promoCode || processingCoupon"
            class="btn-primary flex w-auto ml-2"
        >Apply</button>
    </div>
    <div *ngIf="couponError" class="text-red-500 text-sm ml-2">
        {{ couponError }}
    </div>
    <div *ngIf="couponMessage" class="text-green-500 text-sm ml-2">
        {{ couponMessage }}
    </div>
</div>