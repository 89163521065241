import {gql} from 'apollo-angular';


import { CART_FRAGMENT, ERROR_RESULT_FRAGMENT, ORDER_ADDRESS_FRAGMENT, PAYMENT_INTENT_FRAGMENT } from '../../../common/graphql/fragments.graphql';

export const GET_ORDER_SHIPPING_DATA = gql`
    query GetOrderShippingData {
        activeOrder {
            id
            customer {
                id
                firstName
                lastName
                emailAddress
            }
            shippingAddress {
                ...OrderAddress
            }
        }
    }
    ${ORDER_ADDRESS_FRAGMENT}
`;

export const VALIDATE_ADDRESS = gql`
  mutation ValidateAddress($input: CreateAddressInput!) {
    validateAddress(input: $input) {
      success
      message
    }
  }
`;

export const SET_SHIPPING_ADDRESS = gql`
    mutation SetShippingAddress($input: CreateAddressInput!) {
        setOrderShippingAddress(input: $input) {
            ...Cart
            ...on Order {
                shippingAddress {
                    ...OrderAddress
                }
            }
            ...ErrorResult
        }
    }
    ${CART_FRAGMENT}
    ${ORDER_ADDRESS_FRAGMENT}
    ${ERROR_RESULT_FRAGMENT}
`;

export const GET_ELIGIBLE_SHIPPING_METHODS = gql`
    query GetEligibleShippingMethods {
        eligibleShippingMethods {
            id
            name
            description
            price
            priceWithTax
            metadata
        }
    }
`;

export const SET_SHIPPING_METHOD = gql`
    mutation SetShippingMethod($id: [ID!]!) {
        setOrderShippingMethod(shippingMethodId: $id) {
            ...Cart
            ...ErrorResult
        }
    }
    ${CART_FRAGMENT}
    ${ERROR_RESULT_FRAGMENT}
`;

export const SET_CUSTOMER_FOR_ORDER = gql`
    mutation SetCustomerForOrder($input: CreateCustomerInput!) {
        setCustomerForOrder(input: $input) {
            ...on Order {
                id
                customer {
                    id
                    emailAddress
                    firstName
                    lastName
                }
            }
            ...ErrorResult
        }
    }
    ${ERROR_RESULT_FRAGMENT}
`;

export const TRANSITION_TO_ARRANGING_PAYMENT = gql`
    mutation TransitionToArrangingPayment {
        transitionOrderToState(state: "ArrangingPayment") {
            ...Cart
            ...ErrorResult
        }
    }
    ${CART_FRAGMENT}
    ${ERROR_RESULT_FRAGMENT}
`;

export const GET_NEXT_ORDER_STATES = gql`
    query GetNextOrderStates {
        nextOrderStates
    }
`;

export const TRANSITION_TO_ADDING_ITEMS = gql`
    mutation TransitionToAddingItems {
        transitionOrderToState(state: "AddingItems") {
            ...Cart
            ...ErrorResult
        }
    }
    ${CART_FRAGMENT}
    ${ERROR_RESULT_FRAGMENT}
`;


export const GET_ORDER_BY_CODE = gql`
    query GetOrderByCode($code: String!) {
        orderByCode(code: $code) {
            ...Cart
            updatedAt
            customer {
                id
                emailAddress
                firstName
                lastName
                user {
                    id
                    identifier
                    verified
                }
            }
        }
    }
    ${CART_FRAGMENT}
`;

export const GET_ELIGIBLE_PAYMENT_METHODS = gql`
    query GetEligiblePaymentMethods {
        eligiblePaymentMethods {
            id
            code
            eligibilityMessage
            isEligible
            linkedStripeAccountId
        }
    }
`;

export const ADD_PAYMENT = gql`
    mutation AddPayment($input: PaymentInput!) {
        addPaymentToOrder(input: $input) {
            ...Cart
            ...ErrorResult
        }
    }
    ${CART_FRAGMENT}
    ${ERROR_RESULT_FRAGMENT}
`;

export const CREATE_STRIPE_PAYMENT_INTENT = gql`
    mutation createStripePaymentIntent {
        createStripePaymentIntent {
            ...PaymentIntentResponse
        }
    }
    ${PAYMENT_INTENT_FRAGMENT}
`;

export const CONFIRM_STRIPE_PAYMENT_INTENT = gql`
    mutation confirmStripePaymentIntent($input: ConfirmPaymentIntentInput!) {
        confirmStripePaymentIntent(input: $input) {
            requiresAction
            clientSecret
        }
    }
`;

