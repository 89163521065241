<div *ngIf="collection">
    <div class="w-full h-full full-size collection-image">
        <img loading="lazy"
            [src]="collection.featuredAsset | assetPreview:'full'"
            [alt]="collection.name"
            style="pointer-events: none;"
            class="w-full h-full object-cover aspect-[1/1]"
        />
    </div>
    <div class="text-center items-center">
        <div class="collection-name mt-4 mb-4">
            <p class="fixed-two-lines">
                {{ collection.name }}
            </p>
        </div>
        <div class="flex justify-center">
            <button (click)="navigateToCollectionPage()"
                [ngStyle]="{
                    'background-color': colorConfig ? colorConfig.secondaryColor : '#B6E7B0',
                    'color': colorConfig ? '#000000' : '#FFFFFF'
                }"
                class="w-auto px-6 py-2 whitespace-nowrap pointer-events-auto button-text">
                SHOP NOW
            </button>
        </div>
    </div>
</div>