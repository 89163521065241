<a
  prefetch='intent' [routerLink]="[productBaseUrl, item.slug]" [queryParams]="{ variantid: item.productVariantId }"
  [ngClass]="{'aspect-[4/1]': size === 'small', 'aspect-[3/1]': size === 'large'}"
  class="relative w-full h-full border border-gray-100 rounded-sm bg-white shadow-sm hover:border-gray-400 flex flex-row overflow-y-auto">

  <!-- Left side (Asset Image) -->
  <div *ngIf="type === 'left-asset'"
       class="flex w-auto items-center justify-center">
    <div
      [ngClass]="{'p-4 max-w-[150x] md:max-w-[350px]': size === 'small',
                  'p-1 max-w-[300px] md:max-w-[500px]': size === 'large'}"
      class="bg-grey-100 text-white flex items-center justify-center w-auto h-full aspect-[1/1]">
      <img [src]="featuredAsset | assetPreview:'large'"
           alt="{{ itemName }}"
           class="w-full h-full object-cover rounded-md">
    </div>
  </div>

  <!-- Content area -->
  <div class="flex-1 flex flex-col justify-between"
    [ngClass]="{'w-2/3': size === 'large', 'w-3/4': size === 'small'}">
    <div class="flex-grow">
      <!-- Title -->
      <div [ngClass]="{'px-2 py-0 text-md': size === 'small', 'px-4 py-1 text-lg': size === 'large'}"
           class="bg-grey-100 line-clamp-1 md:line-clamp-2 overflow-hidden leading-snug md:h-[3rem]">
        <p class="text-black font-bold" title="{{ itemName }}">{{ itemName }}</p>
      </div>
      <!-- Subtitle -->
      <div *ngIf="item.productSubtitle"
           [ngClass]="{'px-2 py-0 text-sm': size === 'small', 'px-4 py-1 text-md': size === 'large'}"
           class="bg-grey-100 line-clamp-1">
        <p class="text-gray-700" title="{{ item.productSubtitle }}">{{ item.productSubtitle }}</p>
      </div>
    </div>

    <div>
      <!-- Metrics -->
      <div *ngIf="size === 'small'" class="flex space-x-1 bg-white px-2">
        <div *ngIf="displayViewers" class="text-center px-2 text-sm text-gray-700">
          <fa-icon icon="eye" class="text-yellow-500"></fa-icon>
          1231
        </div>
        <div *ngIf="displayLikes" class="text-center px-2 text-sm text-gray-700">
          <fa-icon icon="heart" class="text-yellow-500"></fa-icon>
          543
        </div>
        <div *ngIf="displayRanking" class="text-center px-2 text-sm text-gray-700">
          <fa-icon icon="ranking-star" class="text-yellow-500"></fa-icon>
          07
        </div>
      </div>

      <div *ngIf="size === 'large'" class="flex space-x-4 bg-white py-1 px-4">
        <div *ngIf="displayViewers" class="text-gray-700 font-medium">
          <fa-icon icon="eye" class="text-yellow-500"></fa-icon>
          1000
        </div>
        <div *ngIf="displayLikes" class="text-gray-700 font-medium">
          <fa-icon icon="heart" class="text-yellow-500"></fa-icon>
          200
        </div>
        <div *ngIf="displayRanking" class="text-gray-700 font-medium">
          <fa-icon icon="ranking-star" class="text-yellow-500"></fa-icon>
          5
        </div>
      </div>

      <!-- Price, Sold, Add to Cart -->
      <div
        [ngClass]="{'px-2': size === 'small', 'py-1 px-4': size === 'large'}"
        class="flex items-center justify-between bg-white">
        <div class="flex space-x-1 items-center">
          <div *ngIf="displayPrice" class="text-center px-2 text-md font-bold text-gray-700">
            <div *ngIf="item.price.__typename === 'SinglePrice'">
              {{ itemPrice | formatPrice }}
            </div>
            <div *ngIf="item.price.__typename === 'PriceRange'">
              <ng-template [ngIf]="item.price.min === item.price.max" [ngIfElse]="range">
                {{ itemPrice | formatPrice }}
              </ng-template>
              <ng-template #range>
                  From {{ item.price.min | formatPrice }}
              </ng-template>
            </div>
          </div>
          <div *ngIf="displaySold" class="text-center py-1 px-2 text-sm text-gray-700 flex flex-row items-center">
            <svg class="size-5 text-yellow-500" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
              <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
            </svg>
            <p class="px-1 text-sm text-gray-700">100</p>
          </div>
        </div>
        <fa-icon
          *ngIf="displayBuyButton"
          icon="cart-shopping"
          class="px-2 text-lg text-yellow-500 cursor-pointer hover:scale-110 transition duration-300 ease-in-out "
          (click)="onButtonClick($event)">
        </fa-icon>
      </div>
    </div>
  </div>

  <!-- Right side (Asset Image) -->
  <div *ngIf="type === 'right-asset'"
       class="flex w-auto items-center justify-center">
    <div
      [ngClass]="{'p-4 max-w-[150x] md:max-w-[350px]': size === 'small',
                  'p-1 max-w-[300px] md:max-w-[500px]': size === 'large'}"
      class="bg-grey-100 text-white flex items-center justify-center w-auto h-full aspect-[1/1]">
      <img [src]="featuredAsset | assetPreview:'large'"
           alt="{{ itemName }}"
           class="w-full h-full object-cover rounded-md">
    </div>
  </div>
</a>