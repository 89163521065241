import { ChangeDetectorRef, Component, Inject, makeStateKey, OnDestroy, OnInit, Optional, PLATFORM_ID, TransferState } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { APP_BASE_HREF } from '@angular/common';
import {
  Asset,
  GetActiveOrderQuery,
  GetChannelInfoQuery,
  GetStoreInfoQuery,
  GetStoreInfoQueryVariables,
  Channel
} from './common/generated-types';
import { GET_STORESITE_INFO } from './common/graphql/documents.graphql';
import { DataService } from './core/providers/data/data.service';
import { StateService } from './core/providers/state/state.service';
import { register } from 'swiper/element/bundle';
import { GET_ACTIVE_ORDER } from './core/providers/active/active.service.graphql';
import { NGXLogger } from 'ngx-logger';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { environment } from '../environments/environment';
import { notNullOrUndefined } from './common/utils/not-null-or-undefined';
import { safeJSONParse } from './common/utils/safe-json-parser';
import { ColorConfig, LogoSectionData, NavMenuOption, PolicyContentData, SnsInfo } from './common/interfaces';
import { Title } from '@angular/platform-browser';
import { GET_CHANNEL_INFO } from './common/graphql/documents.graphql';
import { DEFAULT_HIDE_ACCOUNT_VALUE } from './common/constants';



register();
declare global {
  interface Window {
    displayPreferenceModal: () => void;
  }
}

const DEFAULT_CHANNEL_STORE_NAME = 'DealOmega';
const IS_MOBILE_KEY = makeStateKey<boolean>('IS_MOBILE');
const STORE_IDENTIFIER_KEY = makeStateKey<string>('STORE_IDENTIFIER');
const STORE_INFO_KEY = makeStateKey<object>('STORE_INFO');
const SHOP_HOST_KEY = makeStateKey<string>('SHOP_HOST');

interface StoreInfo {
  name: string;
  identifier: string;
  sellerChannelId: string;
  email: string;
  snsInfo: SnsInfo | null;
  sellerChannel: Channel | null;
}

interface PageMessages {
  headerMessage: string;
  disclaimer: string;
}

interface StorePolicies {
  policyContentList: PolicyContentData[];
  useReturnPolicy: boolean;
  useShippingPolicy: boolean;
}

interface UIState {
  isForStore: boolean;
  isSearchBarExpanded: boolean;
  isNavExpanded: boolean;
  isMobile: boolean | null;
  displayHeaderMenu: boolean;
  displayFooterMenu: boolean;
}

interface NavigationItem {
  name: string;
  type: string;
  href?: string | null;
  href$?: Observable<string> | null;
}

interface Navigation {
  services: NavigationItem[];
  orders: NavigationItem[];
  company: NavigationItem[];
}

@Component({
  selector: 'vsf-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  // Observables
  cartDrawerVisible$: Observable<boolean>;
  mobileNavVisible$: Observable<boolean>;
  slugOrIdentifier$: Observable<{ type: string; slug: string }>;
  returnRequestForm$: Observable<boolean>;
  showReturnForm: boolean | undefined = false;
  channelInfo$: any;
  readyToDisplay$ = new BehaviorSubject<boolean>(false);
  // Direct properties
  storeInfo: StoreInfo = {
    name: '',
    identifier: '',
    sellerChannelId: '',
    sellerChannel: null,
    email: '',
    snsInfo: null,
  };
  pageMessages: PageMessages = {
    headerMessage: '',
    disclaimer: '',
  };
  storeColor: ColorConfig | null = null;
  storeLogoConfig: LogoSectionData = {
    pageTitle: '',
    displayHeaderMenu: true,
    displayFooterMenu: true,
    normalLogo: null,
    smallLogo: null,
    nameLogo: null,
    displayName: true,
    browserIcon: null,
  };
  storeNavMenu: NavMenuOption[] = [];
  uiState: UIState = {
    isForStore: false,
    displayHeaderMenu: true,
    displayFooterMenu: true,
    isSearchBarExpanded: false,
    isNavExpanded: false,
    isMobile: null,
  };
  navigation: any;
  baseUrl:string|null = null;
  hideAccount = DEFAULT_HIDE_ACCOUNT_VALUE;
  navigationColumns: [];
  policyContentList: [];
  orderStatusHref: string = '';

  private static readonly supportEmail = 'support@dealomega.com';
  // vimeo fullscreen preview
  fullscreenPreviewOpen$: Observable<number | null>;
  vimeoVideoId: number | null = null;
  currentStoreIdentifier: string = '';
  storeIdentifier$: Observable<string | null>;

  constructor(
    private router: Router,
    private stateService: StateService,
    private dataService: DataService,
    private changeDetector: ChangeDetectorRef,
    private titleService: Title,
    private logger: NGXLogger,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document,
    @Optional() @Inject('IS_MOBILE') private isMobileDeviceFromSSR: boolean | null,
    @Optional() @Inject('STORE_IDENTIFIER') public storeIdentifierFromSSR: string | null,
    @Optional() @Inject('SHOP_HOST') public shopHost: string | null
  ) {
    this.baseUrl = '';
    this.hideAccount = typeof environment.hideAccount === 'boolean' ? environment.hideAccount : DEFAULT_HIDE_ACCOUNT_VALUE;
  }

  async ngOnInit(): Promise<void> {
    this.logger.debug('AppComponent - ngOnInit', this.storeIdentifierFromSSR, this.isMobileDeviceFromSSR);
    this.cartDrawerVisible$ = this.stateService.select(state => state.cartDrawerOpen);
    const routeEvent$ = this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        take(1)
      );
    await routeEvent$.subscribe(event => {
        const currentPath = event.urlAfterRedirects;
        this.logger.debug(`AppComponent - currentPath: ${currentPath} url: ${event.url}`);
        if (currentPath.includes('return-form?info=')) {
          this.showReturnForm = true;
          return;
        } else {
          this.showReturnForm = false;
        }
    });
    if(this.showReturnForm) {
        return;
    }
    this.stateService.setState('storePath', this.baseUrl || '');
    if (isPlatformServer(this.platformId)) {
      this.transferState.set(STORE_IDENTIFIER_KEY, this.storeIdentifierFromSSR || '');
      this.transferState.set(IS_MOBILE_KEY, (this.isMobileDeviceFromSSR !== undefined) ? this.isMobileDeviceFromSSR : true);
      this.transferState.set(SHOP_HOST_KEY, this.shopHost);
      this.currentStoreIdentifier = this.storeIdentifierFromSSR || '';
      this.uiState.isMobile = (this.isMobileDeviceFromSSR === undefined) ? true : this.isMobileDeviceFromSSR;
      this.stateService.setState('storeIdentifier', this.currentStoreIdentifier);
      this.logger.debug('AppComponent - [from server]:', this.storeIdentifierFromSSR, this.uiState.isMobile);
    } else {
      this.uiState.isMobile = this.transferState.get(IS_MOBILE_KEY, true);
      const storeIdentifierFromTransferState = this.transferState.get(STORE_IDENTIFIER_KEY, '');
      this.shopHost = this.transferState.get(SHOP_HOST_KEY, environment.shopHost);
      this.currentStoreIdentifier = storeIdentifierFromTransferState;
      this.logger.debug('AppComponent - [from transferState]:', storeIdentifierFromTransferState, this.uiState.isMobile);
      this.stateService.setState('storeIdentifier', storeIdentifierFromTransferState);
      this.transferState.remove(STORE_IDENTIFIER_KEY);
      this.transferState.remove(IS_MOBILE_KEY);
      this.transferState.remove(SHOP_HOST_KEY);
    }

    this.stateService.setState('isMobile', this.uiState.isMobile);
    this.stateService.setState('shopHost', this.shopHost || environment.shopHost);

    this.logger.debug(`AppComponent - storeIdentifier: ${this.currentStoreIdentifier} isMobile: ${this.uiState.isMobile} baseUrl: ${this.baseUrl}`);
    if (this.currentStoreIdentifier !== '') {
      this.uiState.isForStore = true;
      if (isPlatformServer(this.platformId)) {
        this.dataService.query<GetStoreInfoQuery, GetStoreInfoQueryVariables>(
          GET_STORESITE_INFO, { identifier: this.currentStoreIdentifier }
        ).pipe(take(1)).subscribe(result => {
          this.updateHeaderInfo('store', result.storeSite);
          this.transferState.set(STORE_INFO_KEY, result.storeSite);
          this.stateService.setState('storeInfo', result.storeSite);
        });
      } else {
        const storeInfo = this.transferState.get(STORE_INFO_KEY, {}) as any;
        this.stateService.setState('storeInfo', storeInfo);
        if(storeInfo?.headerMessage) {
          this.stateService.setState('hasTopMessage', true);
        } else {
          this.stateService.setState('hasTopMessage', false);
        }
        this.updateHeaderInfo('store', storeInfo);
      }
    } else {
      this.uiState.isForStore = false;
      if (isPlatformServer(this.platformId)) {
        this.channelInfo$ = this.dataService.query<GetChannelInfoQuery>(GET_CHANNEL_INFO)
          .pipe(map(result => result.activeChannel));
        this.channelInfo$.pipe(take(1)).subscribe((channel: any) => {
          this.updateHeaderInfo('channel', channel);
          this.transferState.set(STORE_INFO_KEY, channel);
        });
      } else {
        const channelInfo = this.transferState.get(STORE_INFO_KEY, {});
        this.channelInfo$ = of(channelInfo);
        this.stateService.setState('storeInfo', channelInfo);
        this.updateHeaderInfo('channel', channelInfo);
      }
    }
    this.loadClarityScript(environment.clarityTrackingId);
    // get the vimeo video id from the state
    this.fullscreenPreviewOpen$ = this.stateService.select(state => state.fullscreenPreviewOpen)
      .pipe(switchMap(id => {
        this.vimeoVideoId = id;
        return of(id);
    }));

  }

  private updateHeaderInfo(type: string, data: any): void {
    this.logger.debug(`${type.charAt(0).toUpperCase() + type.slice(1)} Header Info:${JSON.stringify(data)}`);
    if (type === 'channel') {
      this.setChannelData(data);
    } else if (type === 'store') {
      this.setStoreData(data);
    }
    this.uiState.isForStore = !!this.storeInfo.identifier;
    this.uiState.displayHeaderMenu = (this.storeLogoConfig?.displayHeaderMenu === false) ? false : true;
    this.uiState.displayFooterMenu = (this.storeLogoConfig?.displayFooterMenu === false) ? false : true;
    this.storeLogoConfig?.browserIcon && this.setFavicon(this.storeLogoConfig.browserIcon);
    if (this.storeLogoConfig?.pageTitle) {
      this.titleService.setTitle(this.storeLogoConfig?.pageTitle);
    }
    this.setupOrderStatusHref();
    this.readyToDisplay$.next(true);
  }

  private setChannelData(data: GetChannelInfoQuery['activeChannel']): void {
    // If type is 'channel', we directly use the data from channel.customFields
    this.storeInfo = {
      name: DEFAULT_CHANNEL_STORE_NAME,
      identifier: '',
      sellerChannelId: '',
      sellerChannel: null,
      email: '',
      snsInfo: null,
    };
    this.pageMessages.headerMessage = data?.customFields?.HeaderMessage || '';
    this.pageMessages.disclaimer = data?.customFields?.StoreDisclaimer || '';
    if (data?.logoSection) {
      this.storeLogoConfig.normalLogo = data.logoSection.normalLogo as Asset;
      this.storeLogoConfig.smallLogo = data.logoSection.smallLogo as Asset || null;
      this.storeLogoConfig.nameLogo = data.logoSection.nameLogo as Asset || null;
      this.storeLogoConfig.browserIcon = data.logoSection.browserIcon as Asset || null;
      this.storeLogoConfig.displayName = (data.logoSection.displayName !== undefined) ? data.logoSection.displayName : true;
    } else {
      this.storeLogoConfig.normalLogo = data?.customFields?.StoreLogo as Asset || null;
      this.storeLogoConfig.smallLogo = data?.customFields?.StoreSmallLogo as Asset || null;
      this.storeLogoConfig.nameLogo = data?.customFields?.StoreNameLogo as Asset || null;
      this.storeLogoConfig.browserIcon = data?.customFields?.StoreBrowserIcon as Asset || null;
      this.storeLogoConfig.displayName = true;
    }
    this.storeColor = null;
    this.storeNavMenu = [];
  }

  private setStoreData(data: any): void {

    const CHANNEL_TOKEN = 'channel_token';
    if (isPlatformBrowser(this.platformId)) {
      if (data?.sellerChannel) {
        localStorage.setItem(CHANNEL_TOKEN, data?.sellerChannel?.token);
      }
    }

    this.storeInfo.name = data?.name || '';
    this.storeInfo.identifier = data?.identifier || '';
    this.storeInfo.email = data?.seller?.customFields?.email || '';
    this.storeInfo.sellerChannelId = data?.sellerChannelId || '';
    this.pageMessages.headerMessage = data?.headerMessage || '';
    this.storeColor = safeJSONParse<ColorConfig>(data?.colorConfig, this.logger) || null;
    this.storeInfo.snsInfo = safeJSONParse<SnsInfo>(data?.snsInfoSection, this.logger) || null;
    const navMenuJson = data?.navigationMenu || [];
    this.storeNavMenu = navMenuJson
      .map((menuJson: string) => safeJSONParse<NavMenuOption>(menuJson, this.logger))
      .filter(notNullOrUndefined);
    const policyContentListJson = data?.policyContentList || [];
    if (policyContentListJson.length > 0) {
      this.policyContentList = policyContentListJson
      .map((jsonString: string) => safeJSONParse<PolicyContentData>(jsonString, this.logger))
      .filter(notNullOrUndefined);
    }
    const logoSection = data?.logoSectionData;
    if (logoSection) {
      this.storeLogoConfig.displayHeaderMenu = (logoSection.displayHeaderMenu === false) ? false : true;
      this.storeLogoConfig.displayFooterMenu = (logoSection.displayFooterMenu === false) ? false : true;
      this.storeLogoConfig.normalLogo = logoSection.normalLogo as Asset;
      this.storeLogoConfig.smallLogo = logoSection.smallLogo as Asset;
      this.storeLogoConfig.nameLogo = logoSection.nameLogo as Asset;
      this.storeLogoConfig.browserIcon = logoSection.browserIcon as Asset;
      this.storeLogoConfig.displayName = (logoSection.displayName !== undefined) ? logoSection.displayName : true;
      this.storeLogoConfig.pageTitle = logoSection.pageTitle || '';
      this.logger.debug(
        `storeLogo: ${this.storeLogoConfig.normalLogo}, storeSmallLogo: ${this.storeLogoConfig.smallLogo}, storeNameLogo: ${this.storeLogoConfig.nameLogo}`
      );
    }
    if(data?.footerNavigationColumns?.length > 0) {
      this.navigationColumns = data?.footerNavigationColumns?.map((json:string) => safeJSONParse(json, this.logger));
    }
  }

  private setupOrderStatusHref(): void {
    this.stateService.select(state => state.signedIn).pipe(
      takeUntil(this.destroy$),
      map(isSignedIn => isSignedIn ? `${this.baseUrl}/account/orders` : `${this.baseUrl}/orderstatus`)
    ).subscribe(href => {
      this.orderStatusHref = href
      this.changeDetector.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setFavicon(asset: Asset) {
    const link: HTMLLinkElement | null = this.document.querySelector('#app-favicon');
    if (link) {
      link.href = asset.source;
    }
  }

  openCartDrawer() {
    this.readyToDisplay$.pipe(
      filter(ready => ready),
      take(1),
      switchMap(() => this.dataService.query<GetActiveOrderQuery>(GET_ACTIVE_ORDER, {}, 'network-only')),
      map(order => order?.activeOrder?.state),
      take(1)
    ).subscribe(orderState => {
      if (orderState !== null) {
        if (orderState === 'ArrangingPayment') {
          this.router.navigate([this.baseUrl, 'cart']);
        } else {
          this.stateService.setState('cartDrawerOpen', true);
        }
      }
    });
  }

  closeCartDrawer() {
    this.stateService.setState('cartDrawerOpen', false);
  }

  clickOnSearchButton() {
    this.uiState.isSearchBarExpanded = !this.uiState.isSearchBarExpanded;
    this.changeDetector.detectChanges();
  }

  clickOnNavButton() {
    this.uiState.isNavExpanded = !this.uiState.isNavExpanded;
    this.changeDetector.detectChanges();
  }

  toggleMobileMenu() {
    this.uiState.isNavExpanded = !this.uiState.isNavExpanded;
    if (this.uiState.isNavExpanded) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    this.changeDetector.detectChanges();
  }

  closeMobileMenu() {
    this.uiState.isNavExpanded = false;
    document.body.style.overflow = '';
    this.changeDetector.detectChanges();
  }

  scrollToSection(sectionId: string) {
    const element = this.document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  navigateToPage(navMenuOption: NavMenuOption) {
    this.logger.debug(`navigateToPage:${navMenuOption}`);
    switch (navMenuOption.type) {
      case 'home-page':
        this.router.navigate([this.baseUrl]);
        break;
      case 'shopping-list':
        this.router.navigate([this.baseUrl, 'shopping']);
        break;
      case 'about-page':
        this.router.navigate([this.baseUrl, 'about']);
        break;
      case 'faq-page':
        this.router.navigate([this.baseUrl, 'faq']);
        break;
      case 'contact-page':
        this.router.navigate([this.baseUrl, 'contact']);
        break;
      case 'other-page':
        this.router.navigate([navMenuOption.url]);
        break;
    }
  }

  private loadClarityScript(trackingId: string): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const logger = this.logger;
    (function (c: any, l: Document, a: string, r: string, i: string) {
      c[a] = c[a] || function () {
        (c[a].q = c[a].q || []).push(arguments);
      };

      const t = l.createElement(r) as HTMLScriptElement;
      t.async = true;
      t.src = "https://www.clarity.ms/tag/" + i;

      const y = l.getElementsByTagName(r)[0];
      if (y && y.parentNode) {
        y.parentNode.insertBefore(t, y);
        logger.debug('loadClarityScript done');
      }
    })(window, document, "clarity", "script", trackingId);
  }
}


