import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { merge, Observable } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { animate, state, style, transition, trigger } from '@angular/animations';

import {
    Discount,
    GetActiveOrderQuery,
    Order,
} from '../../../common/generated-types';
import { StateService } from '../../providers/state/state.service';

import { ActiveService } from '../../providers/active/active.service';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { CombinedAnalyticsService } from '../../providers/analytics/combined-analytics.service';
import { CartService } from '../../providers/cart/cart.service';

@Component({
    selector: 'vsf-cart-drawer',
    templateUrl: './cart-drawer.component.html',
    styleUrls: ['./cart-drawer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('expandCollapse', [
          state('collapsed', style({ height: '0', overflow: 'hidden' })),
          state('expanded', style({ height: '*' })),
          transition('collapsed <=> expanded', animate('300ms ease-in-out'))
        ])
    ]
})
export class CartDrawerComponent implements OnInit {
    @Input() visible = false;
    @Input() storeIdentifier: string = '';
    @Output() close = new EventEmitter<void>();
    @ViewChild('overlay') private overlayRef: ElementRef<HTMLDivElement>;

    cart$: Observable<GetActiveOrderQuery['activeOrder']>;
    isEmpty$: Observable<boolean>;
    baseUrl = '';
    constructor(private stateService: StateService,
                private activeService: ActiveService,
                private router: Router,
                private analyticsService: CombinedAnalyticsService,
                private logger: NGXLogger,
                private cartService: CartService ) {}

    ngOnInit() {
        this.cart$ = merge(
            this.stateService.select(state => state.activeOrderId),
            this.stateService.select(state => state.signedIn),
        ).pipe(
            switchMap(() => this.activeService.activeOrder$),
            shareReplay(1),
        );
        this.isEmpty$ = this.cart$.pipe(
            map(cart => !cart || cart.lines.length === 0 || cart.active === false),
        );
        this.stateService.select(state => state.storePath).pipe(take(1)).subscribe(storePath => {
            this.baseUrl = storePath;
        });
    }

    beginCheckoutEvent(order: GetActiveOrderQuery['activeOrder'], callback:()=>void) {
        try {
            this.analyticsService.beginCheckout(order as Order, callback);
        } catch (err){
            this.logger.error('Error in beginCheckoutEvent', err);
            callback();
        }
    }

    calculateCurrentTotalDiscount(order: GetActiveOrderQuery['activeOrder']): number {
        let totalDiscount = 0;
        if(order && order.discounts && order.discounts.length > 0) {
            order.discounts.forEach((discount: Discount) => {
                if(discount?.amountWithTax) {
                    totalDiscount += discount?.amountWithTax || 0;
                }
            });
        }
        return totalDiscount;
    }

    async continueToCheckout() {
        this.cart$.pipe(take(1)).subscribe(async (order) => {
            if(order) {
                this.beginCheckoutEvent(order, () => {
                    this.router.navigate([this.baseUrl, 'checkout']);
                    this.close.emit();
                });
            }
        });
    };

    setQuantity(event: { itemId: string; quantity: number; }) {
        if (event.quantity > 0) {
            this.cartService.adjustItemQuantity(event.itemId, event.quantity).pipe(take(1)).subscribe();
        } else {
            this.cartService.removeItem(event.itemId).pipe(take(1)).subscribe();
        }
    }

    overlayClick(event: MouseEvent) {
        if (event.target === this.overlayRef.nativeElement) {
            this.close.emit();
        }
    }
}
