import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { merge, Observable } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';

import {
    GetActiveOrderQuery,
    Order
} from '../../../common/generated-types';
import { StateService } from '../../providers/state/state.service';
import { ActiveService } from '../../providers/active/active.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { CombinedAnalyticsService } from '../../providers/analytics/combined-analytics.service';
import { NGXLogger } from 'ngx-logger';
import { CartService } from '../../providers/cart/cart.service'; // Import the updated CartService

@Component({
    selector: 'vsf-cart-page',
    templateUrl: './cart-page.component.html',
    styleUrls: ['./cart-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('expandCollapse', [
          state('collapsed', style({ height: '0', overflow: 'hidden' })),
          state('expanded', style({ height: '*' })),
          transition('collapsed <=> expanded', animate('300ms ease-in-out'))
        ])
    ]
})
export class CartPageComponent implements OnInit {

    cart$: Observable<GetActiveOrderQuery['activeOrder']>;
    isEmpty$: Observable<boolean>;

    constructor(private stateService: StateService,
                private activeService: ActiveService,
                private router: Router,
                private route: ActivatedRoute,
                private analyticsService: CombinedAnalyticsService,
                private logger: NGXLogger,
                private cartService: CartService) {}

    ngOnInit() {
        this.cart$ = merge(
            this.stateService.select(state => state.activeOrderId),
            this.stateService.select(state => state.signedIn),
        ).pipe(
            switchMap(() => this.activeService.activeOrder$),
            shareReplay(1),
        );
        this.isEmpty$ = this.cart$.pipe(
            map(cart => !cart || cart.lines.length === 0 || cart.active === false),
        );
    }

    beginCheckoutEvent(order: GetActiveOrderQuery['activeOrder'], callback:()=>void) {
        if(order) {
            try {
                this.analyticsService.beginCheckout(order as Order, callback);
            } catch (err){
                this.logger.error('Error in beginCheckoutEvent', err);
                callback();
            }
        }
    }

    calculateCurrentTotalDiscount(order: GetActiveOrderQuery['activeOrder']): number {
        let totalDiscount = 0;
        if(order && order.discounts && order.discounts.length > 0) {
            order.discounts.forEach(discount => {
                if(discount?.amountWithTax) {
                    totalDiscount += discount?.amountWithTax || 0;
                }
            });
        }
        return totalDiscount;
    }

    async continueToCheckout() {
        this.cart$.pipe(take(1)).subscribe(async (order) => {
            this.beginCheckoutEvent(order, () => {
                this.router.navigate(['../checkout'], { relativeTo: this.route });
            });
        });
    };

    setQuantity(event: { itemId: string; quantity: number; }) {
        if (event.quantity > 0) {
            this.cartService.adjustItemQuantity(event.itemId, event.quantity).pipe(take(1)).subscribe();
        } else {
            this.cartService.removeItem(event.itemId).pipe(take(1)).subscribe();
        }
    }
}
