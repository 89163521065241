import { Inject, Injectable, makeStateKey, Optional, PLATFORM_ID, TransferState } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { NGXLogger } from 'ngx-logger';
import { notNullOrUndefined } from '../../common/utils/not-null-or-undefined';
import { safeJSONParse } from '../../common/utils/safe-json-parser';
import { PolicyContentData } from '../../common/interfaces';
import { GET_STORESITE_POLICY_LIST } from '../../common/graphql/documents.graphql';
import { GetStorePolicyListQuery, GetStorePolicyListQueryVariables } from '../../common/generated-types';
import { DataService } from './data/data.service';
import { isPlatformServer } from '@angular/common';

const POLICY_STORE_IDENTIFIER_KEY = makeStateKey<string>('POLICY_STORE_IDENTIFIER');
@Injectable({ providedIn: 'root' })
export class PolicyResolver implements Resolve<PolicyContentData | null> {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Optional() @Inject('STORE_IDENTIFIER') public storeIdentifierFromSSR: string | null,
    private dataService: DataService,
    private transferState: TransferState,
    private router: Router,
    private logger: NGXLogger
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const policyTypePath = route.paramMap.get('policy');
    if (!policyTypePath) {
      this.logger.error('PolicyResolver: No policy param found');
      this.router.navigateByUrl('/not-found', { skipLocationChange: true });
      return EMPTY;
    }
    let identifier = '';
    if (isPlatformServer(this.platformId)) {
      this.transferState.set(POLICY_STORE_IDENTIFIER_KEY, this.storeIdentifierFromSSR || '');
      identifier = this.storeIdentifierFromSSR || '';
    } else {
      identifier = this.transferState.get(POLICY_STORE_IDENTIFIER_KEY, '');
      this.transferState.remove(POLICY_STORE_IDENTIFIER_KEY);
    }

    return this.dataService.query<GetStorePolicyListQuery, GetStorePolicyListQueryVariables>(GET_STORESITE_POLICY_LIST, {
      identifier: identifier
    }).pipe(
      map(result => {
        const storeInfo = result?.storeSite;
        if (!storeInfo?.policyContentList) {
          this.logger.error(`PolicyResolver: policyContentList not available for '${policyTypePath}'`);
          this.router.navigateByUrl('/not-found', { skipLocationChange: true });
          return null;
        }

        const policyPageListJson = storeInfo.policyContentList;
        if (!policyPageListJson.length) {
          this.logger.error(`PolicyResolver: No policy pages found for policy '${policyTypePath}'`);
          this.router.navigateByUrl('/not-found', { skipLocationChange: true });
          return null;
        }

        const policyPageList = policyPageListJson
          .map((jsonString: any) => safeJSONParse<PolicyContentData>(jsonString, this.logger))
          .filter(notNullOrUndefined);

        const policyContent = policyPageList.find((p: any) => p?.path === policyTypePath) || null;
        if (!policyContent) {
          this.logger.error('PolicyResolver: Failed to find policy content for path:', policyTypePath);
          this.router.navigateByUrl('/not-found', { skipLocationChange: true });
          return null;
        }
        return policyContent;
      }),
      catchError((err) => {
        // In case of errors, also go to not-found
        this.logger.error(`PolicyResolver: Error ${err} fetching policy for store '${this.storeIdentifierFromSSR}'`);
        this.router.navigateByUrl('/not-found', { skipLocationChange: true });
        return of(null);
      })
    );
  }
}