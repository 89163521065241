import { ChangeDetectorRef, Component, Renderer2, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, fromEvent, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { bufferTime, distinctUntilChanged, filter, map, mapTo, scan, share, shareReplay, skip, switchMap, take, takeUntil, tap, } from 'rxjs/operators';

import {
    GetCollectionQuery,
    GetCollectionQueryVariables,
    SearchProductsQuery,
    SearchProductsQueryVariables
} from '../../../common/generated-types';
import { AssetPreviewPipe } from '../../../shared/pipes/asset-preview.pipe';
import { DataService } from '../../providers/data/data.service';
import { StateService } from '../../providers/state/state.service';
import { FacetFilters } from '../product-list-controls/product-list-controls.component';
import { GET_COLLECTION, SEARCH_PRODUCTS } from '../../../common/graphql/documents.graphql';
import { isPlatformBrowser } from '@angular/common';
import { safeJSONParse } from '../../../common/utils/safe-json-parser';
import { NGXLogger } from 'ngx-logger';
import { CollectionItemOrderData } from '../../../common/interfaces';
import { notNullOrUndefined } from '../../../common/utils/not-null-or-undefined';
import { CollectionItemType } from '../../../common/enums';
import { DESKTOP_HEADER_HEIGHT, DESKTOP_PRODUCT_LIST_FILTER_HEIGHT, DESKTOP_TOP_MESSAGE_HEIGHT, MOBILE_HEADER_HEIGHT } from '../../../common/constants';

type SearchItem = SearchProductsQuery['search']['items'][number];

@Component({
    selector: 'vsf-product-list',
    templateUrl: './product-list.component.html',
    styleUrls: ['./product-list.component.scss'],
    })
export class ProductListComponent implements OnInit, OnDestroy {
    @Input() storeSiteCollectionSlug?: string;
    @Input() channelId?: string;
    private static readonly INITIAL_ITEMS_PER_PAGE = 16;
    private static readonly ITEMS_PER_PAGE = 64;
    private static readonly SCROLL_SUBSCRIPTION_BUFFER_TIME = 250;
    private static readonly FLOATING_PANEL_DISPLAY_THRESHOLD = 150;
    private static readonly BACKGROUD_IMAGE_WIDTH = 1000;
    private static readonly BACKGROUD_IMAGE_HEIGHT = 300;
    desktopHeaderHeight = DESKTOP_HEADER_HEIGHT;
    desktopTopMessageHeight = DESKTOP_TOP_MESSAGE_HEIGHT;
    mobileHeaderHeight = MOBILE_HEADER_HEIGHT;
    desktopFilterHeight = DESKTOP_PRODUCT_LIST_FILTER_HEIGHT;

    products$: Observable<SearchItem[]>;
    totalResults$: Observable<number>;
    collection$: Observable<GetCollectionQuery['collection']>;
    facetValues: SearchProductsQuery['search']['facetValues'];
    unfilteredTotalItems = 0;
    activeFacetValueIds$: Observable<FacetFilters>;
    searchTerm$: Observable<string>;
    displayLoadMore$: Observable<boolean>;
    loading$: Observable<boolean>;
    breadcrumbs$: Observable<Array<{id: string; name: string; }>>;
    mastheadBackground$: Observable<SafeStyle>;
    facetMenuVisible = true;
    isMobile: boolean | null = null;
    hasTopMessage = true;
    productFilterTopOffset = 0;
    productFacetMenuTopOffset = 0;

    showFloatingPanel = false;
    isHeaderVisible = true;
    private scrollSubscription: Subscription;
    private originalControlPanel: HTMLElement | null = null;
    private productListPanel: HTMLElement | null = null;
    private currentPage = 0;
    private refresh = new BehaviorSubject<void>(undefined);
    private sortBy = new BehaviorSubject<string>('');
    readonly placeholderProducts = Array.from({ length: 12 }).map(() => null);
    private destroy$: Subject<void> = new Subject<void>();
    private productIdsOrder: string[] = [];

    constructor(private dataService: DataService,
                private route: ActivatedRoute,
                private stateService: StateService,
                private changeDetector: ChangeDetectorRef,
                private renderer: Renderer2,
                @Inject(PLATFORM_ID) private platformId: object,
                private sanitizer: DomSanitizer,
                private logger: NGXLogger) { }

    async ngOnInit() {
        const isMobile$ = this.stateService.select(state => state.isMobile);
        const isHeaderFloating$ = this.stateService.select(state => state.isHeaderFloating);
        const hasTopMessage$ = this.stateService.select(state => state.hasTopMessage);
        await combineLatest([isMobile$, hasTopMessage$])
        .pipe(take(1)).subscribe(([isMobile, hasTopMessage]) => {
            this.isMobile = isMobile;
            this.hasTopMessage = hasTopMessage;
            this.changeDetector.markForCheck();
        });
        isHeaderFloating$.pipe(takeUntil(this.destroy$))
        .subscribe(isHeaderFloating => {
            if(this.isHeaderVisible !== isHeaderFloating) {
                this.isHeaderVisible = isHeaderFloating;
                this.calculateTopOffset();
                this.changeDetector.markForCheck();
            }
        });
        this.facetMenuVisible = !this.isMobile;
        const collectionSlug$ = this.storeSiteCollectionSlug
        ? of(this.storeSiteCollectionSlug)
        : this.route.paramMap.pipe(
            map(pm => pm.get('slug')),
            distinctUntilChanged(),
            tap(slug => {
                this.stateService.setState('lastCollectionSlug', slug || null);
                this.currentPage = 0;
            }),
            shareReplay(1),
        );
        this.activeFacetValueIds$ = this.route.queryParamMap.pipe(
            map(pm => {
                const facetParam = pm.get('facets');
                const facetFilters: FacetFilters = {};
                if (facetParam) {
                    const facetGroups = facetParam.split(';');
                    facetGroups.forEach(group => {
                        const [id, values] = group.split(':');
                        facetFilters[id] = values.split(',');
                    });
                }
                return facetFilters;
            }),
            distinctUntilChanged(),
            tap(() => {
                this.currentPage = 0;
            }),
            shareReplay(1),
        );
        this.searchTerm$ = this.route.queryParamMap.pipe(
            map(pm => pm.get('search') || ''),
            distinctUntilChanged(),
            shareReplay(1),
        );

        this.collection$ = collectionSlug$.pipe(
            switchMap(slug => {
                if (slug) {
                    return this.dataService.query<GetCollectionQuery, GetCollectionQueryVariables>(GET_COLLECTION, {
                        slug,
                    }).pipe(
                        map(data => data.collection),
                    );
                } else {
                    return of(undefined);
                }
            }),
            shareReplay(1),
        );

        const assetPreviewPipe = new AssetPreviewPipe();

        this.mastheadBackground$ = this.collection$.pipe(
            map(c => `url(${assetPreviewPipe.transform(
                    c?.featuredAsset || undefined,
                    ProductListComponent.BACKGROUD_IMAGE_WIDTH,
                    ProductListComponent.BACKGROUD_IMAGE_HEIGHT
                )})`
            ),
            map(style => this.sanitizer.bypassSecurityTrustStyle(style)),
        );

        this.breadcrumbs$ = this.collection$.pipe(
            map(collection => {
                if (collection) {
                    return collection.breadcrumbs;
                } else {
                    return [{
                        id: '',
                        name: 'Home',
                    }, {
                        id: '',
                        name: 'Search',
                    }];
                }
            }),
        );

        const triggerFetch$ = combineLatest([
            this.collection$,
            this.activeFacetValueIds$,
            this.searchTerm$,
            this.sortBy,
            this.refresh
        ]);
        const getInitialFacetValueIds = () => {
            combineLatest(this.collection$, this.searchTerm$).pipe(
                take(1),
                switchMap(([collection, term]) => {
                    return this.dataService.query<SearchProductsQuery, SearchProductsQueryVariables>(SEARCH_PRODUCTS, {
                        input: {
                            term,
                            groupByProduct: true,
                            collectionId: collection?.id,
                            take: this.getTake(),
                            skip: this.getSkip(),
                        },
                    });
                }),
                takeUntil(this.destroy$)
                ).subscribe(data => {
                    this.facetValues = data.search.facetValues;
                    this.unfilteredTotalItems = data.search.totalItems;
                });
        };
        this.loading$ = merge(
            triggerFetch$.pipe(mapTo(true)),
        );
        const queryResult$ = triggerFetch$.pipe(
            switchMap(([collection, facetValueIds, term, sort]) => {
                if(collection) {
                    const collectionItemOrder = safeJSONParse<CollectionItemOrderData>(collection.customFields?.itemOrderData, this.logger);
                    if (collectionItemOrder?.orderType === CollectionItemType.Product) {
                        this.productIdsOrder = collectionItemOrder.productIdsInOrder;
                    }
                }
                return this.dataService.query<SearchProductsQuery, SearchProductsQueryVariables>(SEARCH_PRODUCTS, {
                    input: {
                        term,
                        groupByProduct: true,
                        channelId: this.channelId,
                        collectionId: collection?.id,
                        facetValueFilters: Object.keys(facetValueIds)?.map(key => { return { or: facetValueIds[key] }; }),
                        take: this.getTake(),
                        skip: this.getSkip(),
                        sort: this.getSortParams(sort),
                    },
                }).pipe(
                    tap(data => {
                        if (Object.keys(facetValueIds).length === 0) {
                            this.facetValues = data.search.facetValues;
                            this.unfilteredTotalItems = data.search.totalItems;
                        } else if (!this.facetValues) {
                            getInitialFacetValueIds();
                        } else {
                            this.facetValues = this.facetValues.map(fv => fv);
                        }
                    }),
                );
            }),
            shareReplay(1),
        );

        this.loading$ = merge(
            triggerFetch$.pipe(mapTo(true)),
            queryResult$.pipe(mapTo(false)),
        );

        const RESET = 'RESET';
        const items$ = this.products$ = queryResult$.pipe(
            map(data => {
                const products = data.search.items;
                if (this.productIdsOrder && this.productIdsOrder.length > 0 && this.sortBy.value === '') {
                    const orderedProducts = this.productIdsOrder
                        .map(id => products.find(product => product.productId === id))
                        .filter(notNullOrUndefined);
                    const remainingProducts = products.filter(product =>
                        this.productIdsOrder && !this.productIdsOrder.includes(product.productId)
                    );
                    return [...orderedProducts, ...remainingProducts];
                }
                return products;
            })
        );

        const reset$ = merge(collectionSlug$, this.activeFacetValueIds$, this.searchTerm$).pipe(
            mapTo(RESET),
            skip(1),
            share(),
        );
        this.products$ = merge(items$, reset$).pipe(
            scan<SearchItem[] | string, SearchItem[]>((acc, val) => {
                if (typeof val === 'string') {
                    return [];
                } else {
                    return this.currentPage === 0 ? val : acc.concat(val);
                }
            }, [] as SearchItem[]),
        );
        this.totalResults$ = queryResult$.pipe(map(data => data.search.totalItems));
        this.displayLoadMore$ = combineLatest(this.products$, this.totalResults$).pipe(
            map(([products, totalResults]) => {
                return 0 < products.length && products.length < totalResults;
            }),
        );
        if (isPlatformBrowser(this.platformId)) {
            this.initScrollListener();
        }
    }

    ngOnDestroy() {
        if (this.scrollSubscription) {
            this.scrollSubscription.unsubscribe();
        }
        this.destroy$.next();
        this.destroy$.complete();
    }

    scrollToTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    calculateTopOffset() {
        if(this.showFloatingPanel) {
            if(this.isHeaderVisible) {
                if(this.isMobile) {
                    this.productFilterTopOffset = this.mobileHeaderHeight;
                } else {
                    if(this.hasTopMessage) {
                        this.productFilterTopOffset = this.desktopHeaderHeight + this.desktopTopMessageHeight;
                        this.productFacetMenuTopOffset = this.desktopHeaderHeight + this.desktopTopMessageHeight + this.desktopFilterHeight;
                    } else {
                        this.productFilterTopOffset = this.desktopHeaderHeight;
                        this.productFacetMenuTopOffset = this.desktopHeaderHeight + this.desktopFilterHeight;
                    }
                }
            } else {
                this.productFilterTopOffset = 0;
                this.productFacetMenuTopOffset = this.desktopFilterHeight;
            }
        } else {
            this.productFacetMenuTopOffset = 0;
        }
    }

    private initScrollListener() {
        // Wait for DOM to be ready
        setTimeout(() => {
            this.originalControlPanel = document.querySelector('.original-control-panel');
            this.productListPanel = document.querySelector('.product-list-panel');
            if (this.originalControlPanel && this.productListPanel) {
                this.scrollSubscription = fromEvent(window, 'scroll', { passive: true }).pipe(
                    map(() => window.scrollY),
                    bufferTime(ProductListComponent.SCROLL_SUBSCRIPTION_BUFFER_TIME),
                    filter(val => 1 < val.length),
                    map(val => ({
                        delta: val[val.length - 1] - val[0],
                        currentScroll: val[val.length - 1]
                    }))
                ).subscribe(({ delta, currentScroll }) => {
                    const rect = this.originalControlPanel?.getBoundingClientRect();
                    const productListRect = this.productListPanel?.getBoundingClientRect();
                    let showFloatingPanel = false;
                    if (rect && productListRect) {
                        showFloatingPanel = (rect.bottom < 0
                            && productListRect.bottom > ProductListComponent.FLOATING_PANEL_DISPLAY_THRESHOLD);
                    } else {
                        showFloatingPanel = false;
                    }
                    if(showFloatingPanel !== this.showFloatingPanel) {
                        this.showFloatingPanel = showFloatingPanel;
                        this.calculateTopOffset();
                        this.changeDetector.markForCheck();
                    }
                });
            }
        }, 0);
    }

    private getTake(): number {
        return this.currentPage === 0 ? ProductListComponent.INITIAL_ITEMS_PER_PAGE : ProductListComponent.ITEMS_PER_PAGE;
    }

    private getSkip(): number {
        return this.currentPage === 0
          ? 0
          : ProductListComponent.INITIAL_ITEMS_PER_PAGE + (this.currentPage - 1) * ProductListComponent.ITEMS_PER_PAGE;
    }

    trackByProductId(index: number, item: SearchItem) {
        return item.productId;
    }

    onSortChange(sortOption: string) {
        this.sortBy.next(sortOption);
        this.currentPage = 0;
        this.refresh.next();
    }

    private getSortParams(sort: string): { [key: string]: string } {
        switch (sort) {
            case 'price-high':
                return { price: 'DESC' };
            case 'price-low':
                return { price: 'ASC' };
            case 'name-z-to-a':
                return { name: 'DESC' };
            case 'name-a-to-z':
                return { name: 'ASC' };
            case 'time-newest':
                return { productId: 'DESC' };
            case 'time-oldest':
                return { productId: 'ASC' };
            default:
                return {};
        }
    }

    toggleFacetMenu() {
        this.facetMenuVisible = !this.facetMenuVisible;
        if (isPlatformBrowser(this.platformId)) {
            if (this.facetMenuVisible && this.isMobile) {
                this.renderer.addClass(document.body, 'no-scroll');
            } else {
                this.renderer.removeClass(document.body, 'no-scroll');
            }
        }
    }

    onBackdropClick() {
        this.toggleFacetMenu();
    }

    loadMore() {
        this.currentPage ++;
        this.refresh.next();
    }

}
