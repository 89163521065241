import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { PolicyContentData } from '../../common/interfaces';
import { StateService } from '../../core/providers/state/state.service';
import { CanonicalService } from '../../core/providers/canonical/canonical.service';

@Component({
  selector: 'vsf-store-policy-page',
  templateUrl: './store-policy-page.component.html',
  styleUrls: ['./store-policy-page.component.scss'],
})
export class StorePolicyPageComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  policyContent: PolicyContentData | null = null;
  canonicalURL = '';
  shopHost = '';

  constructor(private route: ActivatedRoute,
              private canonicalService: CanonicalService,
              private changeDetector: ChangeDetectorRef,
              private stateService: StateService) {
  }

  ngOnInit(): void {
    this.policyContent = this.route.snapshot.data['policyData'] as PolicyContentData;
    const storePath$ = this.stateService.select(state => state.storePath).pipe(take(1));
    const shopHost$ = this.stateService.select(state => state.shopHost).pipe(take(1));
    combineLatest([storePath$, shopHost$]).pipe(takeUntil(this.destroy$))
    .subscribe(([storePath, shopHost]) => {
        this.canonicalURL = `${storePath}/store-policy/${this.policyContent?.path}`;
        this.shopHost = shopHost;
        this.canonicalService.setCanonicalURL(`${this.shopHost}${this.canonicalURL}`);
        this.changeDetector.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.canonicalService.removeCanonicalURL();
  }
}