import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { notNullOrUndefined } from '../../common/utils/not-null-or-undefined';
import { Collection, GetStoreInfoQuery, ProductReview } from '../../common/generated-types';
import { NGXLogger } from 'ngx-logger';
import { safeJSONParse } from '../../common/utils/safe-json-parser';
import { CollectionGroupData, ColorConfig, EmailSubscriptionData, FeaturedCollectionData, LogoSectionData, LayoutOptionData, MarketingBannerData } from '../../common/interfaces';
import { Meta, Title } from '@angular/platform-browser';
import { StateService } from '../../core/providers/state/state.service';
import { stripHtml } from '../../common/utils/strip-html';
import { replaceHostname } from '../../common/utils/url-handlers';
import { CanonicalService } from '../../core/providers/canonical/canonical.service';

@Component({
  selector: 'vsf-store-main-page',
  templateUrl: './store-main-page.component.html',
  styleUrls: ['./store-main-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreMainPageComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroy$ = new Subject<void>();
  storeInfo: GetStoreInfoQuery['storeSite'];
  baseUrl: string;
  identifier: string;
  sellerChannelId: string;
  shopHost: string;

  layoutOrder: LayoutOptionData[];
  colorConfig: ColorConfig | null;

  heroSection: any | null;
  featuredProductReviewList: ProductReview[];
  emailSubscriptionSection: EmailSubscriptionData | null;

  marketingBanners: MarketingBannerData[];
  featuredCollectionList: FeaturedCollectionData[];
  collectionListForShopByGroupList: CollectionGroupData[];
  collectionForSeriesHeroSectionList: any[];

  isMobile: boolean | null = null;
  readyToDisplay$ = new BehaviorSubject<boolean>(false);

  constructor(private titleService: Title,
              private metaService: Meta,
              private changeDetectorRef: ChangeDetectorRef,
              private canonicalService: CanonicalService,
              @Inject(PLATFORM_ID) private platformId: object,
              private stateService: StateService,
              @Optional() @Inject('STORE_IDENTIFIER') public storeIdentifierFromSSR: string | null,
              private logger: NGXLogger) { }

  async ngOnInit() {
      const storeInfoSub = this.stateService.select(state => state.storeInfo).pipe(takeUntil(this.destroy$), filter(notNullOrUndefined));
      const isMobileSub = this.stateService.select(state => state.isMobile).pipe(takeUntil(this.destroy$), filter(notNullOrUndefined));
      const storePathSub = this.stateService.select(state => state.storePath).pipe(takeUntil(this.destroy$), filter(notNullOrUndefined));
      const shopHostSub = this.stateService.select(state => state.shopHost).pipe(takeUntil(this.destroy$), filter(notNullOrUndefined));
      await combineLatest([storeInfoSub, isMobileSub, storePathSub, shopHostSub]).pipe(takeUntil(this.destroy$)).subscribe(([storeInfo, isMobile, storePath, shopHost]) => {
        this.baseUrl = storePath;
        this.storeInfo = storeInfo;
        this.identifier = storeInfo?.identifier || '';
        this.isMobile = isMobile;
        this.shopHost = shopHost;
      });
      this.canonicalService.setCanonicalURL(`${this.shopHost}${this.baseUrl}`);
      this.updateStoreMetadata(this.storeInfo);
      this.sellerChannelId = this.storeInfo?.sellerChannelId || '';
      this.layoutOrder = (this.storeInfo?.layoutOrder || [])
      .map(jsonString => {
        const layoutOption =  safeJSONParse<LayoutOptionData>(jsonString, this.logger);
        if (layoutOption) {
          // Compute desktop width percentage
          layoutOption.widthPercentage = layoutOption.width && layoutOption.width > 0
            ? `${layoutOption.width * 100}%`
            : '100%';
          // Compute mobile width percentage
          layoutOption.mobileWidthPercentage = layoutOption.mobileWidth && layoutOption.mobileWidth > 0
            ? `${layoutOption.mobileWidth * 100}%`
            : '100%';
          // Ensure margin properties are numbers (fallback to 0 if undefined)
          layoutOption.topMargin = layoutOption.topMargin || 0;
          layoutOption.bottomMargin = layoutOption.bottomMargin || 0;
          layoutOption.mobileTopMargin = layoutOption.mobileTopMargin || 0;
          layoutOption.mobileBottomMargin = layoutOption.mobileBottomMargin || 0;
        }
        return layoutOption;
      })
      .filter(notNullOrUndefined);
      this.colorConfig = safeJSONParse<ColorConfig>(this.storeInfo?.colorConfig, this.logger);
      this.heroSection = this.storeInfo?.heroSectionData || null;
      this.featuredProductReviewList = this.storeInfo?.featuredProductReviewListData as ProductReview[] || [];
      this.emailSubscriptionSection = this.storeInfo?.emailSubscriptionData as EmailSubscriptionData;
      this.marketingBanners = this.storeInfo?.marketingBanners as any[] || [];
      this.featuredCollectionList = this.storeInfo?.featuredCollectionDataList as any[] || [];
      this.collectionListForShopByGroupList = this.storeInfo?.collectionGroupDataList as CollectionGroupData[] || [];
      this.collectionForSeriesHeroSectionList = this.storeInfo?.collectionForSeriesListData?.map(collection => { return collection.heroSection })  || [];
      this.computeLayoutStyles();
      this.readyToDisplay$.next(true);
      this.logger.info('Store Info', JSON.stringify(this.storeInfo));
      this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.canonicalService.removeCanonicalURL();
    this.removeMetadata();
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit() {
    this.changeDetectorRef.markForCheck();
  }

  @HostListener('window:resize', [])
  onResize() {
    this.computeLayoutStyles();
  }

  private updateStoreMetadata(store: any): void {
    if (!store) return;
    const heroSection = store?.heroSectionData;
    const logoConfig = safeJSONParse<LogoSectionData>(store?.logoSection, this.logger);
    const titleHtml = heroSection?.title || store.name;
    const descriptionHtml = heroSection?.subtitle || '';
    const title = stripHtml(titleHtml, this.platformId);
    const description = stripHtml(descriptionHtml, this.platformId);
    // Set the page title
    if (logoConfig?.pageTitle) {
      this.titleService.setTitle(logoConfig?.pageTitle);
    } else {
      this.titleService.setTitle(`${title}`);
    }
    // Set meta description
    this.metaService.updateTag({ name: 'description', content: description });
    // Set Open Graph meta tags
    this.metaService.updateTag({ property: 'og:title', content: title });
    this.metaService.updateTag({ property: 'og:description', content: description });
    this.metaService.updateTag({ property: 'og:type', content: 'website' });
    this.metaService.updateTag({ property: 'og:url', content: `${this.shopHost}/${this.baseUrl}` });
    // If StoreHeroImages is available and is an array, use the first image
    if (heroSection?.medias && Array.isArray(heroSection?.medias) && heroSection?.medias.length > 0 && heroSection?.medias[0].type === 'IMAGE') {
      const imageUrl = `${heroSection?.medias[0].preview}?preset=large`;
      this.metaService.updateTag({ property: 'og:image', content: replaceHostname(imageUrl, this.shopHost) });
    }
    // Set Twitter Card meta tags
    this.metaService.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.metaService.updateTag({ name: 'twitter:title', content: title });
    this.metaService.updateTag({ name: 'twitter:description', content: description });
    // If StoreHeroImages is available and is an array, use the first image
    if (heroSection?.medias && Array.isArray(heroSection?.medias) && heroSection?.medias.length > 0 && heroSection?.medias[0].type === 'IMAGE') {
      const imageUrl = heroSection?.medias[0].preview;
      this.metaService.updateTag({ name: 'twitter:image', content: replaceHostname(imageUrl, this.shopHost) });
    }
    // If there's a hero video, you might want to include it in the metadata
    if (heroSection?.medias && Array.isArray(heroSection?.medias) && heroSection?.medias.length > 0 && heroSection?.medias[0].type === 'VIDEO') {
      this.metaService.updateTag({ property: 'og:video', content: heroSection?.medias[0].source });
    }
    this.logger.debug('Updated metadata for store:', store.identifier);
  }

  private removeMetadata() {
    this.metaService.removeTag('name="description"');
    this.metaService.removeTag('property="og:title"');
    this.metaService.removeTag('property="og:description"');
    this.metaService.removeTag('property="og:type"');
    this.metaService.removeTag('property="og:url"');
    this.metaService.removeTag('property="og:image"');
    this.metaService.removeTag('name="twitter:card"');
    this.metaService.removeTag('name="twitter:title"');
    this.metaService.removeTag('name="twitter:description"');
    this.metaService.removeTag('name="twitter:image"');
  }

  computeLayoutStyles() {
    if (this.layoutOrder) {
      this.layoutOrder.forEach(layout => {
        const styles: { [key: string]: string } = {};

        if (this.isMobile) {
          styles.width = layout.mobileWidthPercentage || '100%';
          styles['margin-top'] = `${layout.mobileTopMargin}px`;
          styles['margin-bottom'] = `${layout.mobileBottomMargin}px`;
        } else {
          styles.width = layout.widthPercentage || '100%';
          styles['margin-top'] = `${layout.topMargin}px`;
          styles['margin-bottom'] = `${layout.bottomMargin}px`;
        }

        // Center the component
        styles['margin-left'] = 'auto';
        styles['margin-right'] = 'auto';

        layout.styles = styles;
      });
    }
  }
}