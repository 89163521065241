<div
  *ngIf="navigationMenu?.length > 0"
  [ngClass]="
    showTopNav
      ? 'bg-white shadow-lg z-50'
      : 'fixed top-0 left-0 right-0 bg-white shadow-lg z-50'
  "
  [ngStyle]="
    showTopNav
      ? {}
      : {
          'top.px': isHeaderFloating ? isMobile? mobileHeaderHeight:
            ( withHeaderMessage ? (desktopHeaderHeight + desktopTopMessageHeight ) : desktopHeaderHeight ) : 0
        }
  "
>
  <hr>
  <div
    class="grid grid-cols-4 gap-2 px-4 py-2"
    [ngClass]="isMobile ? '' : 'max-w-6xl mx-auto'"
    [ngStyle]="{'grid-template-columns': getGridColumns()}"
  >
    <div
      *ngFor="let item of navigationMenu"
      class="flex items-center justify-center"
    >
      <button
        (click)="onNavItemClicked(item)"
        class="cursor-pointer bg-transparent text-sm sm:text-base transition duration-200 ease-in-out transform hover:scale-110 font-normal hover:font-bold hover:text-[#29319C] hover:border-b-2 hover:border-[#29319C]"
      >
        {{ item.label }}
      </button>
    </div>
  </div>
</div>
