import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MOBILE_HEADER_HEIGHT, DESKTOP_HEADER_HEIGHT, DESKTOP_TOP_MESSAGE_HEIGHT } from '../../../common/constants';
@Component({
    selector: 'vsf-section-nav-menu',
    templateUrl: './section-nav-menu.component.html',
    styleUrls: ['./section-nav-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  })
  export class SectionNavMenuComponent implements OnChanges {
    @Input() isMobile = false;           // Whether user is on mobile
    @Input() showTopNav = true;    // Whether to show top nav or floating nav
    @Input() isHeaderFloating = false;   // For controlling top offset if there's a floating header
    @Input() withHeaderMessage = false;     // Whether to show top message
    @Input() navigationMenu: Array<any> = []; // The nav items

    @Output() navItemClicked = new EventEmitter<any>();
    mobileHeaderHeight = MOBILE_HEADER_HEIGHT
    desktopHeaderHeight = DESKTOP_HEADER_HEIGHT
    desktopTopMessageHeight = DESKTOP_TOP_MESSAGE_HEIGHT

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges): void {
      if (changes['showTopNav'] || changes['isHeaderFloating']) {
        // If showTopMobileNav or isHeaderFloating input changed, mark for check.
        this.cdr.markForCheck();
      }
    }
    // Method triggered when a nav item is clicked
    onNavItemClicked(item: any) {
      // Emit the clicked item to the parent
      this.navItemClicked.emit(item);
    }

    getGridColumns(): string {
        const itemsCount = this.navigationMenu?.length || 0;
        return `repeat(${Math.min(itemsCount, 4)}, 1fr)`;
    }
}