<div class="relative w-full border border-gray-100 bg-white shadow-sm hover:border-gray-400 overflow-y-auto"
  [ngClass]="{'h-auto aspect-[16/9]': size === 'full'}"
>
  <!-- Common Templates -->
  <ng-template #priceTemplate>
    <div *ngIf="displayPrice" class="text-center py-1 px-2 text-md font-bold md:whitespace-nowrap"
         [ngClass]="{'text-white': size==='full', 'text-gray-800': size!=='full'}">
      <div *ngIf="item.price.__typename === 'SinglePrice'">
        {{ itemPrice | formatPrice }}
      </div>
      <div *ngIf="item.price.__typename === 'PriceRange'">
        <ng-template [ngIf]="item.price.min === item.price.max" [ngIfElse]="range">
          {{ itemPrice | formatPrice }}
        </ng-template>
        <ng-template #range>
          From {{ item.price.min | formatPrice }}
        </ng-template>
      </div>
    </div>
  </ng-template>

  <ng-template #metricsTemplate>
    <div class="flex justify-start gap-2">
      <div *ngIf="displayViewers && (size === 'full' || size === 'large')"
           class="text-center py-1 px-2 text-sm"
           [ngClass]="{'text-white': size==='full', 'text-gray-800': size!=='full'}">
        <fa-icon icon="eye" class="text-yellow-500"></fa-icon>
        1211
      </div>
      <div *ngIf="displayLikes"
           class="text-center py-1 px-2 text-sm"
           [ngClass]="{'text-white': size==='full', 'text-gray-800': size!=='full'}">
        <fa-icon icon="heart" class="text-yellow-500"></fa-icon>
        1000
      </div>
      <div *ngIf="displayRanking"
           class="text-center py-1 px-2 text-sm"
           [ngClass]="{'text-white': size==='full', 'text-gray-800': size!=='full'}">
        <fa-icon icon="ranking-star" class="text-yellow-500"></fa-icon>
        4
      </div>
    </div>
  </ng-template>

  <ng-template #soldTemplate>
    <div *ngIf="displaySold"
         class="text-center py-1 px-2 text-sm flex flex-row justify-center items-center"
         [ngClass]="{'text-white': size==='full', 'text-gray-800': size!=='full'}">
      <svg class="size-5 text-yellow-500" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
           stroke="currentColor" aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round"
              d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
      </svg>
      <p class="px-1 text-sm"
         [ngClass]="{'text-white': size==='full', 'text-gray-800': size!=='full'}">100</p>
    </div>
  </ng-template>

  <ng-template #commonBottomSection>
    <div class="p-2 flex items-end justify-between">
      <div class="flex flex-col gap-2">
        <!-- First row: metrics -->
        <ng-container *ngTemplateOutlet="metricsTemplate"></ng-container>
        <!-- Second row: price & sold -->
        <div class="flex justify-center gap-2">
          <ng-container *ngTemplateOutlet="priceTemplate"></ng-container>
          <ng-container *ngTemplateOutlet="soldTemplate"></ng-container>
        </div>
      </div>
      <fa-icon *ngIf="displayBuyButton"
               icon="cart-shopping"
               class="z-10 px-2 py-1 text-lg cursor-pointer hover:scale-110 transition duration-300 ease-in-out "
               [ngClass]="{'text-white': size==='full', 'text-yellow-500': size!=='full'}"
               (click)="onButtonClick($event)">
      </fa-icon>
    </div>
  </ng-template>

  <!-- If 'full' layout -->
  <ng-container *ngIf="size === 'full'">
    <a prefetch="intent" [routerLink]="[productBaseUrl, item.slug]" [queryParams]="{ variantid: item.productVariantId }">
      <div class="absolute inset-0 items-center w-auto h-auto">
        <img
          [src]="featuredAsset | assetPreview:'full'"
          alt="{{ itemName }}"
          class="w-full h-full object-cover aspect-[16/9]"
        />
      </div>
      <div class="relative w-full h-full flex flex-col justify-end z-10">
        <div class="px-2 py-1 line-clamp-1 text-white">
          <p class="text-lg font-bold">{{ itemName }}</p>
        </div>
        <ng-container *ngTemplateOutlet="commonBottomSection"></ng-container>
      </div>
      <span aria-hidden="true" [style.z-index]="1"
            class="absolute w-full bottom-x-0 bottom-0 h-1/2 bg-gradient-to-t from-gray-500 opacity-90">
      </span>
    </a>
  </ng-container>

  <!-- If not 'full', e.g. 'small' or 'large' -->
  <ng-container *ngIf="size !== 'full'">
    <a prefetch="intent" [routerLink]="[productBaseUrl, item.slug]" [queryParams]="{ variantid: item.productVariantId }">
      <!-- Asset container -->
      <div class="p-1 w-auto h-auto flex items-center justify-center">
        <img
          [src]="featuredAsset | assetPreview: size==='small' ? 'medium' : 'full'"
          alt="{{ itemName }}"
          [ngClass]="{
            'aspect-[1/1]': size === 'small',
            'aspect-[16/9]': size === 'large'
          }"
          class="w-full h-full object-cover rounded-md"
        />
      </div>

      <!-- Title bar -->
      <div class="bg-grey-100 px-2 py-1">
        <p class="text-black font-bold line-clamp-2 h-[3rem]" title="{{ itemName }}">{{ itemName }}</p>
      </div>

      <!-- Optional subtitle for 'large' -->
      <div *ngIf="size === 'large'" class="bg-grey-100 px-2 py-1 line-clamp-1">
        <p class="text-gray-700" title="{{ item.productSubtitle }}">{{ item.productSubtitle || '&nbsp;' }}</p>
      </div>

      <!-- Common bottom section -->
      <ng-container *ngTemplateOutlet="commonBottomSection"></ng-container>
    </a>
  </ng-container>
</div>
