import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-not-found',
  template: `
    <main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div class="text-center">
        <p class="text-base font-semibold text-indigo-600">404</p>
        <h1 class="mt-4 text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">Oops! Page not found</h1>
        <p class="mt-6 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">The page you’re looking for doesn’t exist.</p>
        <div class="mt-10 flex items-center justify-center gap-x-6">
          <a href="/" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Go back home</a>
          <a href="/shopping" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Shopping</a>
        </div>
        <p class="mt-8 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">Or search for products</p>
        <div class="mt-8">
          <vsf-simple-search-bar></vsf-simple-search-bar>
        </div>
      </div>
    </main>
  `
})
export class NotFoundComponent {
    constructor(
        private titleService: Title,
        private metaTagService: Meta,
    ) {
        this.titleService.setTitle('404: Page Not Found');
        this.metaTagService.addTags([
            { name: 'robots', content: 'noindex, nofollow' },
            { name: 'description', content: 'Sorry, the page or resource you are looking for does not exist.' }
        ]);
    }
}