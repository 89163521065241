<div *ngIf="(isMobile===false)" class="relative">
     <div *ngIf="selectedAsset && selectedAsset.type === 'IMAGE'" (click)="openImage(selectedAsset?.id)" class="cursor-zoom-in">
          <img
          loading="lazy"
          [src]="selectedAsset | assetPreview:'medium'"
          alt="variant featured image"
          style="pointer-events: none;"
          (load)="onLoad()"
          class="w-full h-full object-center object-cover aspect-[1/1]" />
     </div>
     <div *ngIf="selectedAsset && selectedAsset.mimeType === 'video/vimeo'" class="cursor-zoom-in w-full h-full" [style.width.px]="vimeoWidth" [style.height.px]="vimeoHeight">
        <vsf-vimeo-player [source]="selectedAsset.customFields?.videoUrl || selectedAsset.source" [width]="vimeoWidth" [height]="vimeoHeight"></vsf-vimeo-player>
     </div>
     <div *ngIf="isBrowser" class="w-full relative">
          <div *ngIf="selectedAssetIndex > 0">
               <img src="assets/left_chevron.png" class="cursor-pointer left-button mr-1" alt="left chevron icon" (click)="onClickPrev()">
          </div>
          <div class="thumbnails-container">
               <swiper-container appSwiper #desktopSwiper auto-height=true lazy="true"
                    [slidesPerView]="slidesPerView" space-between=20 rewind=true
                    [centeredSlides]="assets?.length > 3 && selectedAssetIndex > 0"
                    class="flex-grow object-cover" pagination-clickable="true" >
                    <swiper-slide *ngFor="let image of assets; let i = index"
                         class="swiper-slide"
                         [ngClass]="{'selected-slide': selectedAssetIndex === i}"
                         (click)="selectImage(image.id)"
                    >
                         <div class="flex justify-center items-center w-full h-full image-container">
                           <img [src]="image | assetPreview:'medium'"
                                loading="lazy"
                                alt="thumbnail"
                                style="pointer-events: none;"

                                class="swiper-lazy w-full h-full object-cover aspect-[1/1]" />
                           <div class="swiper-lazy-preloader"></div>
                         </div>
                    </swiper-slide>
               </swiper-container>
          </div>
          <div *ngIf="selectedAssetIndex < assets.length - 1">
               <img src="assets/right_chevron.png" class="cursor-pointer right-button ml-1" alt="right chevron icon" (click)="onClickNext()">
          </div>
     </div>
</div>
<div *ngIf="isMobile">
     <!-- If we are in the browser, show the Swiper. Otherwise, show the fallback -->
     <ng-container *ngIf="isBrowser; else fallbackImage">
          <swiper-container appSwiper #mobileSwiper
               pagination-type="bullets"
               auto-height="true"
               [dynamicBullets]="true"
               [observer]="true"
               pagination-clickable="true"
               centered-slides="true"
               rewind="true"
               effect="fade"
               grab-cursor="true"
               lazy="true"
               class="flex-grow object-cover custom-pagination">
               <swiper-slide *ngFor="let asset of assets" (click)="openImage(asset?.id)">
                    <div class="swiper-zoom-container">
                         <img *ngIf="asset?.type === 'IMAGE'"
                              [src]="asset | assetPreview:'medium'"
                              loading="lazy"
                              alt="variant featured image"
                              style="pointer-events: none;"
                              (load)="onLoad()"
                              class="swiper-lazy w-full h-full object-center object-cover cursor-zoom-in aspect-[1/1]" />
                         <div *ngIf="asset?.type === 'VIDEO' && asset?.mimeType === 'video/mp4'" class="video-container">
                              <video [src]="asset.source"></video>
                         </div>
                         <div *ngIf="asset?.type === 'VIDEO' && asset?.mimeType === 'video/vimeo'" class="video-container">
                         <vsf-vimeo-player [source]="asset.customFields?.videoUrl || asset.source" [width]="vimeoWidth" [height]="vimeoHeight"></vsf-vimeo-player>
                         </div>
                         <div class="swiper-pagination"></div>
                         <div class="swiper-lazy-preloader"></div>
                    </div>
               </swiper-slide>
          </swiper-container>
     </ng-container>

     <!-- Fallback template for SSR -->
     <ng-template #fallbackImage>
          <div class="h-full flex justify-center items-center" style="background: #eee;">
               <ng-container *ngIf="assets?.[0] as firstAsset">
                    <ng-container *ngIf="firstAsset.type === 'IMAGE'">
                         <img [src]="firstAsset | assetPreview:'medium'"
                              alt="Fallback Image"
                              class="object-cover w-full h-full"
                              style="pointer-events: none;" />
                    </ng-container>
               </ng-container>
          </div>
     </ng-template>
</div>