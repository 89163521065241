import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { CartFragment } from '../../../common/generated-types';

@Component({
  selector: 'vsf-cart-totals',
  templateUrl: './cart-totals.component.html',
  styleUrls: ['./cart-totals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartTotalsComponent implements OnInit, OnChanges {
    @Input() cart: CartFragment;
    constructor(
      private changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {}
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['cart']) {
          this.changeDetectorRef.markForCheck();
        }
    }

}
