<div class="product-carousel">
  <div *ngIf="displayCarouselTitle" class="product-carousel-title">
    <p>{{ title }}</p>
    <div class="title-underline"></div>
  </div>
  <div class="product-list-container">
    <ng-container *ngIf="productCardType === 'regular'">
      <vsf-product-card-v2 *ngFor="let item of displayedItems; trackBy: trackByProductId"
                      [displayType]="displayType"
                      [item]="item"
                      [displaySubtitle]="displaySubtitle"
                      [displayPrice]="displayPrice"
                      [displayBuyButton]="displayBuyButton"
                      [displayAlternativeImage]="displayAlternativeImage"
                      [imgSize]="imgSize"
                      class="card product-card">
      </vsf-product-card-v2>
    </ng-container>
    <ng-container *ngIf="productCardType === 'left-asset-small' || productCardType === 'left-asset-large' || productCardType === 'right-asset-small' || productCardType === 'right-asset-large'">
      <vsf-product-card-v3-horizontal *ngFor="let item of displayedItems; trackBy: trackByProductId"
                      [displayType]="displayType"
                      [item]="item"
                      [displaySubtitle]="displaySubtitle"
                      [displayPrice]="displayPrice"
                      [displayBuyButton]="displayBuyButton"
                      [displayAlternativeImage]="displayAlternativeImage"
                      [displayViewers]="displayViewers"
                      [displayLikes]="displayLikes"
                      [displayRanking]="displayRanking"
                      [displaySold]="displaySold"
                      [displayPrice]="displayPrice"
                      [imgType]="'rectangle'"
                      [imgSize]="imgSize"
                      [type]="(productCardType === 'right-asset-small' || productCardType === 'right-asset-large') ? 'right-asset' : 'left-asset'"
                      [size]="(productCardType === 'left-asset-large' || productCardType === 'right-asset-large') ? 'large' : 'small'"
                      class="card product-card">
      </vsf-product-card-v3-horizontal>
    </ng-container>
    <ng-container *ngIf="productCardType === 'top-asset-small' || productCardType === 'top-asset-large' || productCardType === 'full-asset'">
      <vsf-product-card-v3-vertical *ngFor="let item of displayedItems; trackBy: trackByProductId"
                      [displayType]="displayType"
                      [item]="item"
                      [displaySubtitle]="displaySubtitle"
                      [displayPrice]="displayPrice"
                      [displayBuyButton]="displayBuyButton"
                      [displayAlternativeImage]="displayAlternativeImage"
                      [displayViewers]="displayViewers"
                      [displayLikes]="displayLikes"
                      [displayRanking]="displayRanking"
                      [displaySold]="displaySold"
                      [displayPrice]="displayPrice"
                      [imgSize]="imgSize"
                      [size]="(productCardType === 'top-asset-small') ? 'small' :
                              ((productCardType === 'top-asset-large') ? 'large' : 'full')"
                      class="card product-card">
      </vsf-product-card-v3-vertical>
    </ng-container>
  </div>
  <button class="arrow arrow-left" (click)="showPreviousItems()" *ngIf="startIndex > 0">
    <span><fa-icon icon="chevron-left"></fa-icon></span>
  </button>
  <button class="arrow arrow-right" (click)="showNextItems()" *ngIf="startIndex + itemsPerSlide < (products$ | async)?.length">
    <span><fa-icon icon="chevron-right"></fa-icon></span>
  </button>
</div>